import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DashboardClusterWidget } from "./DashboardClusterWidget";
import { useTranslation } from "@circle/gestalt-app";
import { sort } from "../../helper/sort";

const HistoryProductsOverview = props => { // eslint-disable-line id-length,complexity
    const { translate, currentLanguage } = useTranslation();
    const [content, setContent] = useState([]);
    const showSortedParts  = props.selectedCluster.isSortedOutSumup === false && props.selectedCluster.plantElements.length > 1;
    const isSequential     = props.selectedCluster.isSequentiallyOrdered || props.selectedCluster.plantElements.length === 1;
    const showDurationTime = props.selectedCluster?.name !== "element.overall";
    const [isOrderAsc, setIsOrderAsc] = useState(true);

    const onSort = elem => {
        setIsOrderAsc(!isOrderAsc);
        const sortedContent = sort({
            getter:   translate,
            items:    content,
            property: elem,
            ordering: isOrderAsc ? "asc" : "desc"
        });

        setContent(sortedContent);
    };

    useEffect(() => {
        setContent(props.data);
    }, []);

    useEffect(() => {
        setContent(props.data);
    }, [props.data]);

    return (
        <DashboardClusterWidget header={ translate("products.header") }>
            <table className="cluster-table">
                <thead>
                    <tr>
                        <th
                            id="processed"
                            onClick={() => onSort("processed")}
                            className="event-entry"
                        >{ translate("dashboard.widgets.produced.type") }</th>
                        <th
                            id="sorted"
                            onClick={() => onSort("sorted")}
                            className="event-entry"
                        >{ translate("dashboard.widgets.produced.header") }</th>
                        {
                            !showSortedParts &&
                            <th
                                id="sorted"
                                onClick={() => onSort("sorted")}
                            >{ translate("dashboard.widgets.overview.waste") }</th>
                        }
                        {
                            showDurationTime &&
                            <th
                                id="processingTime"
                                onClick={() => onSort("processingTime")}
                            >{ translate("product.process.time") }</th>
                        }
                        <th
                            id="parts"
                            onClick={() => onSort("parts")}
                        >{ translate("process.run.time") }</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="font-bold">
                        <td>{ translate("general.overall") }</td>
                        <td>{ Number(props.overall.processed).toLocaleString(currentLanguage) }</td>
                        {
                            !showSortedParts &&
                            <td>{ Number(props.overall.sorted).toLocaleString(currentLanguage) }</td>
                        }
                        {
                            showDurationTime &&
                            <td>{ props.selectedCluster.config.constructionTime && isSequential ? `${Number(Math.round(props.overall.processingTime / 10) / 100).toLocaleString(currentLanguage)}s` : "-"}</td>
                        }
                        <td>{ isSequential ? Number(props.overall.parts).toLocaleString(currentLanguage) : "-"}</td>
                    </tr>
                    {
                        props.selectedCluster.config.producedProductTypes && content.map(elem => (
                            <tr key={elem.id}>
                                <td>{ translate(elem.name) }</td>
                                <td>{ Number(elem.processed ?? 0).toLocaleString(currentLanguage) }</td>
                                {
                                    !showSortedParts &&
                                    <td>{ props.selectedCluster.id ? Number(elem.sorted ?? 0).toLocaleString(currentLanguage) : ""}</td>
                                }
                                {
                                    showDurationTime &&
                                    <td>{ props.selectedCluster.config.constructionTime && isSequential ? `${Number(elem.processingTime ? Math.round(elem.processingTime / 10) / 100 : 0).toLocaleString(currentLanguage)}s` : "-"}</td>
                                }
                                <td></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </DashboardClusterWidget>
    );
};

HistoryProductsOverview.propTypes = { // eslint-disable-line id-length
    data:            PropTypes.arrayOf(PropTypes.object),
    overall:         PropTypes.object,
    selectedCluster: PropTypes.object
};

HistoryProductsOverview.defaultProps = { // eslint-disable-line id-length
    data: []
};

export { HistoryProductsOverview };
