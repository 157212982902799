import { Routes, Route } from "react-router-dom";
import { PlantsDropdown } from "../PlantsDropdown";
import { HistoryCalendar } from "./HistoryCalendar";
import { Export } from "../Export";
import { HistoryFilter } from "./HistoryFilter";
import { Breadcrumb } from "../Breadcrumb";
import { MessageMonitorLink } from "./MessageMonitorLink";
import { useBranch } from "@circle/gestalt-app";

const Header = () => {
    const { calendar } = useBranch({
        calendar: ["calendar"]
    });

    return (
        <>
            <Routes>
                <Route path=":plantId/cluster/:clusterId" element={<Breadcrumb/>}/>
                <Route path="*" element={<PlantsDropdown />}/>
            </Routes>
            <div className="history-selector flex-grow flex-row-reverse">
                {
                    calendar &&
                    <>
                        <HistoryCalendar/>
                        <Routes>
                            <Route exact path=":plantId/cluster/:clusterId" element={<MessageMonitorLink/>}/>
                        </Routes>
                        <Export/>
                        <HistoryFilter/>
                    </>
                }
            </div>
        </>
    );
};

export { Header };
