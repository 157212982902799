export default {
    hour: {
        translation: "custom.hour",
        multiplier:  1000 * 60 * 60
    },
    day: {
        translation: "custom.day",
        multiplier:  1000 * 60 * 60 * 24
    },
    month: {
        translation: "custom.month",
        multiplier:  1000 * 60 * 60 * 24 * 30
    },
    year: {
        translation: "custom.year",
        multiplier:  1000 * 60 * 60 * 24 * 365
    }
};
