import { getConfigOfCluster } from "../helper/cluster";

interface PlantElement {
    id: string
    type: string
}

interface Shift {
    id: string
    title: string
}

interface Target {
    workingShiftsId: string
}

interface OverallData {
    plantId: string
    plantElements: PlantElement[]
    allElements: PlantElement[]
    createdAt: Date
    updatedAt: Date
    shifts: Shift[]
    targets: Target[]
}

interface Config {
    [key: string]: boolean
}

interface IPlantElement {
    clusters_id: null
    id: null,
    plant_element_id: string
    type: string
}

interface IOverall {
    id: null
    type: string
    plantId: string
    clusterId: null
    name: string
    elementsCount: number
    plantElements: IPlantElement[]
    allElements: IPlantElement[]
    config: Config
    createdAt: Date
    updatedAt: Date
    targets: Target[]
}

export class Overall implements IOverall {
    constructor(data: OverallData) { // eslint-disable-line complexity
        this.id = null;
        this.type = "element";
        this.plantId = data.plantId;
        this.clusterId = null;
        this.name = "element.overall";
        this.elementsCount = data.plantElements.length;
        this.plantElements = data.plantElements.map(x => ({
            clusters_id:      null, // eslint-disable-line camelcase
            id:               null,
            plant_element_id: x.id, // eslint-disable-line camelcase
            type:             x.type
        }));
        this.allElements = data.allElements.map(x => ({
            clusters_id:      null, // eslint-disable-line camelcase
            id:               null,
            plant_element_id: x.id, // eslint-disable-line camelcase
            type:             x.type
        }));
        this.config = {
            ...getConfigOfCluster({ plantElements: data.plantElements }),
            constructionTime:        false,
            historyConstructionTime: false, // eslint-disable-line id-length
            historyThroughputTime:   false, // eslint-disable-line id-length
            historyWaste:            false,
            statesOverall:           false,
            throughputTime:          false,
            waste:                   true
        };
        this.createdAt = data.createdAt || null;
        this.updatedAt = data.updatedAt || null;
        this.targets   = data.shifts.map(shift => {
            const target = data.targets.find(elem => elem.workingShiftsId === shift.id);

            return target ? Object.assign({}, target, {
                name: shift.title
            }) : ({
                name:            shift.title,
                workingShiftsId: shift.id,
                target:          null
            });
        });
    }
    id: null;
    type: string;
    plantId: string;
    clusterId: null;
    name: string;
    elementsCount: number;
    plantElements: IPlantElement[];
    allElements: IPlantElement[];
    config: Config;
    createdAt: Date;
    updatedAt: Date;
    targets: Target[];
}
