import { useTrigger } from "@circle/gestalt-app";
import { useEffect, useState } from "react";

const useQuery = ({ options, keys }) => {
    const { trigger }     = useTrigger();
    const [init, setInit] = useState(false);

    useEffect(() => {
        if(Object.keys(options).length === 0) return;

        trigger("applyQuery", options, keys);

        setInit(true);
    }, [options]);
    return [init];
};

export { useQuery };
