import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { DatePicker } from "../../types/DatePicker";
import { CombinedTooltip } from "../history/CombinedTooltip";
import ClusterFilter  from "../../enums/clusterFilter";
import { CustomLegend } from "../history/CustomLegend";
import { BusyIndicator } from "../BusyIndicator";
import { getChartColor } from "../../helper/color";
import { useParams } from "react-router-dom";

const DashboardGraph = props => { // eslint-disable-line complexity
    const params      = useParams();
    const { trigger } = useTrigger();
    const { translate, currentLanguage } = useTranslation();
    const { isBusy, selectedPlant } = useBranch({
        isBusy:        ["counter", "monitor"],
        selectedPlant: ["selectedPlant"]
    });

    const activeElements = selectedPlant?.workingShifts.filter(x => props.active.includes(translate(x.title)));
    const items          = (activeElements ?? []).map((elem, idx) => ({
        ...elem,
        name:  translate(elem.title),
        color: getChartColor(idx + 1)
    }));
    const mainColor       = getChartColor(0);
    const viewFault       = props.active.includes("fault");
    const viewProcessTime = props.active.includes("processTime");
    const viewDuration    = props.active.includes("durchlaufzeit");
    const viewBar         = props.active.includes("teile");
    const data            = Object.keys(props.data).map(elem => {
        const item = params.clusterId === "all" ?
            props.data[elem].find(x => !x.clusterId) :
            props.data[elem].find(x => `${x.clusterId}` === params.clusterId);

        const basicItem = {
            name:        elem,
            from:        item.from,
            until:       item.until,
            processed:   item.processed,
            faultCourse: item.faultCourse,
            ...item.shifts?.reduce((dest, x) => ({
                ...dest,
                [`processed-${x.id}`]: x.processed
            }), {})
        };

        if(!props.showTimes) return basicItem;

        return Object.assign({}, basicItem, {
            duration:       item.duration,
            processingTime: item.processingTime,
            ...item.shifts?.reduce((dest, x) => ({
                ...dest,
                [`processed-${x.id}`]:      x.processed,
                [`processingTime-${x.id}`]: x.processingTime,
                [`duration-${x.id}`]:       x.duration
            }), {})
        });
    });

    const onClick = e => {
        if(!e || !e.activePayload || !e.activePayload[0] || !e.activePayload[0].payload) return;

        const from  = new Date(e.activePayload[0].payload.from);
        const until = new Date(e.activePayload[0].payload.until);

        if(!from || !until) return;

        const selector = DatePicker.of([from, until]);

        trigger("onCalendarSelect", selector, params.plantId, params.clusterId);
    };

    const handleLegendClick = e => {
        const dataKey = e.dataKey.split("-")[0];
        const key     = DashboardGraph.types[dataKey];

        return props.toggleActive(key);
    };

    return (
        <div className="main-graph">
            {
                isBusy > 0 &&
                <div className="local-indicator">
                    <BusyIndicator/>
                </div>
            }
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    onClick={onClick}
                    width={900}
                    height={250}
                    data={data}
                    margin={{
                        top: 40, right: 0, bottom: 20, left: -10
                    }}
                >
                    <CartesianGrid stroke="#d7d7d7" strokeDasharray="5 5" horizontalFill={["#d7d7d7"]} vertical={false} fillOpacity={0.2} />
                    <XAxis dataKey="name" axisLine={false}/>
                    <YAxis yAxisId="left" axisLine={false}/>
                    <YAxis yAxisId="right" orientation='right' width={30} axisLine={false}/>
                    <Tooltip content={<CombinedTooltip locale={currentLanguage}/>}/>
                    <Legend className="legend" content={<CustomLegend/>} onClick={handleLegendClick}/>
                    {
                        viewBar &&
                        <Bar yAxisId="left"
                            name={translate("general.overall")}
                            dataKey="processed"
                            barSize={20}
                            fill={mainColor}
                            fillOpacity={0.7}
                        />
                    }
                    {
                        viewBar &&
                        items.map(elem => (
                            <Bar key={`processed-${elem.id}`}
                                yAxisId="left"
                                name={elem.name}
                                dataKey={`processed-${elem.id}`}
                                barSize={20}
                                fill={elem.color}
                            />
                        ))
                    }
                    {
                        viewDuration &&
                        <Line
                            legendType="triangle"
                            yAxisId="right"
                            type="monotone"
                            dataKey="duration"
                            stroke={mainColor}
                            name={translate(ClusterFilter.durchlaufzeit)}
                        />
                    }
                    {
                        viewDuration &&
                        items.map(elem => (
                            <Line key={`duration-${elem.id}`}
                                legendType="triangle"
                                yAxisId="right"
                                type="monotone"
                                dataKey={`duration-${elem.id}`}
                                stroke={elem.color}
                                name={translate(ClusterFilter.durchlaufzeit)}
                            />
                        ))
                    }
                    {
                        viewProcessTime &&
                        <Line legendType="circle"
                            yAxisId="right"
                            type="monotone"
                            strokeDasharray="3 3"
                            dataKey="processingTime"
                            name={translate(ClusterFilter.processTime)}
                            stroke={mainColor}
                        />
                    }
                    {
                        viewProcessTime &&
                        items.map(elem => (
                            <Line key={`processingTime-${elem.id}`}
                                legendType="circle"
                                yAxisId="right"
                                type="monotone"
                                strokeDasharray="3 3"
                                dataKey={`processingTime-${elem.id}`}
                                name={translate(ClusterFilter.processTime)}
                                stroke={elem.color}
                            />
                        ))
                    }
                    {
                        viewFault &&
                        <YAxis yAxisId="right-3"
                            orientation='right'
                            width={30}
                            axisLine={false}
                            tick={{ fill: "red" }}
                        />
                    }
                    {
                        viewFault &&
                        <Line yAxisId="right-3"
                            type="monotone"
                            strokeDasharray="3 3"
                            dataKey="faultCourse"
                            key="faultCourse"
                            name={translate(ClusterFilter.fault)}
                            stroke="red"
                            dot={{ stroke: "red", strokeWidth: 1, strokeDasharray: "" }}
                        />
                    }
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

DashboardGraph.propTypes = {
    data:         PropTypes.object,
    toggleActive: PropTypes.func,
    active:       PropTypes.arrayOf(PropTypes.string),
    showTimes:    PropTypes.bool
};

DashboardGraph.defaultProps = {
    data:      {},
    showTimes: true
};

DashboardGraph.types = {
    duration:       "durchlaufzeit",
    processingTime: "processTime",
    produced:       "teile",
    faultCourse:    "fault"
};

export { DashboardGraph };
