import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTrigger } from "@circle/gestalt-app";

const useQueryString = ({ plants, optional = {} }) => {
    const { trigger }         = useTrigger();
    const params              = useParams();
    const [filter, setFilter] = useState({});

    useEffect(() => {
        const urlParts = decodeURI(window.location.href).split("?");
        const filters  = urlParts.length < 2 ? {} : urlParts[1].split("&").reduce((dest, val) => Object.assign({}, dest, {
            [val.split("=")[0]]: val.split("=")[1]
        }), {});

        const activeFilter = {
            selectedPlant: plants.find(x => x.id === params.plantId),
            clusters:      JSON.parse(filters?.clusters ?? "[]"),
            ...Object.keys(optional).reduce((dest, x) => ({ ...dest, [x]: optional[x](filters[x]) }), {})
        };

        trigger("setQueryOptions", activeFilter);
        setFilter(activeFilter);
    }, []);

    return [filter];
};

export { useQueryString };
