import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { Button } from "@circle/kip-components";
import { LanguageToggle } from "./LanguageToggle";

const FormActions = props => {
    const [modal, setModal] = useState(false);
    const { translate } = useTranslation();

    const onSubmit = () => {
        if(props.isValid.required && !props.isValid.overall) return setModal(true);

        return props.onSubmit();
    };

    return (
        <div className={`actions-container flex${props.hidden ? " hidden" : ""}`}>
            {
                props.hasLanguageToggle &&
                <LanguageToggle />
            }
            {
                modal &&
                <div className="bubble-container">
                    <div className="bubble">
                        {
                            translate("actions.notValid").split("\n").map((x, key) => <p key={key}>{ x }</p>)
                        }
                        <div className="horizontal-center">
                            <Button onClick={() => setModal(false)}>
                                { translate("actions.ok").toUpperCase() }
                            </Button>
                        </div>
                    </div>
                </div>
            }
            <div className="horizontal-end flex-grow">
                <Button onClick={props.onCancel}>{ translate("actions.cancel") }</Button>
                <Button className="ml-2" _appearance="primary" disabled={!props.isValid.overall} onClick={onSubmit}>{ translate("actions.save") }</Button>
            </div>
        </div>
    );
};

FormActions.propTypes = {
    isValid:           PropTypes.object,
    hidden:            PropTypes.bool,
    onSubmit:          PropTypes.func,
    onCancel:          PropTypes.func,
    hasLanguageToggle: PropTypes.bool
};

FormActions.defaultProps = {
    hasLanguageToggle: true
};

export { FormActions };
