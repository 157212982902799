import { useTranslation } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useDate } from "../hooks/date";

const Timer = props => {
    const { translate, currentLanguage } = useTranslation();
    const location = useLocation();
    const value    = useDate(currentLanguage);

    return (
        <div className="flex-row align-right timer flex-grow">
            <div className="flex-column">
                {
                    location.pathname.includes("/overview") &&
                    <div className="font-bold">
                        <span>{value.date}</span>
                    </div>
                }
                {
                    !location.pathname.includes("/overview") &&
                    <div className="font-bold">
                        <span>{props.shift ? `${props.shift}, ` : `${translate("last8Hours")}, `}{value.date}</span>
                    </div>
                }
                <div className="font-bold align-right">{value.time}</div>
            </div>
        </div>
    );
};

Timer.propTypes = {
    shift: PropTypes.string
};

export { Timer };
