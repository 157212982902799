import { useBranch } from "@circle/gestalt-app";
import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";
import { Dashboard } from "./Dashboard";

const DashboardWrapper = () => {
    const { options, plants } = useBranch({
        options: ["queryOptions"],
        plants:  ["plants"]
    });

    const [filter] = useQueryString({
        plants,
        optional: {
            widgets: x => JSON.parse(x?.replace("undefined", "null") ?? JSON.stringify(options.widgets))
        }
    });

    const [init] = useQuery({
        keys:    ["clusters", "widgets"],
        options: filter
    });

    return (
        <>
            {
                init &&
                <Dashboard
                    filter={filter}
                />
            }
        </>
    );
};

export { DashboardWrapper };

