import { useState } from "react";
import PropTypes from "prop-types";
import { Add } from "../Add";
import { Button, Modal } from "@circle/kip-components";
import { Timer } from "../Timer";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";

const DashboardClusterWidget = props => { // eslint-disable-line id-length
    const { trigger } = useTrigger();
    const { translate, currentLanguage } = useTranslation();
    const { selectedClusters, selectedPlant, shifts } = useBranch({
        selectedPlant:    ["selectedPlant"],
        selectedClusters: ["selectedClusters"],
        shifts:           ["currentWorkingShifts"]
    });
    const [modal, setModal] = useState(false);
    const [id] = useState(Math.floor(Math.random() * 100));

    const shift     = shifts.find(x => x.plants_id === selectedPlant?.id);
    const shiftName = shift ? translate(shift.title) : null;

    const onModal = (info, e) => {
        e.stopPropagation();

        props.onFullScreen(info);
        setModal(!modal);
        if(info === "open") return trigger("fullWidget", true);
        return trigger("fullWidget", false);
    };

    return (
        <>
            <div className={`clusters-widget cluster-widget${props.centered ? " centered" : ""}`}>
                <div className="cluster-widget-inner">
                    {
                        Boolean(props.header) &&
                    <div className="cluster-widget-header">
                        <h5>{props.header}</h5>
                        {
                            !props.onAdd &&
                        <Button className="cluster-widget-icon" _variant="icon" onClick={e => onModal("open", e)}>
                            {/* <Icon _icon="Export"/> */}
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 15H3.75C3.55109 15 3.36032 14.9209 3.21967 14.7803C3.07902 14.6396 3 14.4489 3 14.25V11.25C3 11.051 3.07902 10.8603 3.21967 10.7196C3.36032 10.579 3.55109 10.5 3.75 10.5C3.94891 10.5 4.13968 10.579 4.28033 10.7196C4.42098 10.8603 4.5 11.051 4.5 11.25V13.5H6.75C6.94891 13.5 7.13968 13.579 7.28033 13.7196C7.42098 13.8603 7.5 14.051 7.5 14.25C7.5 14.4489 7.42098 14.6396 7.28033 14.7803C7.13968 14.9209 6.94891 15 6.75 15ZM10.5 8.24995C10.351 8.25082 10.2052 8.2073 10.081 8.12495C9.9569 8.0426 9.8601 7.92515 9.80297 7.78756C9.74585 7.64998 9.73099 7.4985 9.76029 7.35244C9.78959 7.20638 9.86173 7.07236 9.9675 6.96745L13.7175 3.21745C13.861 3.09458 14.0455 3.03038 14.2343 3.03767C14.423 3.04496 14.6021 3.12321 14.7357 3.25678C14.8692 3.39035 14.9475 3.56941 14.9548 3.75816C14.9621 3.94692 14.8979 4.13148 14.775 4.27495L11.025 8.02495C10.8865 8.16626 10.6978 8.24713 10.5 8.24995Z" fill="black"/>
                                <path d="M14.25 7.5C14.0511 7.5 13.8603 7.42098 13.7196 7.28033C13.579 7.13968 13.5 6.94891 13.5 6.75V4.5H11.25C11.0511 4.5 10.8603 4.42098 10.7196 4.28033C10.579 4.13968 10.5 3.94891 10.5 3.75C10.5 3.55109 10.579 3.36032 10.7196 3.21967C10.8603 3.07902 11.0511 3 11.25 3H14.25C14.4489 3 14.6396 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V6.75C15 6.94891 14.921 7.13968 14.7803 7.28033C14.6396 7.42098 14.4489 7.5 14.25 7.5ZM3.74997 15C3.601 15.0009 3.45515 14.9573 3.33101 14.875C3.20687 14.7926 3.11006 14.6752 3.05294 14.5376C2.99581 14.4 2.98096 14.2486 3.01026 14.1025C3.03956 13.9564 3.1117 13.8224 3.21747 13.7175L6.96747 9.9675C7.11094 9.84463 7.2955 9.78043 7.48426 9.78772C7.67301 9.79501 7.85207 9.87326 7.98564 10.0068C8.11921 10.1404 8.19746 10.3195 8.20475 10.5082C8.21204 10.697 8.14784 10.8815 8.02497 11.025L4.27497 14.775C4.13649 14.9163 3.94779 14.9972 3.74997 15V15Z" fill="black"/>
                            </svg>
                        </Button>
                        }
                        {
                            props.onAdd &&
                            <Add onClick={props.onAdd}/>
                        }
                    </div>
                    }
                    <div className="cluster-widget-content m1">
                        {props.children}
                    </div>
                </div>
            </div>

            <Modal
                id={id}
                className="cluster-widget-modal"
                title=""
                isOpen={modal}
                onClose={e => onModal("close", e)}
                isCloseable={true}
            >
                <div className="widget-modal-info">
                    <div>
                        <div><b>{translate("widget.plant")}:{" "}</b>{translate(selectedPlant?.name)}</div>
                        <div><b>{translate("widget.cluster")}:{" "}</b>{selectedClusters?.map(x => x.name === "element.overall" ? translate("general.overall") : translate(x.name)).join(", ")}</div>
                    </div>
                    <Timer shift={shiftName} />
                </div>
                {
                    props.values &&
                        <div className="mt-5 mb-5">
                            <table className="cluster-table">
                                <tbody>
                                    <tr>
                                        <td>{ translate("dashboard.widgets.overview.target") }</td>
                                        <td><b>{ !props.values.target ? "-" : Number(props.values.target).toLocaleString(currentLanguage) }</b></td>
                                    </tr>
                                    <tr>
                                        <td>{ translate("dashboard.widgets.overview.actual") }</td>
                                        <td><b>{ Number(props.values.actual).toLocaleString(currentLanguage) }</b></td>
                                    </tr>
                                    <tr>
                                        <td>{ translate("dashboard.widgets.overview.waste") }</td>
                                        <td><b>{ props.values.waste.toLocaleString(currentLanguage) }</b></td>
                                    </tr>
                                    <tr>
                                        <td>{ translate("dashboard.widgets.overview.partsPerHour") }</td>
                                        <td><b>{ Number(props.values.averageParts).toLocaleString(currentLanguage) }</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                }
                {props.children}
            </Modal>
        </>
    );
};

DashboardClusterWidget.propTypes = { // eslint-disable-line id-length
    header:           PropTypes.string,
    centered:         PropTypes.bool,
    children:         PropTypes.node,
    onAdd:            PropTypes.func,
    selectedPlant:    PropTypes.object,
    translate:        PropTypes.func,
    trigger:          PropTypes.func,
    selectedClusters: PropTypes.arrayOf(PropTypes.object),
    values:           PropTypes.object,
    locale:           PropTypes.string,
    selected:         PropTypes.object,
    shifts:           PropTypes.arrayOf(PropTypes.object),
    onFullScreen:     PropTypes.func
};

DashboardClusterWidget.defaultProps = { // eslint-disable-line id-length
    header:       "",
    centered:     false,
    onFullScreen: x => x
};

export { DashboardClusterWidget };
