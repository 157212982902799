import { useBranch, useTrigger } from "@circle/gestalt-app";
import { useLocation } from "react-router-dom";
import { PlantsDropdown } from "../PlantsDropdown";
import { Add } from "../Add";

const AdminHeader = () => {
    const location     = useLocation();
    const isDashboard  = location.pathname.includes("/dashboard");
    const isCluster    = location.pathname.includes("/cluster");
    const { trigger }  = useTrigger();
    const { selected } = useBranch({
        selected: ["selectedPlant"]
    });

    return (
        <>
            {
                !isDashboard &&
                <PlantsDropdown />
            }
            {
                isDashboard &&
                <div className="bg-white plants-dropdown"/>
            }
            {
                (isDashboard || (isCluster && selected)) &&
                <div className="flex-grow horizontal-end">
                    <Add onClick={() => trigger("onSidebarToggle", null)}/>
                </div>
            }
        </>
    );
};

export { AdminHeader };
