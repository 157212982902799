import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Datatable } from "../generic/Datatable";
import { Column } from "../generic/Column";
import { ClusterForm } from "./ClusterForm";
import { Icon, Drawer } from "@circle/kip-components";
import { useParams } from "react-router-dom";

const ClustersNav = () => {
    const { translate } = useTranslation();
    const { trigger }   = useTrigger();
    const params = useParams();
    const { clusters, isSidebarOpen, selected, options } = useBranch({
        clusters:      ["joinedClusters"],
        isSidebarOpen: ["isSidebarOpen"],
        selected:      ["selectedPlant"],
        options:       ["queryOptions"]
    });
    const [cluster, setCluster] = useState(null);

    const content = selected ? clusters.filter(x => x.plantId === selected.id) : clusters.filter(x => x.plantId === "");

    const onClose = () => {
        trigger("onSidebarToggle", false);

        setCluster(null);

        trigger("resetLang");
    };

    const onSubmit = data => {
        const func = !cluster ? "createCluster" : "editCluster";

        trigger(func, {
            ...data,
            plantId: selected.id
        });

        return onClose();
    };

    const onSort = elem => {
        trigger("setSortTable", elem);
    };

    useEffect(() => {
        trigger("setSortTable", {
            property:   "name",
            isOrderAsc: true
        });
        trigger("onSettingsPlantSelect", params.plantId, options);
    }, []);

    return (
        <>
            <Datatable
                content={content}
                isEditable={true}
                hasActionsColumn={true}
                onRowClick={elem => setCluster(elem)}
                onDelete={x => trigger("deleteCluster", x)}
                selected={cluster?.id}
                className="datatable"
                onHeadlineClick={onSort}
            >
                <Column
                    title=""
                    width="10%"
                    render={() =>
                        <div className="vertical-align">
                            <Icon className="home-icon" _icon="Cluster"/>
                        </div>
                    }
                />
                <Column
                    title="element.name.header"
                    width="45%"
                    sortable
                    item="name"
                    render={x =>
                        <span className="clamped">{ translate(x) }</span>
                    }
                />
                <Column
                    title="plant.ele.count.header"
                    width="60%"
                    item="plantElements"
                    sortable
                    render={x =>
                        <span className="clamped">{x?.length}</span>
                    }
                />
                <Column
                    title="last.modified.header"
                    width="50%"
                    sortable
                    item={"createdAt" || "updatedAt"}
                    render={x => {
                        const date = x;

                        if(!date) return "-";

                        const finalDate = date.toLocaleDateString ? date : new Date(date);

                        return finalDate.toLocaleString().replace(",", "");
                    }}
                />
            </Datatable>
            <div>
                <Drawer className="sidebar drawer-container" _show={Boolean(cluster?.id || isSidebarOpen)} _onClose={onClose}>
                    {
                        cluster &&
                            <ClusterForm
                                {...cluster}
                                onCancel={onClose}
                                onSubmit={onSubmit}
                            />
                    }
                    {
                        !cluster &&
                            <ClusterForm
                                plantId={selected?.id}
                                onCancel={onClose}
                                onSubmit={onSubmit}
                            />
                    }
                </Drawer>
            </div>
        </>
    );
};

export { ClustersNav };
