interface Language {
    language: string,
    content: string
}

interface Lang {
    value: string
}

interface Element {
    id: string | number,
    name: string
    plant_element_id: string | number
}

interface Props {
    translate: (val: string | undefined) => string
    allElements: Element[]
}

export const hasValue = (language : string, content: Language[] = []) => {
    const item = content.filter(elem => elem.language === language)[0];

    return item && item.content.length > 0;
};

export const validateTranslation = (languages: Lang[], content: Language[] = [], required = true) => {
    if(!required) return true;

    const languageList = content.map(elem => elem.language);
    const systemLang   = languages.map(z => z.value);

    return systemLang.reduce((dest, elem) => dest && languageList.includes(elem), true) &&
        content.reduce((dest, elem) => dest && elem.content?.length > 0, true);
};

export const elemToValue = (props: Props, x: Element) => ({
    label:            props.translate(props.allElements.find(elem => elem.id === x.plant_element_id)?.name),
    plant_element_id: x.plant_element_id, // eslint-disable-line camelcase
    value:            x.plant_element_id
});
