import PropTypes from "prop-types";
import { DashboardClusterWidget } from "./DashboardClusterWidget";
import { useTranslation } from "@circle/gestalt-app";
import { MixedChart } from "../MixedChart";

const CombinedProductivity = props => {
    const { translate } = useTranslation();

    return (
        <DashboardClusterWidget header={translate("dashboard.widgets.monitor")} values={props.values}>
            {
                !props.data &&
                <span>{translate("data.none")}</span>
            }
            {
                props.data &&
                <MixedChart
                    width={660}
                    height={200}
                    data={props.data}
                    labels={{
                        actual:        translate("productivity.actual"),
                        actualPerHour: translate("productivity.actualPerHour"),
                        hour:          translate("productivity.hour"),
                        target:        translate("productivity.target")
                    }}
                    units={{
                        actual:        " ",
                        actualPerHour: " ",
                        target:        " "
                    }}
                />
            }
        </DashboardClusterWidget>
    );
};

CombinedProductivity.propTypes = {
    data:   PropTypes.object,
    values: PropTypes.object
};

export { CombinedProductivity };
