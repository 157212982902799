import PropTypes from "prop-types";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { NavLink } from "react-router-dom";
import { Icon } from "@circle/kip-components";

const Breadcrumb = props => {
    const { translate }          = useTranslation();
    const { clusters, selected } = useBranch({
        clusters: ["joinedClusters"],
        selected: ["selectedPlant"]
    });

    const cluster = clusters.find(elem => elem.id === props.clusterId);

    return (
        <div id="breadcrumb">
            <span className="vertical-align font-bold">
                <NavLink to={`/history/${selected?.id}`} rel="noopener noreferrer">
                    <Icon className="colored home-icon" _icon="Home"/>
                </NavLink>
                <Icon className="chevron ml-2 mr-2" _icon="ChevronRight"/>
                <span className="plant-name">{ translate(selected?.name) }</span>
                <Icon className="chevron mr-2 ml-2" _icon="ChevronRight"/>
                <span className="cluster-name">
                    {
                        cluster &&
                        translate(cluster?.name)
                    }
                    {
                        !cluster &&
                        translate("system.headline")
                    }
                </span>
            </span>
        </div>
    );
};

Breadcrumb.propTypes = {
    clusterId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export { Breadcrumb };
