import { useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { DropdownToggle } from "./DropdownToggle";
import { Checkbox } from "./generic/Checkbox";
import cluster from "../enums/cluster";
import { Icon, Button } from "@circle/kip-components";

const Filter = () => {
    const { trigger }            = useTrigger();
    const { translate }          = useTranslation();
    const { dashboards, filters } = useBranch({
        dashboards: ["sortedDashboardsFilter"],
        filters:    ["dashboardFilters"]
    });
    const [open, setOpen] = useState(false);

    return (
        <div className="filter-container flex relative ml-3">
            <Button _variant="icon" onClick={() => setOpen(!open)}>
                <Icon _icon={!open ? "Filter" : "FilterSolid"}/>
            </Button>
            {
                open &&
                <DropdownToggle onClose={() => setOpen(false)} className="dashboard-dropdown">
                    {
                        dashboards.map(elem => (
                            <a href="#" className="dropdown-link w-dropdown-link flex" tabIndex="0" key={elem.id}>
                                <Checkbox checked={filters.id === elem.id} onChange={() => trigger("setDashboard", elem)} className="dashboard-checkbox">
                                    { translate(elem.name) }
                                </Checkbox>
                            </a>
                        ))
                    }
                    <a href="#" className="flex-column flex-grow dropdown-link w-dropdown-link">
                        <span className="filter-header font-bold">
                            { translate("cluster.headline") }
                        </span>
                        {
                            Object.keys(cluster).map(key => (
                                <div className="flex ml-2" key={key}>
                                    <Checkbox checked={filters[key]} onChange={() => trigger("setDashboardFilter", key, !filters[key])}>
                                        { translate(cluster[key]) }
                                    </Checkbox>
                                </div>
                            ))
                        }
                    </a>
                </DropdownToggle>
            }
        </div>
    );
};

export { Filter };
