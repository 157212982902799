import PropTypes from "prop-types";

const Checkbox = props => {
    const onChange = e => {
        props.onChange(e);
    };

    return (
        <label className={`${props.className} ${!props.disabled ? "clickable" : "checkbox-disable"} checkbox-label`}>
            <input disabled={props.disabled} type="checkbox" className="checkbox-input" value={props.value} checked={props.checked} onChange={onChange} name={props.name} />
            <span className="checkbox-indicator" />
            {props.children}
        </label>
    );
};

Checkbox.propTypes = {
    className: PropTypes.string,
    onChange:  PropTypes.func,
    checked:   PropTypes.bool,
    name:      PropTypes.string,
    value:     PropTypes.string,
    children:  PropTypes.node,
    disabled:  PropTypes.bool
};

Checkbox.defaultProps = {
    disabled:  false,
    className: "",
    value:     "",
    checked:   false
};


export { Checkbox };
