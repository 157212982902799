import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation, useTrigger } from "@circle/gestalt-app";
import { DashboardClusterWidget } from "./DashboardClusterWidget";
import { EventForm } from "../EventForm";
import { Modal } from "@circle/kip-components";
import { useParams } from "react-router-dom";
import { sort } from "../../helper/sort";

const EventsOverview = props => {
    const params            = useParams();
    const { trigger }       = useTrigger();
    const { translate }     = useTranslation();
    const [event, setEvent]       = useState(null);
    const [editable, setEditable] = useState(false);
    const [open, setOpen]         = useState(false);
    const [content, setContent]   = useState([]);
    const [isOrderAsc, setIsOrderAsc] = useState(true);

    const onAddClick = () => {
        setEditable(true);
        setOpen(true);
    };
    const onClose = () => {
        setEditable(false);
        setOpen(false);
        setEvent(null);
    };

    const onEventSubmit = x => {
        trigger("createEvent", x);
        trigger("fetchEvents", params.clusterId, params.plantId);

        onClose();
    };

    const onEventClick = x => {
        setEditable(false);
        setOpen(true);
        setEvent(x);
    };

    const onSort = elem => {
        setIsOrderAsc(!isOrderAsc);
        const sortedContent = sort({
            getter:   translate,
            items:    content,
            property: elem,
            ordering: isOrderAsc ? "asc" : "desc"
        });

        setContent(sortedContent);
    };

    useEffect(() => {
        setContent(props.data);
    }, []);

    return (
        <DashboardClusterWidget header={translate("dashboard.widgets.events")} onAdd={onAddClick}>
            {
                (props.data ?? []).length === 0 &&
                    <span>{translate("data.none")}</span>
            }
            {
                (props.data ?? []).length > 0 &&
                <table className="cluster-table">
                    <thead>
                        <tr>
                            <th
                                id="name"
                                onClick={() => onSort("name")}
                                className="event-entry"
                            >
                                { translate("dashboard.widgets.events.event") }
                            </th>
                            <th
                                id="endDateTime"
                                className="event-entry"
                                onClick={() => onSort("endDateTime")}
                            >
                                { translate("dashboard.widgets.events.duration") }
                            </th>
                            <th
                                id="startDateTime"
                                className="event-entry"
                                onClick={() => onSort("startDateTime")}
                            >
                                { translate("dashboard.widgets.events.time") }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            content?.map((x, i) => (
                                <tr className="click" key={i} onClick={() => onEventClick(x)}>
                                    <td>{ translate(x.name) }</td>
                                    <td>{ Math.round((new Date(x.endDateTime) - new Date(x.startDateTime)) / 1000 / 60) }</td>
                                    <td>{ new Date(x.startDateTime).toLocaleString([], { dateStyle: "short", timeStyle: "short", timeZone: "UTC" }).replace(",", " ") }</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
            <Modal
                isOpen={open}
                isCloseable={false}
                onClose={onClose}
                className="event-modal"
            >
                <div id="event-form" className="flex-column">
                    <EventForm
                        selectedCluster={props.cluster}
                        onClose={onClose}
                        event={event}
                        onSubmit={onEventSubmit}
                        isEditable={editable}
                        {...event}
                    />
                </div>
            </Modal>
        </DashboardClusterWidget>
    );
};

EventsOverview.propTypes = {
    selected:      PropTypes.object,
    isHistoryTile: PropTypes.bool,
    data:          PropTypes.arrayOf(PropTypes.object),
    cluster:       PropTypes.object
};

export { EventsOverview };
