import { useEffect } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { States, ProductsOverview, CombinedProductivity, ValuesOverview } from "../widgets";
import { EventsOverview } from "../widgets/EventsOverview";

const DashboardCluster = props => { // eslint-disable-line complexity
    const { trigger } = useTrigger();
    const { translate } = useTranslation();
    const { filters, dashboardEvents, dashboardProducts, dashboardCombined, dashboardOverview, dashboardStates, workingShiftsHash, selectedPlant } = useBranch({
        filters:           ["dashboardFilters"],
        dashboardEvents:   ["dashboardEvents"],
        dashboardProducts: ["dashboardProducts"],
        dashboardCombined: ["dashboardCombined"],
        dashboardOverview: ["dashboardOverview"],
        dashboardStates:   ["dashboardStates"],
        workingShiftsHash: ["workingShiftsHash"],
        selectedPlant:     ["selectedPlant"]
    });

    useEffect(() => {
        trigger("fetchDashboard", selectedPlant.id, props.cluster.id);
    }, [workingShiftsHash, props.cluster]);

    const showStatesTile = !props.cluster.name.includes("overall") &&
        ((props.cluster?.isStatesSumup === true && props.cluster?.plantElements?.length > 1) || props.cluster?.plantElements?.length === 1);

    return (
        <div className="dashboard-cluster">
            <div className="flex">
                <h4 className="cluster-header">
                    {translate(props.cluster?.name || "Cluster")}
                </h4>
            </div>
            <div className="cluster-widget-wrapper flex-row">
                <div className="cluster-widget-container">
                    {
                        filters.isShowdiagram && showStatesTile && props.cluster?.config.statesOverall &&
                        <States data={dashboardStates[props.cluster?.id]}/>
                    }
                    {
                        filters.isClusterTarget && props.cluster?.config?.parts &&
                        <ValuesOverview data={dashboardOverview[props.cluster?.id]} selectedCluster={props.cluster}/>
                    }
                    {
                        filters.isClusterSystem && props.cluster?.config?.productivity &&
                        <CombinedProductivity data={dashboardCombined[props.cluster?.id]} values={dashboardOverview[props.cluster?.id]} />
                    }
                    {
                        filters.isClusterProduct && props.cluster?.config?.producedProductTypes &&
                        <ProductsOverview data={dashboardProducts[props.cluster?.id]} showTimes={(props.cluster?.isSequentiallyOrdered || props.cluster?.plantElements.length <= 1) && props.cluster?.name !== "element.overall"} />
                    }
                    {
                        filters.isClusterEvents &&
                        <EventsOverview
                            data={dashboardEvents.events}
                            cluster={props.cluster}
                            isHistoryTile={false}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

DashboardCluster.propTypes = {
    selected: PropTypes.object,
    cluster:  PropTypes.object
};

export { DashboardCluster };
