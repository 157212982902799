import PropTypes from "prop-types";
import { Button, Icon } from "@circle/kip-components";

const Add = props => {
    return (
        <Button _variant="icon" _appearance="primary" onClick={props.onClick}>
            <Icon className="add-icon" _icon="Add"/>
        </Button>
    );
};

Add.propTypes = {
    onClick: PropTypes.func
};

Add.defaultProps = {
    onClick: x => x
};

export { Add };

