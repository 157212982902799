import PropTypes from "prop-types";
import { DashboardClusterWidget } from "./DashboardClusterWidget";
import { useTranslation } from "@circle/gestalt-app";

// eslint-disable-next-line complexity
const ValuesOverview = props => {
    const { translate, currentLanguage } = useTranslation();
    const showSortedParts = (props.selectedCluster.isSortedOutSumup === false && props.selectedCluster.plantElements.length > 1);
    const isEmpty         = !props.data || Object.values(props.data).every(x => x === false);

    return (
        <DashboardClusterWidget header={translate("dashboard.widgets.overview")}>
            {
                isEmpty &&
                <span>{translate("data.none")}</span>
            }
            {
                !isEmpty &&
                <table className="cluster-table">
                    <tbody>
                        {
                            props.data.target >= 0 &&
                        <tr>
                            <td>{ translate("dashboard.widgets.overview.target") }</td>
                            <td><b>{ !props.data.target ? "-" : Number(props.data.target).toLocaleString(currentLanguage) }</b></td>
                        </tr>
                        }
                        {
                            (props.data.actual !== false && props.data.actual >= 0) &&
                        <tr>
                            <td>{ translate("dashboard.widgets.overview.actual") }</td>
                            <td><b>{ Number(props.data.actual).toLocaleString(currentLanguage) }</b></td>
                        </tr>
                        }
                        {
                            !showSortedParts && (props.data.waste !== false && props.data.waste >= 0) &&
                            <tr>
                                <td>{ translate("dashboard.widgets.overview.waste") }</td>
                                <td><b>{ props.data.waste.toLocaleString(currentLanguage) }</b></td>
                            </tr>
                        }
                        {
                            (props.data.averagePassTime !== false && props.data.averagePassTime >= 0) && (props.selectedCluster.isSequentiallyOrdered || props.selectedCluster.plantElements.length === 1) &&
                        <tr>
                            <td>{ translate("dashboard.widgets.overview.throughputTimePerPart") }</td>
                            <td><b>{ `${Number(props.data.averagePassTime ? props.data.averagePassTime : 0).toLocaleString(currentLanguage)}s`}</b></td>
                        </tr>
                        }
                        {
                            (props.data.averageEditTime !== false && props.data.averageEditTime >= 0) && (props.selectedCluster.isSequentiallyOrdered || props.selectedCluster.plantElements.length === 1) &&
                        <tr>
                            <td>{translate("dashboard.widgets.overview.constructionTimePerPart")}</td>
                            <td><b>{ `${Number(props.data.averageEditTime ? props.data.averageEditTime : 0).toLocaleString(currentLanguage)}s`}</b></td>
                        </tr>
                        }
                        {
                            (props.data.averageParts !== false && props.data.averageParts >= 0) &&
                            <tr>
                                <td>{ translate("dashboard.widgets.overview.partsPerHour") }</td>
                                <td><b>{ `${Number(props.data.averageParts ? props.data.averageParts : 0).toLocaleString(currentLanguage)}`}</b></td>
                            </tr>
                        }
                    </tbody>
                </table>
            }
        </DashboardClusterWidget>
    );
};

ValuesOverview.propTypes = {
    data:            PropTypes.object,
    selectedCluster: PropTypes.object
};

export { ValuesOverview };
