import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useBranch } from "@circle/gestalt-app";
import { Overview } from "./overview/Overview";
import { ContentHeader } from "./ContentHeader";
import { GraphicalContent } from "./GraphicalContent";
import { Settings } from "./settings/Settings";

const Content = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedPlant, plants } = useBranch({
        selectedPlant: ["selectedPlant"],
        plants:        ["licensedPlants"]
    });

    useEffect(() => {
        const split = location.pathname.split("/");
        const index = split[1] === "admin" ? 3 : 2;
        const plant = plants.find(elem => elem.id === split[index]);

        if(split[2] && split[1] === "overview") navigate("/overview", { replace: true });

        if(selectedPlant?.id === plant?.id || split[1] === "overview") return;
    }, [location.pathname, plants, selectedPlant]); // eslint-disable-line

    return (
        <>
            <ContentHeader/>
            <Routes>
                <Route path="/overview/*" element={<Overview/>}/>
                <Route path="/dashboard/*" element={<GraphicalContent/>}/>
                <Route path="/history/*" element={<GraphicalContent/>}/>
                <Route path="/admin/*" element={<Settings/>}/>
                <Route path="*" element={<Navigate to="/overview" replace/>}/>
            </Routes>
        </>
    );
};

export { Content };
