import { useState } from "react";
import PropTypes from "prop-types";
import { FormActions } from "../generic/FormActions";
import md5 from "md5";
import { TextInput } from "@circle/kip-components";
import { useTranslation } from "@circle/gestalt-app";

const MultiSettingsForm = props => {
    const { translate } = useTranslation();
    const init = {
        id:        props.selectedClusters[0].id || null,
        name:      props.selectedClusters[0].name || [],
        createdAt: props.selectedClusters[0].createdAt,
        updatedAt: props.selectedClusters[0].updatedAt || null,
        plantId:   props.selectedClusters[0].plantId || null,
        clusterId: props.selectedClusters[0].clusterId || null,
        targets:   props.selectedClusters[0].targets || []
    };

    const [content, setContent] = useState(init);
    const [hash]                = useState(md5(JSON.stringify(init)));

    const onChange = (key, value) => {
        const values = {
            ...content,
            [key]: value
        };
        const currentHash = md5(JSON.stringify(values));

        setContent(values);

        props.onChange(key, values[key], currentHash !== hash);
    };

    const onCancel = () => {
        return props.selectedClusters.map(elem => props.onCancel(elem));
    };

    const onSubmit = () => {
        props.selectedClusters.forEach(elem => {
            const previous = elem.targets.map(x => x.id).filter(x => x);
            const filtered = content.targets.filter(x => previous.includes(x.id));
            const others   = content.targets.filter(x => !previous.includes(x.id));

            props.onSubmit({
                id:        elem.id,
                createdAt: elem.createdAt,
                updatedAt: elem.updatedAt,
                plantId:   elem.clusterId ? null : elem.plantId,
                clusterId: elem.clusterId,
                targets:   filtered.concat(others.map(x => ({
                    workingShiftsId: x.workingShiftsId,
                    target:          x.target
                })))
            });
        });

        return onCancel();
    };

    const onTargetChange = (index, e) => {
        const newTargets = content.targets.concat([]);

        newTargets[index] = ({
            ...newTargets[index],
            target: parseInt(e.target.value, 10)
        });

        onChange("targets", newTargets);
    };

    return (
        <>
            <div>
                <div className="form">
                    <span className="header font-bold">{ translate("sidebar.headline").toUpperCase() }</span>
                    <div className="flex form-row">
                        <div className="form-column flex-column flex-grow">
                            <span className="form-header font-bold">
                                { translate("name.multi.headline") }
                            </span>
                            {
                                props.selectedClusters.map((x, index) => (
                                    <div key={index}>
                                        <TextInput
                                            className={index > 0 ? "mt-2" : ""}
                                            value={translate(x.name)}
                                            readOnly
                                            placeholder={translate("name.headline")}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="flex form-row">
                        <div className="form-column flex-column flex-grow">
                            <span className="form-header font-bold">
                                { translate("target.headline") }
                            </span>
                        </div>
                    </div>
                    {
                        content.targets.map((x, key) => (
                            <div key={key} className="flex form-row">
                                <div className="flex flex-grow">
                                    <span className="form-header shift-label font-bold mt-2">
                                        { translate(x.name) }
                                    </span>
                                    <TextInput
                                        className="target-input ml-2"
                                        value={x.target}
                                        onChange={e => onTargetChange(key, e)}
                                    />
                                    <span className="unit-label mt-2 ml-5">
                                        { translate("tile.unit") }
                                    </span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <FormActions
                onCancel={onCancel}
                onSubmit={onSubmit}
                isValid={({ overall: true, required: true })}
                validationText={ "actions.notValid" }
                hasLanguageToggle={ false }
            />
        </>
    );
};

MultiSettingsForm.propTypes = {
    id:               PropTypes.string,
    name:             PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.string]),
    plantId:          PropTypes.string,
    clusterId:        PropTypes.string,
    targets:          PropTypes.arrayOf(PropTypes.object),
    onChange:         PropTypes.func,
    onCancel:         PropTypes.func,
    onSubmit:         PropTypes.func,
    selectedClusters: PropTypes.arrayOf(PropTypes.object)
};

MultiSettingsForm.defaultProps = {
    onChange: x => x,
    onCancel: x => x,
    onSubmit: x => x
};

export { MultiSettingsForm };
