import { App as BasicApp, sync, translation, computed, action, getLanguageBy, hasPermission } from "@circle/gestalt-app";
import { DatePicker } from "./types/DatePicker";
import { OverviewWidgetData } from "./types/OverviewWidgetData";
import { CombinedWidgetData, ProductivityDataEntry } from "./types/CombinedWidgetData";
import { ProductsWidgetData, ProductItem } from "./types/ProductsWidgetData";
import { Slice } from "./types/Slice";
import { StatesDistribution } from "./types/StatesDistribution";
import { WorkingShiftsADay } from "./types/WorkingShiftsADay";
import timeSpans from "./enums/timeSpans";
import { min, round, toFakeUTC, fromFakeUTC, getZIndex, isUuid } from "./helper/helper";
import { v4 as uuid } from "uuid";
import md5 from "md5";
import { getConfigOfCluster } from "./helper/cluster";
import { createBrowserHistory } from "history";
import { Overall } from "./types/Overall";
import { dateToString } from "./helper/date";
import { sort } from "./helper/sort";
import dashboardFilter from "./enums/dashboardFilter";

const getShiftTime = shift => {
    if(!shift) return null;

    const startTime = new Date(shift.startTime);
    const endTime   = new Date(shift.endTime);

    return [`${`0${startTime.getUTCHours()}`.slice(-2)}:${`0${startTime.getUTCMinutes()}`.slice(-2)}`, `${`0${endTime.getUTCHours()}`.slice(-2)}:${`0${endTime.getUTCMinutes()}`.slice(-2)}`];
};

const getPath = x => {
    const pathParts = x.split("/");

    if(x.startsWith("/dashboard") || x.startsWith("/history")) return `/${pathParts[1]}`;

    return `/${pathParts[1]}/${pathParts[2]}`;
};

const createQueryString = filters => {
    const format = value => typeof value === "string" ? value.replaceAll("+", "%2B") : value;

    return Object.keys(filters).reduce((dest, val) => dest.concat(`${val}=${filters[val] instanceof Array ? `[${filters[val]}]&` : `${format(filters[val])}&`}`), "").slice(0, -1);
};

const removeEmptyKeys = val => Object.keys(val).reduce((dest, elem) => !val[elem] ? dest : ({
    ...dest,
    [elem]: val[elem]
}), {});

const getWorkingShift = plant => {
    const currentDate   = new Date();
    const currentHour   = currentDate.getHours();
    const currentMinute = Math.floor(currentDate.getMinutes() / 15) * 15;
    const shiftPlan     = WorkingShiftsADay.of({
        shifts: plant.workingShifts.map(x => Object.assign({}, x, {
            startTime: new Date(x.startTime),
            endTime:   new Date(x.endTime)
        })),
        date: new Date()
    });

    return shiftPlan[currentHour][currentMinute];
};

class App extends BasicApp {
    plants = sync("plants");
    clusters = sync("clusters");
    plant_elements = sync("plant_elements"); // eslint-disable-line camelcase
    dashboards = sync("dashboards");
    events = sync("events");
    processes = sync("processes");
    manufacturers = sync("manufacturers");
    target_settings = sync("target_settings"); // eslint-disable-line camelcase
    licenses = sync("licenses", {
        readonly: true
    });

    plant_element_states = sync("plant_element_states", { // eslint-disable-line camelcase
        readonly: true,
        empty:    true
    });

    aggregated_states = sync("aggregated_states", { // eslint-disable-line camelcase
        readonly: true,
        empty:    true
    });

    aggregated_parts = sync("aggregated_parts", { // eslint-disable-line camelcase
        readonly: true,
        empty:    true
    });


    cause_reports = sync("cause_reports"); // eslint-disable-line camelcase

    cause_categories = sync("cause_categories", { // eslint-disable-line camelcase
        readonly: true
    });

    translations = translation({
        de: "/translations/texts_de.yml",
        en: "/translations/texts_en.yml"
    });

    static state = {
        history:                      createBrowserHistory(),
        apps:                         [],
        language:                     getLanguageBy(window.config.languages.map(x => x.value)),
        plants:                       [],
        clusters:                     [],
        // eslint-disable-next-line camelcase
        plant_elements:               [],
        dashboards:                   [],
        events:                       [],
        licenses:                     [],
        processes:                    [],
        plantOverview:                [],
        target_settings:              [], // eslint-disable-line camelcase
        plant_element_produced_parts: [], // eslint-disable-line camelcase,id-length
        plant_element_states:         [], // eslint-disable-line camelcase,id-length
        aggregated_states:            [], // eslint-disable-line camelcase
        aggregated_parts:             [], // eslint-disable-line camelcase
        cause_reports:                [], // eslint-disable-line camelcase
        cause_categories:             [], // eslint-disable-line camelcase
        manufacturers:                [],
        translations:                 {},
        default:                      window.config.defaultLanguage,
        languages:                    window.config.languages,
        selectedPlantEvent:           null,
        selectedClusters:             [],
        selectedShift:                null,
        currentWorkingShifts:         [],
        isSidebarOpen:                false,
        fullScreenWidget:             false,
        sliderSettings:               {
            shortcut: "top10",
            range:    {
                from: 0,
                to:   10
            }
        },
        filter:       { selected: "name", order: "asc", language: getLanguageBy(window.config.languages.map(x => x.value)) },
        calendar:     null,
        dataHistory:  {},
        dataOverview: {
            parts:  {},
            events: {},
            states: {}
        },
        dashboardFilters: document.cookie !== "" ? document.cookie.split(";").map(x => ({
            key:   x.split("=")[0].replace(/ /g, ""),
            value: x.split("=")[1]
        }))
        .filter(x => x.key === "dashboards")
        .reduce((dest, obj) => JSON.parse(obj.value), {
            id:               null,
            isShowdiagram:    true,
            isClusterTarget:  true,
            isClusterSystem:  true,
            isClusterProduct: true,
            isClusterEvents:  true
        }) : {
            id:               null,
            isShowdiagram:    true,
            isClusterTarget:  true,
            isClusterSystem:  true,
            isClusterProduct: true,
            isClusterEvents:  true
        },
        queryOptions: {
            filter:        null,
            selectedPlant: null,
            shift:         null,
            clusters:      [],
            widgets:       ["events", "product", "system", "target", "diagram"]
        },
        search: {
            value:     "",
            processed: {
                value: "",
                time:  -1
            },
            result: []
        },
        counter: {
            global:  0,
            monitor: 0
        },
        clock:    Date.now(),
        ordering: {
            order:  "desc",
            column: "product"
        },
        sorting: {
            isOrderAsc: true,
            property:   "name"
        }
    };

    // --- computations ---

    sortedPlants = computed({
        plants:    ["plants"],
        translate: ["translate"]
    }, data => {
        if(!data.plants) return [];

        return sort({
            getter:   data.translate,
            items:    data.plants,
            property: "name",
            ordering: "asc"
        });
    });

    sortedClusters = computed({
        clusters:  ["clusters"],
        translate: ["translate"],
        sorting:   ["sorting"]
    }, data => {
        if(!data.clusters) return [];

        return sort({
            getter:   data.translate,
            items:    data.clusters,
            property: data.sorting.property,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc"
        });
    });

    sortedDashboardEvents = computed({
        dashboardEvents: ["dashboardEvents"],
        translate:       ["translate"],
        sorting:         ["sorting"]
    }, data => {
        if(!data.dashboardEvents) return [];

        return sort({
            getter:   data.translate,
            items:    data.dashboardEvents.events,
            property: data.sorting.property,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc"
        });
    });

    sortedPlantElementsDropdown = computed({
        plantElements: ["plant_elements"],
        translate:     ["translate"]
    }, data => {
        if(!data.plantElements) return [];

        return sort({
            getter:   data.translate,
            items:    data.plantElements,
            property: "name",
            ordering: "asc"
        });
    });

    sortedPlantElements = computed({
        plantElements: ["sortedPlantElementsDropdown"],
        translate:     ["translate"],
        sorting:       ["sorting"]
    }, data => {
        if(!data.plantElements) return [];
        return sort({
            getter:   data.translate,
            items:    data.plantElements,
            property: data.sorting.property,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc"
        });
    });

    sortedDashboardsFilter = computed({
        dashboards: ["dashboards"],
        translate:  ["translate"]
    }, data => {
        if(!data.dashboards) return [];

        return sort({
            getter:   data.translate,
            items:    data.dashboards,
            property: "name",
            ordering: "asc"
        });
    });

    sortedDashboards = computed({
        dashboards: ["sortedDashboardsFilter"],
        translate:  ["translate"],
        sorting:    ["sorting"]
    }, data => {
        if(!data.dashboards) return [];

        return sort({
            getter:   data.translate,
            items:    data.dashboards,
            property: data.sorting.property,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc"
        });
    });

    sortedTargetSettings = computed({
        targetSettings: ["target_settings"],
        translate:      ["translate"],
        sorting:        ["sorting"]
    }, data => {
        if(!data.targetSettings) return [];

        return sort({
            getter:   data.translate,
            items:    data.targetSettings,
            property: data.sorting.property,
            ordering: data.sorting.isOrderAsc ? "asc" : "desc"
        });
    });

    licensedPlants = computed({
        licenses: ["licenses"],
        plants:   ["sortedPlants"],
        user:     ["user"]
    }, data => {
        const plantIds = data.licenses
            .reduce((dest, elem) => dest.concat(elem.licenses.filter(x => x.appId === "productivity-monitor")
            .filter(x => new Date(x.expiryDate) > new Date() || x.expiryDate !== null)
            .map(x => x.plantId)), []);

        if(hasPermission(data.user, "SKIP_LICENSE_CHECK")) return data.plants;

        return [...new Set(plantIds)]
            .filter(elem => elem)
            .map(x => data.plants.find(elem => elem.id === x))
            .filter(x => x);
    });

    allLanguages = computed({
        languages: ["languages"],
        translate: ["translate"]
    }, data => {
        const translate = data.translate ? data.translate : elem => elem;

        return data.languages.map(elem => ({
            value: elem.value,
            label: translate(elem.value)
        }));
    });

    fullPlants = computed({
        plants:        ["licensedPlants"],
        manufacturers: ["manufacturers"]
    }, data => {
        if(!data.plants || !data.manufacturers) return [];

        return data.plants.map(x => Object.assign({}, x, {
            manufacturer: data.manufacturers.find(y => y.id === x.manufacturerId)
        }));
    });

    overview = computed({
        plants:               ["licensedPlants"],
        targetSettings:       ["sortedTargetSettings"],
        currentWorkingShifts: ["currentWorkingShifts"],
        producedParts:        ["plant_element_produced_parts"],
        plantElements:        ["sortedPlantElements"],
        aggregatedParts:      ["aggregated_parts"],
        overview:             ["plantOverview"],
        manufacturers:        ["manufacturers"]
    }, data => {
        return (data.plants ?? []).map(x => { // eslint-disable-line complexity
            const targetSetting  = data.targetSettings.find(setting => setting.plantId === x.id);
            const currentShift   = getWorkingShift(x);
            const startingTime   = !currentShift ? fromFakeUTC(Date.now()) - 8 * 60 * 60 * 1000 : currentShift.realUTCStartTime.getTime();
            const endingTime     = !currentShift ? fromFakeUTC(Date.now()).getTime() : currentShift.realUTCEndTime.getTime();
            const target         = targetSetting?.targets.find(elem => elem.workingShiftsId === currentShift?.id);
            const range          = [startingTime, endingTime];
            const duration       = range[1] - range[0];
            const relevantElems  = data.plantElements.filter(elem => elem.plantId === x.id && elem.isPlantReference);
            const overview       = data.overview.find(y => y.plantId === x.id) ?? {
                period:   0,
                produced: 0
            };
            const parts = data.aggregatedParts
                .filter(y => relevantElems.map(z => z.id).includes(y.plantElementId)) // eslint-disable-line max-nested-callbacks
                .filter(part => new Date(part.timestamp).getTime() >= startingTime && new Date(part.timestamp).getTime() < endingTime)
                .reduce((dest, y) => ({
                    ...dest,
                    produced: y.produced + dest.produced
                }), overview);

            const displayTargets = target && target.target;
            const alternativePicture = data.manufacturers.find(manufacturer => manufacturer.id === x.manufacturerId);

            return {
                id:                 x.id,
                name:               x.name,
                image:              x.image,
                location:           x.location,
                shift:              currentShift,
                alternativePicture: alternativePicture.logo,
                showParts:          relevantElems.length > 0,
                parts:              {
                    current: parts.produced,
                    target:  displayTargets ? target.target : null
                },
                partsPerTime: {
                    current: duration > 0 ? round(parts.produced / parts.period, 2) : null,
                    target:  displayTargets ? round(target.target / (duration / 1000 / 60 / 60), 2) : null
                }
            };
        });
    });

    historyEvents = computed({
        clusters:      ["selectedClusters"],
        calendar:      ["calendar"],
        selectedShift: ["selectedShift"],
        events:        ["events"]
    }, data => {
        return (data.clusters ?? [])
            .reduce((dest, cluster) => {
                const plantElementIds = cluster.plantElements.map(x => x.plant_element_id);
                const events          = data.events
                    .map(x => ({
                        ...x,
                        startDateTime: new Date(x.startDateTime),
                        endDateTime:   new Date(x.endDateTime)
                    }))
                    .filter(x => x.plantElements.map(elem => elem.plant_element_id).filter(value => plantElementIds.includes(value))) // eslint-disable-line max-nested-callbacks
                    .filter(event => event.startDateTime >= data.calendar.from && event.startDateTime < data.calendar.until);

                const filtered = !data.selectedShift ?
                    events :
                    events.filter(x => {
                        const start = new Date((x.startDateTime.getHours() * 3600 + x.startDateTime.getMinutes() * 60) * 1000);

                        return new Date(data.selectedShift.startTime) <= start && start < new Date(data.selectedShift.endTime);
                    });

                return {
                    ...dest,
                    [cluster.id]: filtered.reduce((init, elem) => init.find(x => x.id === elem.id) ? init : init.concat(elem), []), // eslint-disable-line max-nested-callbacks
                    [null]:       dest.null.concat(filtered)
                };
            }, {
                null: []
            });
    });

    joinedClusters = computed({
        plantElements: ["sortedPlantElements"],
        clusters:      ["sortedClusters"]
    }, data => {
        return !data.clusters || !data.plantElements ? [] : data.clusters
            .map(x => ({
                ...x,
                plantElements: x.plantElements.map(plantElementRelation => ({
                    ...plantElementRelation,
                    type: data.plantElements?.find(elem => elem.id === plantElementRelation.plant_element_id)?.type // eslint-disable-line max-nested-callbacks
                }))
            })).map(x => ({
                ...x,
                config: getConfigOfCluster(x)
            }));
    });

    dashboardEvents = computed({
        clusters:             ["selectedClusters"],
        selectedPlant:        ["selectedPlant"],
        currentWorkingShifts: ["currentWorkingShifts"],
        events:               ["events"],
        plantElements:        ["plantElements"]
    }, data => {
        const currentShift = (data.currentWorkingShifts ?? []).find(x => x.plants_id === data.selectedPlant?.id);
        const now          = Date.now();
        const nextFullHour = new Date(now).setHours(new Date(now).getHours() + 1, 0, 0, 0);

        return (data.clusters ?? [])
            .reduce((dest, cluster) => {
                const plantElementIds = cluster.plantElements.map(x => x.plant_element_id);
                const plantEvents     = data.events.filter(x => x.plantId === data.selectedPlant.id);
                const events          = plantEvents
                    .map(x => ({
                        ...x,
                        startDateTime: new Date(x.startDateTime),
                        endDateTime:   new Date(x.endDateTime)
                    }))
                    .filter(x => x.plantElements.map(elem => elem.plant_element_id).filter(value => plantElementIds.includes(value))); // eslint-disable-line max-nested-callbacks
                const activeEvents    = !currentShift ?
                    events.filter(event => event.startDateTime.getTime() >= fromFakeUTC(nextFullHour - timeSpans.hour.multiplier * 8).getTime() && event.startDateTime.getTime() < fromFakeUTC(now).getTime()) :
                    events.filter(event => event.startDateTime.getTime() >= currentShift.realUTCStartTime.getTime() && event.startDateTime.getTime() < currentShift.realUTCEndTime.getTime());

                return {
                    ...dest,
                    events: activeEvents.reduce((init, elem) => init.find(x => x.id === elem.id) ? init : init.concat(elem), []), // eslint-disable-line max-nested-callbacks
                    [null]: dest.null.concat(activeEvents)
                };
            }, {
                null: []
            });
    });

    dashboardProducts = computed({
        clusters:             ["selectedClusters"],
        parts:                ["aggregated_parts"],
        selectedPlant:        ["selectedPlant"],
        currentWorkingShifts: ["currentWorkingShifts"],
        initialParts:         ["dataOverview", "parts"],
        ordering:             ["ordering"]
    }, data => {
        const currentShift = (data.currentWorkingShifts ?? []).find(x => x.plants_id === data.selectedPlant.id);
        const now          = Date.now();
        const nextFullHour = new Date(now).setHours(new Date(now).getHours() + 1, 0, 0, 0);

        return (data.clusters ?? [])
            .reduce((dest, cluster) => {
                const plantElementIds = cluster.plantElements.map(x => x.plant_element_id);
                const parts           = (data.initialParts[cluster.id] ?? []).concat(data.parts);
                const timestamp       = !currentShift ? [nextFullHour - (timeSpans.hour.multiplier * 8), now] : [currentShift.realStartTime.getTime(), currentShift.realEndTime.getTime()];
                const activeParts     = parts
                    .filter(part => plantElementIds.includes(part.plantElementId))
                    .filter(part => toFakeUTC(part.startTime).getTime() >= timestamp[0] && toFakeUTC(part.startTime).getTime() < timestamp[1])
                    .filter(part => part.producedAmount > 0)
                    .reduce((init, elem) => init.find(x => elem.id === x.id) ? init.filter(x => x.id !== elem.id).concat(elem) : init.concat(elem), []); // eslint-disable-line max-nested-callbacks

                const initialState = [...new Set(activeParts.map(x => x?.productType))].reduce((init, elem) => ({
                    ...init,
                    [elem]: {
                        id:             elem,
                        processed:      0,
                        processingTime: 0
                    }
                }), {});

                const measuringElements = !cluster.measuringElement ? plantElementIds : [cluster.measuringElement];

                const products = activeParts
                    .reduce((init, val) => {
                        return ({
                            ...init,
                            [val.productType]: {
                                ...init[val.productType],
                                processed:      parseInt(init[val.productType].processed, 10) + (measuringElements.includes(val.plantElementId) ? parseInt(val.producedAmount, 10) : 0),
                                processingTime: parseInt(init[val.productType].processingTime, 10) + parseInt(val.processingTime, 10)
                            }
                        });
                    }, initialState);

                return {
                    ...dest,
                    [cluster.id]: new ProductsWidgetData([...new Set(activeParts.map(x => x.productType))]
                        .map(x => new ProductItem(products[x].id, products[x].processed, round(products[x].processingTime / (1000 * products[x].processed), 2)))
                        .sort((a, b) => {
                            const valueA = !isNaN(parseFloat(a[data.ordering.column], 10)) ? a[data.ordering.column] : a[data.ordering.column].toString().toLowerCase();
                            const valueB = !isNaN(parseFloat(a[data.ordering.column], 10)) ? b[data.ordering.column] : b[data.ordering.column].toString().toLowerCase();


                            if(data.ordering.order === "asc")
                                return (valueA > valueB ? 1 : -1);

                            return (valueA < valueB ? 1 : -1);
                        })
                    )
                };
            }, {});
    });

    dashboardCombined = computed({
        clusters:             ["selectedClusters"],
        parts:                ["aggregated_parts"],
        selectedPlant:        ["selectedPlant"],
        currentWorkingShifts: ["currentWorkingShifts"],
        initialParts:         ["dataOverview", "parts"],
        targetSettings:       ["sortedTargetSettings"],
        locale:               ["locale"],
        clock:                ["clock"]
    }, data => {
        const currentShift  = (data.currentWorkingShifts ?? []).find(x => x.plants_id === data.selectedPlant.id);
        const now           = Date.now();
        const nextFullHour  = new Date(now).setHours(new Date(now).getHours() + 1, 0, 0, 0);
        const timestamp     = !currentShift ? [now - (timeSpans.hour.multiplier * 8), now] : [currentShift.realStartTime.getTime(), currentShift.realEndTime.getTime()];
        const duration      = !currentShift ? Math.ceil((now - timestamp[0]) / 3600000) : Math.ceil((now - new Date(timestamp[0]).setUTCHours(new Date(timestamp[0]).getUTCHours(), 0, 0, 0)) / 3600000);
        const shiftDuration = Math.ceil((timestamp[1] - timestamp[0]) / 3600000);

        return (data.clusters ?? [])
            .reduce((dest, cluster) => {
                const plantElementIds   = cluster.plantElements.map(x => x.plant_element_id);
                const targetSetting     = data.targetSettings.find(setting => setting.clusterId === cluster.id);
                const target            = targetSetting?.targets.find(elem => elem.workingShiftsId === currentShift?.id);
                const measuringElements = !cluster.measuringElement ? plantElementIds : [cluster.measuringElement];
                const parts             = (data.initialParts[cluster.id] ?? [])
                    .concat(data.parts)
                    .filter(part => measuringElements.includes(part.plantElementId))
                    .reduce((init, elem) => init.find(x => elem.id === x.id) ? init.filter(x => x.id !== elem.id).concat(elem) : init.concat(elem), []); // eslint-disable-line max-nested-callbacks

                const startingTime = !currentShift ? fromFakeUTC(nextFullHour - timeSpans.hour.multiplier * 8) : new Date(new Date(currentShift.realUTCStartTime).setUTCMinutes(0));

                if(!cluster.config.productivity) return {};

                return {
                    ...dest,
                    [cluster.id]: new CombinedWidgetData(new Array(duration)
                        .fill(null)
                        .reduce((overall, x, key) => {
                            const startTime   = startingTime.getTime() + timeSpans.hour.multiplier * key;
                            const endTime     = startingTime.getTime() + timeSpans.hour.multiplier * (key + 1);
                            const activeParts = parts.filter(part => new Date(part.startTime).getTime() >= startTime && new Date(part.startTime).getTime() < endTime); // eslint-disable-line max-nested-callbacks
                            const actual      = activeParts.reduce((init, val) => init + val.producedAmount, 0); // eslint-disable-line max-nested-callbacks
                            const sum         = overall[key - 1] ? overall[key - 1].actual + actual : actual;

                            return overall.concat([new ProductivityDataEntry(
                                `${new Date(startTime).getUTCHours()}:00`,
                                Number(Math.floor(target && target.target ? (target.target / shiftDuration) * (key + 1) : 0)),
                                Number(sum),
                                Number(actual)
                            )]);
                        }, []))
                };
            }, {});
    });

    dashboardOverview = computed({
        clusters:             ["selectedClusters"],
        parts:                ["aggregated_parts"],
        selectedPlant:        ["selectedPlant"],
        currentWorkingShifts: ["currentWorkingShifts"],
        initialParts:         ["dataOverview", "parts"],
        targetSettings:       ["sortedTargetSettings"]
    }, data => {
        const currentShift = (data.currentWorkingShifts ?? []).find(x => x.plants_id === data.selectedPlant.id);
        const now          = Date.now();
        const nextFullHour = new Date(now).setHours(new Date(now).getHours() + 1, 0, 0, 0);
        const timestamp    = !currentShift ? [fromFakeUTC(nextFullHour - timeSpans.hour.multiplier * 8).getTime(), fromFakeUTC(now).getTime()] : [currentShift.realUTCStartTime.getTime(), currentShift.realUTCEndTime.getTime()];

        return (data.clusters ?? [])
        // eslint-disable-next-line complexity
            .reduce((dest, cluster) => {
                const plantElementIds    = cluster.plantElements.map(x => x.plant_element_id);
                const allPlantElementIds = cluster.allElements ? cluster.allElements.map(x => x.plant_element_id) : plantElementIds;
                const targetSetting      = data.targetSettings.find(setting => setting.clusterId === cluster.id);
                const target             = targetSetting?.targets.find(elem => elem.workingShiftsId === currentShift?.id);
                const durationMinutes    = !currentShift ? 60 * 8 : parseInt((now - currentShift.realStartTime.getTime()) / 1000 / 60, 10);
                const parts              = (data.initialParts[cluster.id] ?? [])
                    .concat(data.parts)
                    .filter(part => allPlantElementIds.includes(part.plantElementId))
                    .filter(part => new Date(part.startTime).getTime() >= timestamp[0] && new Date(part.startTime).getTime() < timestamp[1])
                    .reduce((init, elem) => init.find(x => elem.id === x.id) ? init.filter(x => x.id !== elem.id).concat(elem) : init.concat(elem), []); // eslint-disable-line max-nested-callbacks

                const measuringElements = !cluster.measuringElement ? plantElementIds : [cluster.measuringElement];

                const productData = parts
                    .reduce((init, val) => ({
                        processed:      init.processed + (measuringElements.includes(val.plantElementId) ? parseInt(val.producedAmount, 10) : 0),
                        sorted:         init.sorted + parseInt(val.sortedOutAmount, 10),
                        processingTime: init.processingTime + parseInt(val.processingTime, 10),
                        duration:       init.duration + parseInt(val.duration, 10)
                    }), {
                        processed:      0,
                        sorted:         0,
                        processingTime: 0,
                        duration:       0
                    });

                return {
                    ...dest,
                    [cluster.id]: new OverviewWidgetData({
                        target:          cluster.config.targetAmount ? target?.target : false,
                        actual:          cluster.config.currentAmount ? round(productData.processed, 2) : false,
                        waste:           cluster.config.waste ? round(productData.sorted, 2) : false,
                        averagePassTime: cluster.config.throughputTime ? (productData.processed === 0 ? 0 : round(productData.duration / (1000 * productData.processed), 2)) : false, // eslint-disable-line no-nested-ternary
                        averageEditTime: cluster.config.constructionTime ? (productData.processed === 0 ? 0 : round(productData.processingTime / (1000 * productData.processed), 2)) : false, // eslint-disable-line no-nested-ternary
                        averageParts:    cluster.config.parts ? round(productData.processed / durationMinutes, 2) : false
                    })
                };
            }, {});
    });

    dashboardStates = computed({
        clusters:             ["selectedClusters"],
        selectedPlant:        ["selectedPlant"],
        currentWorkingShifts: ["currentWorkingShifts"],
        initialStates:        ["dataOverview", "states"],
        states:               ["aggregated_states"],
        initialEvents:        ["dataOverview", "events"],
        events:               ["events"]
    }, data => {
        const currentShift = (data.currentWorkingShifts ?? []).find(x => x.plants_id === data.selectedPlant.id);
        const now          = Date.now();
        const nextFullHour = new Date(now).setHours(new Date(now).getHours() + 1, 0, 0, 0);
        const timestamp    = !currentShift ? [nextFullHour - (timeSpans.hour.multiplier * 8), now] : [currentShift.realStartTime.getTime(), currentShift.realEndTime.getTime()];

        return (data.clusters ?? [])
            .reduce((dest, cluster) => {
                const activeStates = (data.initialStates[cluster.id] || []).concat(data.states.filter(state => state.clusterId === cluster.id))
                    .filter(part => toFakeUTC(part.startTime).getTime() >= timestamp[0] && toFakeUTC(part.startTime).getTime() < timestamp[1])
                    .reduce((init, elem) => init.find(x => elem.id === x.id) ? init.filter(x => x.id !== elem.id).concat(elem) : init.concat(elem), []); // eslint-disable-line max-nested-callbacks

                if(!cluster.config.statesOverall) return {
                    ...dest,
                    [cluster.id]: {}
                };

                return {
                    ...dest,
                    [cluster.id]: {
                        data: StatesDistribution.of({
                            states: activeStates,
                            events: [],
                            from:   timestamp[0],
                            until:  new Date(now)
                        }).map(x => new Slice(x))
                    }
                };
            }, {});
    });

    clusterStates = computed({
        clusters: ["selectedClusters"],
        initial:  ["dataOverview", "clusterState"],
        states:   ["plant_element_states"]
    }, data => {
        return (data.clusters ?? [])
            .reduce((dest, cluster) => {
                const { timestamp, states } = ((data.initial && data.initial[cluster.id]) || {});
                const plantElements = Object.keys(states || {});
                const activeStates  = (data.states || [])
                    .map(x => ({ ...x, timestamp: new Date(new Date(x.timestamp).setUTCHours(new Date(x.timestamp).getUTCHours() - 1)) }))
                    .filter(x => x.timestamp > new Date(timestamp) && x.timestamp < new Date())
                    .filter(x => plantElements.includes(x.plantElementId))
                    .sort((a, b) => a.timestamp - b.timestamp);
                const statesPerElement = activeStates.reduce((init, value) => ({
                    ...init,
                    [value.plantElementId]: value.state
                }), states);

                return {
                    ...dest,
                    [cluster.id]: !states ?
                        null :
                        Object.values(statesPerElement).reduce((init, value) => getZIndex(value) > getZIndex(init) ? value : init, null)
                };
            }, {});
    });

    targetSettings = computed({
        clusters:       ["joinedClusters"],
        plant_elements: ["sortedPlantElements"], // eslint-disable-line camelcase
        selectedPlant:  ["selectedPlant"],
        plants:         ["licensedPlants"],
        targetSettings: ["sortedTargetSettings"] // eslint-disable-line camelcase
    }, data => {
        if(!data.clusters) return [];

        const plants           = data.plants ?? [];
        const id               = data.selectedPlant?.id ?? window.location.pathname.split("?")[0].split("/").slice(-1);
        const clusters         = data.clusters.filter(x => x.plantId === id);
        const plantElements    = data.plant_elements.filter(x => x.plantId === id && x.isPlantReference);
        const workingShifts    = plants.find(x => x.id === id)?.workingShifts;

        if(!workingShifts) return [];

        const modifiedClusters = clusters.map(x => {
            const targetSetting = data.targetSettings.find(setting => setting.clusterId === x.id) || { targets: [] };

            return {
                id:            targetSetting.id ?? uuid(),
                type:          "cluster",
                plantId:       x.plantId,
                clusterId:     x.id,
                name:          x.name,
                elementsCount: x.plantElements.length,
                createdAt:     x.createdAt,
                updatedAt:     x.updatedAt,
                targets:       workingShifts.map(shift => {
                    const target = targetSetting.targets.find(elem => elem.workingShiftsId === shift.id); // eslint-disable-line max-nested-callbacks

                    return target ? Object.assign({}, target, {
                        name: shift.title
                    }) : ({
                        name:            shift.title,
                        workingShiftsId: shift.id,
                        target:          null
                    });
                })
            };
        });

        const overallTargetSetting = data.targetSettings.find(x => x.plantId === id) || { targets: [] };
        const overall = new Overall({
            plantId:       id,
            plantElements: plantElements,
            allElements:   data.plant_elements.filter(x => x.plantId === id),
            createdAt:     overallTargetSetting.createdAt || null,
            updatedAt:     overallTargetSetting.updatedAt || null,
            shifts:        workingShifts,
            targets:       overallTargetSetting.targets
        });

        return [overall].concat(modifiedClusters);
    });

    queryStrings = computed({
        calendar:         ["calendar"],
        selectedPlant:    ["selectedPlant"],
        selectedClusters: ["selectedClusters"],
        locale:           ["locale"]
    }, data => {
        if(!data.calendar) return { events: "", parts: "", states: "" };

        const overall = data.selectedClusters.find(x => x.name === "element.overall");

        const plantElements = overall ? null : Array.from(new Set(data.selectedClusters
            .map(x => x.plantElements)
            .reduce((dest, val) => dest.concat(val), [])
            .map(x => `"${x.id}"`)));

        const obj = {
            events: {
                startDateTime: [data.calendar.from.getTime() / 1000, data.calendar.until.getTime() / 1000],
                plantId:       data.selectedPlant?.id,
                plantElements
            },
            parts: {
                timestamp: [data.calendar.from.getTime() / 1000, data.calendar.until.getTime() / 1000],
                plantElements
            },
            states: {
                startTime: [data.calendar.from.getTime() / 1000, data.calendar.until.getTime() / 1000],
                plantId:   data.selectedPlant?.id,
                clusterId: data.selectedClusters.map(x => `"${x.id}"`)
            }
        };

        return {
            events: createQueryString(removeEmptyKeys(obj.events)),
            parts:  createQueryString(removeEmptyKeys(obj.parts)),
            states: createQueryString(removeEmptyKeys(obj.states))
        };
    });

    currentWorkingShifts = computed({
        plants: ["licensedPlants"],
        clock:  ["clock"]
    }, data => {
        if(!data.plants) return [];

        const currentDate   = new Date();
        const currentHour   = currentDate.getHours();
        const currentMinute = Math.floor(currentDate.getMinutes() / 15) * 15;
        const plantsShiftPlan = data.plants.filter(x => x).reduce((dest, val) => Object.assign({}, dest, {
            [val.id]: WorkingShiftsADay.of({
                shifts: val.workingShifts.map(x => Object.assign({}, x, {
                    startTime: new Date(x.startTime),
                    endTime:   new Date(x.endTime)
                })),
                date: new Date()
            })
        }), {});

        const result = Object.keys(plantsShiftPlan)
            .map(plantId => plantsShiftPlan[plantId][currentHour][currentMinute])
            .filter(x => x);

        const resultHash = md5(result);

        if(resultHash === this.state.get("workingShiftsHash")) return result;


        return result;
    });

    selectablePlantElements = computed({
        plantElements: ["sortedPlantElements"],
        selectedPlant: ["selectedPlant"]
    }, data => { // eslint-disable-line id-length
        if(!data.plantElements) return [];
        if(!data.selectedPlant) return data.plantElements;

        return data.plantElements
            .filter(x => x.plantId === data.selectedPlant.id);
    });

    joinCausesCategories = computed({
        causes:     ["cause_reports"],
        categories: ["cause_categories"]
    }, data => {
        const join = cause => {
            if(cause?.category?.length === 0) return cause;
            return Object.assign({}, cause, {
                categories: cause.category.map(category => {
                    return data?.categories?.find(x => x?.id === category?.category_id);
                })
            });
        };

        return data.causes?.map(cause => join(cause));
    });

    causesByPlant = computed({
        causes:       ["joinCausesCategories"],
        queryOptions: ["queryOptions"]
    }, data => data.causes?.filter(x => x.plant_id === data.queryOptions.selectedPlant?.id));

    // --- actions ---

    setQueryOptions = action(data => {
        const previous = this.state.get("queryOptions");

        this.state.select("queryOptions").set({
            ...previous,
            ...data
        });

        this.state.commit();
    });

    applyQuery = action((options, keys) => { // eslint-disable-line complexity
        const location   = options.location ?? window.location.pathname;
        const domain     = `/${location.split("/")[1]}${window.location.pathname.includes("/admin") ? `/${location.split("/")[2]}` : ""}`;
        const optionKeys = keys ?? [...new URLSearchParams(window.location.search).keys()];
        const plantId    = isUuid(options.selectedPlant?.id) ? options.selectedPlant?.id : null;
        const content    = {
            clusters: `[${options.clusters.map(x => !x ? `${x}` : `"${x}"`).join(",")}]`,
            ...["widgets"].reduce((dest, x) => options[x] ? ({ ...dest, [x]: options[x].map(option => `"${option}"`) }) : dest, {}),
            ...["shift"].reduce((dest, x) => options[x] ? ({ ...dest, [x]: options[x] === "overall" ? options[x] : `"${options[x]}"` }) : dest, {}),
            ...["startTime"].reduce((dest, x) => options[x] ? ({ ...dest, [x]: options[x] }) : dest, {})
        };

        if(optionKeys.length === 0) return this.trigger("open", `${domain}${plantId ? `/${plantId}` : ""}`);

        const queryString = createQueryString(optionKeys.reduce((dest, x) => ({ ...dest, [x]: content[x] }), {}));

        return this.trigger("open", `${domain}${plantId ? `/${plantId}` : ""}?${queryString}`);
    });

    applyCalendar(calendarElem) {
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            calendar: calendarElem
        };

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);

        return options.selectedPlant;
    }

    applyOption = action((type, value) => {
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            [type]: value
        };

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
    });

    applyPlant = action(plantId => {
        const plant    = this.state.get("licensedPlants").find(x => x.id === plantId);
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            selectedPlant: plant
        };

        this.state.select("selectedPlant").set(plant);
        this.state.select("filter").set(null);
        this.state.commit();

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
    });

    setSortTable = action(elem => {
        const { property, isOrderAsc } = this.state.select("sorting").get();

        this.state.select("sorting").set({
            property:   elem,
            isOrderAsc: elem === property ? !isOrderAsc : isOrderAsc
        });
        this.state.commit();
    });

    setOrdering = action(value => {
        const cursor = this.state.select("ordering");
        const ordering = cursor.get();

        if(ordering.column === value)
            return cursor.set({ ...ordering, order: ordering.order === "asc" ? "desc" : "asc" });

        return cursor.set({ order: "desc", column: value });
    });

    open = action(url => this.history(url));

    setSliderSettings = action(settings => {
        this.state.select("sliderSettings").set(settings);
        this.state.commit();
    });

    resetPlant = action(() => {
        this.state.select("selectedPlant").set(null);
        this.state.select("selectedClusters").set([]);
        this.state.commit();
        this.history(getPath(location.pathname));
    });

    onHistoryPlantSelect = action(async(plant, filter) => {
        if(!plant) return null;

        const calendar   = this.state.get("calendar");
        const fallback   = calendar ?? DatePicker.of("today");
        const options    = await this.trigger("onPlantSelect", plant, filter);
        const shift      = !filter || filter.shift === "overall" || !options.selectedPlant ? null : options?.selectedPlant?.workingShifts.find(x => x.id === filter.shift);
        const additional = {
            ...options,
            shift:     filter?.shift ?? "overall",
            startTime: filter?.startTime ?? [fallback.from.getTime() / 1000, fallback.until.getTime() / 1000]
        };

        this.state.select("selectedShift").set(shift);
        this.state.select("calendar").set(DatePicker.of([new Date(additional.startTime[0] * 1000), new Date(additional.startTime[1] * 1000)]));

        this.trigger("setQueryOptions", additional);
        this.trigger("applyQuery", additional, ["clusters", "shift", "startTime"]);

        if(!options.selectedPlant?.id || !options.clusters.length === 0) return null;

        return this.trigger("fetch", null, options.selectedPlant.id);
    });

    onDashboardPlantSelect = action(async(plant, filter) => {
        if(!plant) return;

        const widgets = {
            isClusterEvents:  filter?.widgets?.includes("events") ?? true,
            isClusterProduct: filter?.widgets?.includes("product") ?? true,
            isClusterSystem:  filter?.widgets?.includes("system") ?? true,
            isClusterTarget:  filter?.widgets?.includes("target") ?? true,
            isShowdiagram:    filter?.widgets?.includes("diagram") ?? true
        };

        this.state.select("dashboardFilters").set(widgets);

        const options    = await this.trigger("onPlantSelect", plant, filter);
        const additional = {
            ...options,
            widgets: Object.keys(widgets).reduce((dest, elem) => widgets[elem] ? dest.concat(dashboardFilter[elem]) : dest, [])
        };

        this.trigger("setQueryOptions", additional);
        this.trigger("applyQuery", { ...additional, location: "/dashboard" }, ["clusters", "widgets"]);
    });

    onSettingsPlantSelect = action(plant => {
        if(!plant) return;

        const finalPlant = plant && plant.id ? plant : this.state.get("licensedPlants").find(x => x.id === plant);
        const options = this.state.get("queryOptions");

        const additional = {
            ...options,
            selectedPlant: finalPlant
        };

        this.trigger("setQueryOptions", additional);
        this.state.select("selectedPlant").set(finalPlant);
        this.state.select("selectedClusters").set([]);
        this.state.commit();

        this.history(`${getPath(window.location.pathname)}/${finalPlant ? finalPlant.id : ""}`);
    });

    onPlantSelect = action((plant, filter) => { // eslint-disable-line max-statements, complexity
        const finalPlant = plant && plant.id ? plant : this.state.get("licensedPlants").find(x => x.id === plant);
        const previous   = this.state.get("queryOptions");
        const elements   = this.state.get("plant_elements").filter(x => x.plantId === finalPlant.id && x.isPlantReference);
        const setting    = this.state.get("target_settings").find(x => x.plantId === finalPlant.id) || { targets: [] };

        this.state.select("selectedPlant").set(finalPlant);

        const hasOverallPlant = this.state.get("plant_elements").filter(x => x.plantId === finalPlant.id && x.isPlantReference).length > 0;
        const cluster = !hasOverallPlant ? [] : [new Overall({
            plantId:       finalPlant.id,
            plantElements: elements,
            allElements:   this.state.get("plant_elements").filter(x => x.plantId === finalPlant.id) || [],
            createdAt:     setting.createdAt || null,
            updatedAt:     setting.updatedAt || null,
            shifts:        finalPlant.workingShifts,
            targets:       setting.targets
        })];

        const defaultClusters = hasOverallPlant || filter?.clusters?.includes(null) ? cluster : [];

        const clusters = this.state.get("joinedClusters");
        const selectedClusters = clusters.filter(x => filter?.clusters?.includes(x.id));

        this.state.select("selectedClusters").set(defaultClusters.concat(selectedClusters));

        return {
            ...previous,
            selectedPlant: finalPlant,
            clusters:      defaultClusters.concat(selectedClusters).map(x => x.id)
        };
    });

    setDashboardFilter = action((name, value) => {
        const selectedWidgets = this.state.get("dashboardFilters");
        const filters = { ...selectedWidgets, [name]: value };
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            widgets: Object.keys(filters).filter(x => x.startsWith("is") && filters[x]).map(x => dashboardFilter[x])
        };

        this.state.select("dashboardFilters", name).set(value);
        this.state.select("dashboardFilters", "id").set(null);

        this.state.commit();

        this.trigger("setQueryOptions", options);
        return this.trigger("applyQuery", options);
    });

    setDashboard = action(dashboard => {
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            widgets: Object.keys(dashboard).filter(x => x.startsWith("is") && dashboard[x]).map(x => dashboardFilter[x])
        };

        this.state.select("dashboardFilters").set(dashboard);

        this.state.commit();

        this.trigger("setQueryOptions", options);
        return this.trigger("applyQuery", options);
    });

    setPlant = action(plant => {
        const finalPlant = plant && plant.id ? plant : this.state.get("licensedPlants").find(x => x.id === plant);

        this.state.select("selectedPlant").set(finalPlant);
        this.state.commit();
    });

    fullWidget = action(act => {
        this.state.select("fullScreenWidget").set(act);
        this.state.commit();
    });

    createEvent = action(event => {
        delete event.id;

        const result = {
            ...event,
            createdAt: dateToString(new Date())
        };

        this.state.push(["events"], result);
        this.state.commit();
    });

    onSidebarToggle = action(value => {
        this.state.select("isSidebarOpen").set(value !== null ? value : !this.state.get("isSidebarOpen"));
        this.state.commit();
    });

    resetLang = action(() => {
        this.state.select("language").set(this.state.get("user").language || "de");
        this.state.commit();
    });

    deleteCluster = action(id => {
        const idx = this.state
            .get("clusters")
            .findIndex(x => x.id === id);

        this.state.unset(["clusters", idx]);
        this.state.commit();
    });

    createCluster = action(cluster => {
        delete cluster.id;

        const result = {
            ...cluster,
            createdAt: new Date().toISOString()
        };

        this.state.push(["clusters"], result);
        this.state.commit();
    });

    editCluster = action(cluster => {
        const currentIdx = this.state
            .get("clusters")
            .findIndex(x => x.id === cluster.id);

        const result = {
            ...cluster,
            updatedAt: new Date().toISOString()
        };

        this.state.set(["clusters", currentIdx], result);
        this.state.commit();

        return this.history(`/admin/cluster/${cluster.plantId}`);
    });

    createDashboard = action(dashboard => {
        delete dashboard.id;

        const result = {
            ...dashboard,
            createdAt: new Date().toISOString()
        };

        this.state.push(["dashboards"], result);
        this.state.commit();

        return this.history("/admin/dashboard");
    });

    editDashboard = action(dashboard => {
        const currentIdx = this.state
            .get("dashboards")
            .findIndex(x => x.id === dashboard.id);

        const result = {
            ...dashboard,
            updatedAt: new Date().toISOString()
        };

        this.state.set(["dashboards", currentIdx], result);
        this.state.commit();

        return this.history("/admin/dashboard");
    });

    deleteDashboard = action(id => {
        const idx = this.state
            .get("dashboards")
            .findIndex(x => x.id === id);

        this.state.unset(["dashboards", idx]);
        this.state.commit();
    });

    deletePlantEle = action(id => {
        const idx = this.state
            .get("plant_elements")
            .findIndex(x => x.id === id);

        this.state.unset(["plant_elements", idx]);
        this.state.commit();
    });

    addSelectedCluster = action(cluster => {
        const selectedClusters = this.state.get("selectedClusters");
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            clusters: selectedClusters.concat([cluster]).map(x => x.id)
        };

        this.state.select("selectedClusters").set(selectedClusters.concat([cluster]));
        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
    });

    removeSelectedCluster = action(cluster => {
        const selectedClusters = this.state.get("selectedClusters");
        const changedClusters = selectedClusters.filter(x => x.id !== cluster.id);
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            clusters: changedClusters.map(x => x.id)
        };

        this.state.select("selectedClusters").set(changedClusters);
        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);
    });

    resetSelectedClusters = action((clusters = []) => {
        this.state.select("selectedClusters").set(clusters);
        this.state.commit();
    });

    reset = action(async() => {
        const previous = this.state.get("queryOptions");
        const today    = DatePicker.of("today");

        if(!previous.selectedPlant?.id) return;

        const options  = await this.trigger("onPlantSelect", previous.selectedPlant?.id, {
            ...previous,
            clusters: []
        });
        const plant = window.location.pathname.includes("/overview") ? null : options.selectedPlant;

        const additional  = {
            ...options,
            widgets:       ["events", "product", "system", "target", "diagram"],
            startTime:     [today.from.getTime() / 1000, today.until.getTime() / 1000],
            shift:         null,
            selectedPlant: plant
        };

        this.state.select("queryOptions").set(additional);
        this.state.select("dashboardFilters").set({
            isClusterEvents:  true,
            isClusterProduct: true,
            isClusterSystem:  true,
            isClusterTarget:  true,
            isShowdiagram:    true
        });
        this.state.select("selectedShift").set(null);
        this.state.select("calendar").set(today);
        this.state.select("selectedPlant").set(plant);

        this.trigger("setQueryOptions", additional);
    });

    createSetting = action(setting => {
        delete setting.id;

        const result = {
            ...setting,
            createdAt: new Date().toISOString()
        };

        this.state.push(["target_settings"], result);
        this.state.commit();

        return this.history(`/admin/settings/${this.state.get("selectedPlant").id}`);
    });

    editSetting = action(setting => {
        const currentIdx = this.state
            .get("target_settings")
            .findIndex(x => x.id === setting.id);

        const result = {
            ...setting,
            updatedAt: new Date().toISOString()
        };

        this.state.set(["target_settings", currentIdx], result);
        this.state.commit();

        return this.history(`/admin/settings/${this.state.get("selectedPlant").id}`);
    });

    fetchOverview = action(async({ plantId }) => {
        const plants = this.state.get("overview");

        const shift = plants?.find(x => x.id === plantId)?.shift;
        const to    = fromFakeUTC(new Date());
        const from  = !shift ? new Date(to).setHours(to.getHours() - 8, to.getMinutes(), to.getSeconds(), to.getMilliseconds()) : shift.realUTCStartTime.getTime();
        const url   = `${window.config.backendUrl}/overview?plantId=${plantId}&timestamp=[${from / 1000},${to.getTime() / 1000}]`;

        try {
            const result = await this.http("GET", url).then(x => x.json());

            const overviewItems = this.state.get("plantOverview");

            this.state.select("plantOverview").set(overviewItems.filter(x => x.plantId !== plantId).concat([result]));
        } catch(error) {
            this.log.error(error);
            return;
        }
    });

    onCalendarSelect = action((calendarElem, plantId, clusterId) => {
        const plant    = this.state.get("selectedPlant");
        const clusters = this.state.get("selectedClusters");

        this.state.select("calendar").set(calendarElem);
        this.state.commit();

        const isInvalid = (
            window.location.href.indexOf("/history") === -1 ||
            (!plant && !plantId) ||
            (!clusterId && clusters.length === 0)
        );

        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            startTime: [calendarElem.from.getTime() / 1000, calendarElem.until.getTime() / 1000]
        };

        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);

        if(isInvalid) return null;

        return this.trigger("fetch", clusterId, plantId);
    });

    fetch = action(async(clusterId, id) => {
        const plant    = this.state.get("selectedPlant");
        const clusters = this.state.get("selectedClusters");
        const plantId  = plant?.id ?? id;

        if(!plantId || (!clusterId && clusters.length === 0)) return this.log.error("error to fetch");

        return await Promise.all([
            this.trigger("fetchEvents", clusterId, plantId),
            this.fetchProducts(clusterId, plantId),
            this.fetchStates(clusterId, plantId),
            this.trigger("fetchMonitor", clusterId, plantId, window.location.href.indexOf("/cluster") !== -1)
        ]);
    });


    onWorkingShiftSelect = action((shift, plantId, clusterId) => {
        const plant    = this.state.get("selectedPlant");
        const clusters = this.state.get("selectedClusters");
        const previous = this.state.get("queryOptions");
        const options  = {
            ...previous,
            shift: shift?.id ?? "overall"
        };

        this.state.select("selectedShift").set(shift);
        this.trigger("setQueryOptions", options);
        this.trigger("applyQuery", options);

        if(window.location.href.indexOf("/history") === -1 || (!plantId && !plant) || (!clusterId && clusters.length === 0)) return null;

        return this.trigger("fetch", clusterId, plantId);
    });

    changeLang = action(value => {
        const language = value ? value : this.state.get("locale");

        this.state.select("language").set(language);
        this.state.commit();
    });

    fetchDashboard = action(async() => {
        const plant = this.state.get("selectedPlant");

        if(!plant) return null;

        return await Promise.all([
            this.trigger("fetchEvents", null, plant?.id, ["dataOverview", "events"]),
            this.fetchAggregatedParts(plant?.id),
            this.fetchAggregatedStates(plant?.id)
        ]);
    });

    setClock = action(value => {
        this.state.select("clock").set(value);
        this.state.commit();
    });

    calcCurrentWorkingShift = action(() => { // eslint-disable-line id-length
        const plants = this.state.get("licensedPlants");

        if(!plants) {
            this.state.select("currentWorkingShifts", []);
            return;
        }

        const result     = plants.map(plant => getWorkingShift(plant)).filter(x => x);
        const resultHash = md5(result);

        if(resultHash === this.state.get("workingShiftsHash")) return;

        this.state.select("currentWorkingShifts").set(result);
        this.state.select("workingShiftsHash").set(resultHash);
        this.state.commit();
    });

    fetchMonitor = action(async(clusterId, plantId, isDetail = false) => {
        this.setCounter("monitor");

        const selected = !clusterId ? this.state.get("selectedClusters") : [{
            type: "cluster",
            id:   clusterId
        }];
        const calendar      = this.state.get("calendar");
        const locale        = this.state.get("locale");
        const time          = getShiftTime(this.state.get("selectedShift"));
        const clusterFilter = `&clusterId=[${selected.map(elem => elem.type === "element" ? "null" : `"${elem.id}"`).join(",")}]`;
        const response      = await this.http("GET", `${window.config.backendUrl}/monitor?isDetail=${isDetail ? "true" : "false"}&locale=${locale}&plantId="${plantId}"&timestamp=[${calendar.from.getTime() / 1000},${calendar.until.getTime() / 1000}]${clusterFilter}${time ? `&time=["${time[0]}","${time[1]}"]` : ""}`).then(x => x.json());

        const cursor  = this.state.select(["dataHistory"]);
        const history = cursor.get();

        cursor.set({
            ...history,
            monitor: response
        });

        this.state.commit();

        this.setCounter("monitor", -1);
    });

    fetchEvents = action(async(clusterId, plantId, target = "dataHistory") => {
        this.setCounter("global");
        const selected = !clusterId ? this.state.get("selectedClusters") : [{
            type: "cluster",
            id:   clusterId
        }];

        const calendar = this.state.get("calendar");
        const shift    = this.state.get("selectedShift");
        const timestamp = window.location.href.indexOf("/history") > -1 ?
            [calendar.from.getTime() / 1000, calendar.until.getTime() / 1000] :
            this.getTimeRange(plantId);

        const time   = getShiftTime(shift);
        const events = await Promise.all(selected.map(() => {
            return this.http("GET", `${window.config.backendUrl}/events?plantId="${plantId}"&startDateTime=[${timestamp[0]},${timestamp[1]}]${time ? `&time=["${time[0]}","${time[1]}"]` : ""}`).then(x => x.json());
        }));

        const cursor  = this.state.select(target);
        const history = cursor.get();
        const data    = selected.reduce((dest, elem, idx) => ({
            ...dest,
            [elem.id]: {
                ...(dest[elem.id] ?? {}),
                events: events[idx]
            }
        }), history);

        cursor.set(data);

        this.state.commit();

        this.setCounter("global", -1);
    });

    // --- helper ---

    setCounter(type, summand = 1) {
        const cursor  = this.state.select("counter", type);
        const current = cursor.get();

        cursor.set(min(current + summand, 0));

        this.state.commit();
    }

    async fetchAggregatedParts(plantId) {
        this.setCounter("global");

        const selected  = this.state.get("selectedClusters");
        const timestamp = this.getTimeRange(plantId);
        const parts     = await Promise.all(selected.map(cluster => {
            const clusterFilter = cluster.type === "element" ? "" : `&clusterId="${cluster.id}"`;

            return this.http("GET", `${window.config.backendUrl}/parts?plantId="${plantId}"${clusterFilter}&timestamp=[${timestamp[0]},${timestamp[1]}]`).then(x => x.json());
        }));

        const cursor = this.state.select("dataOverview", "parts");
        const history = cursor.get();
        const data    = selected.reduce((dest, elem, idx) => ({
            ...dest,
            [elem.id]: parts[idx]
        }), history);

        cursor.set(data);

        this.state.commit();

        this.setCounter("global", -1);
    }

    async fetchAggregatedStates(plantId) {
        this.setCounter("global");

        const selected  = this.state.get("selectedClusters");
        const timestamp = this.getTimeRange(plantId);
        const states    = await Promise.all(selected.map(cluster => {
            const clusterFilter = cluster.type === "element" ? "" : `&clusterId="${cluster.id}"`;

            return this.http("GET", `${window.config.backendUrl}/aggregatedStates?plantId="${plantId}"${clusterFilter}&timestamp=[${timestamp[0]},${timestamp[1]}]`).then(x => x.json());
        }));

        const cursor = this.state.select("dataOverview", "states");
        const history = cursor.get();
        const data    = selected.reduce((dest, elem, idx) => ({
            ...dest,
            [elem.id]: states[idx]
        }), history);

        cursor.set(data);

        this.state.commit();

        this.setCounter("global", -1);
    }

    async fetchStates(clusterId, plantId, target = "dataHistory") {
        this.setCounter("global");

        const selected = !clusterId ? this.state.get("selectedClusters") : [{
            type: "cluster",
            id:   clusterId
        }];
        const calendar  = this.state.get("calendar");
        const shift     = this.state.get("selectedShift");
        const timestamp = window.location.href.indexOf("/history") > -1 ?
            [calendar.from.getTime() / 1000, calendar.until.getTime() / 1000] :
            this.getTimeRange(plantId);

        const time   = getShiftTime(shift);
        const states = await Promise.all(selected.map(cluster => {
            const clusterFilter = cluster.type === "element" ? "" : `&clusterId="${cluster.id}"`;

            return this.http("GET", `${window.config.backendUrl}/states?plantId="${plantId}"${clusterFilter}&timestamp=[${timestamp[0]},${timestamp[1]}]${time ? `&time=["${time[0]}","${time[1]}"]` : ""}`).then(x => x.json());
        }));

        const cursor  = this.state.select(target);
        const history = cursor.get();
        const data    = selected.reduce((dest, elem, idx) => ({
            ...dest,
            [elem.id]: {
                ...(dest[elem.id] ?? {}),
                states: states[idx]
            }
        }), history);

        cursor.set(data);

        this.state.commit();

        this.setCounter("global", -1);
    }

    getTimeRange(plantId) {
        const currentWorkingShifts = this.state.get("currentWorkingShifts");
        const activeShift          = currentWorkingShifts.find(x => x.plants_id === plantId);

        if(activeShift) return [activeShift.realUTCStartTime.getTime() / 1000, activeShift.realUTCEndTime.getTime() / 1000];

        const fakeNow = fromFakeUTC(Date.now());

        return [parseInt(fakeNow.getTime() / 1000, 10) - 8 * 60 * 60, parseInt(fakeNow.getTime() / 1000, 10)];
    }

    async fetchProducts(clusterId, plantId, target = "dataHistory") {
        this.setCounter("global");

        const selected = !clusterId ? this.state.get("selectedClusters") : [{
            type: "cluster",
            id:   clusterId
        }];
        const calendar  = this.state.get("calendar");
        const shift     = this.state.get("selectedShift");
        const timestamp = window.location.href.indexOf("/history") > -1 ?
            [calendar.from.getTime() / 1000, calendar.until.getTime() / 1000] :
            this.getTimeRange(plantId);

        const time     = getShiftTime(shift);
        const products = await Promise.all(selected.map(cluster => {
            const clusterFilter = cluster.type === "element" ? "" : `&clusterId="${cluster.id}"`;

            return this.http("GET", `${window.config.backendUrl}/products?onlySum=${cluster.config?.producedProductTypes ? "false" : "true"}&plantId="${plantId}"${clusterFilter}&timestamp=[${timestamp[0]},${timestamp[1]}]${time ? `&time=["${time[0]}","${time[1]}"]` : ""}`).then(x => x.json());
        }));

        const cursor  = this.state.select(target);
        const history = cursor.get();
        const data    = selected.reduce((dest, elem, idx) => ({
            ...dest,
            [elem.id]: {
                ...(dest[elem.id] ?? {}),
                products: products[idx]
            }
        }), history);

        cursor.set(data);

        this.state.commit();

        this.setCounter("global", -1);
    }
}

export default App;
