import { useBranch, useTranslation } from "@circle/gestalt-app";
import html2canvas from "html2canvas";
import { DropDownButton, Icon } from "@circle/kip-components";

const Export = () => {
    const { translate }    = useTranslation();
    const { queryStrings } = useBranch({
        queryStrings: ["queryStrings"]
    });
    const onExport = () => {
        setTimeout(() => html2canvas(document.querySelector("#page-content")).then(canvas => {
            const a = document.createElement("a");

            a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
            a.download = "Export-FullPage.jpg";

            a.click();
        }), 250);
    };

    const onClick = x => {
        if(x === "image") return onExport();

        const link = `${window.config.backendUrl}/${x}/download/csv?${queryStrings[Export.keys[x]]}`;

        window.open(link, "_blank").focus();

        return null;
    };

    const pageExport = [{
        value: "image",
        label: <><Icon className="mr-2" _icon="ExportPage"/>{ translate("image.overall.page") }</>
    }];
    const options = pageExport.concat(!queryStrings ? [] : [{
        value: "states",
        label: <><Icon className="mr-2" _icon="List"/>{ translate("csv.states") }</>
    }, {
        value: "produced_parts",
        label: <><Icon className="mr-2" _icon="List"/>{ translate("csv.produced.parts") }</>
    }, {
        value: "events",
        label: <><Icon className="mr-2" _icon="List"/>{ translate("csv.event.log") }</>
    }]);

    return (
        <div className="export-block flex-row-reverse relative flex">
            <DropDownButton
                className="export-dropdown"
                buttonProps={{
                    _variant: "icon"
                }}
                options={options}
                onClick={onClick}
                closeOnOptionClick
            >
                <Icon _icon="Export"/>
            </DropDownButton>
        </div>
    );
};

Export.keys = {
    states:         "states",
    produced_parts: "parts", // eslint-disable-line
    events:         "events"
};

export { Export };
