interface Day {
    day: number
}

interface Shift {
    days: Day[]
    startTime: Date
    endTime: Date
}

interface WorkingShift {
    shifts: Shift[]
    date: Date
}

const isBetween = (start: Date, end: Date, hour: number, minute: number) => { // eslint-disable-line complexity
    const hourStart   = start.getUTCHours();
    const hourEnd     = end.getUTCHours();
    const minuteStart = start.getUTCMinutes();
    const minuteEnd   = end.getUTCMinutes();

    return (hourStart < hour || hourStart === hour && minuteStart <= minute) && (hourEnd > hour || hourEnd === hour && minuteEnd > minute);
};

const getDuration = (start: Date, end: Date) => {
    if(start.getUTCHours() <= end.getUTCHours()) return (end.getUTCHours() - start.getUTCHours() + ((end.getUTCMinutes() - start.getUTCMinutes()) / 60));

    return 24 - start.getUTCHours() + end.getUTCHours() + ((end.getUTCMinutes() - start.getUTCMinutes()) / 60);
};

export class WorkingShiftsADay {
    constructor({ shifts, date }: WorkingShift) {
        const day = date.getDay();

        const shiftsStartingToday = shifts
            .filter(shift => shift.days.map(x => x.day).includes(day))
            .map(x => Object.assign({}, x, {
                todayStartTime:   x.startTime,
                todayEndTime:     x.endTime.getUTCHours() < x.startTime.getUTCHours() ? new Date(new Date().setUTCHours(23, 59, 59, 999)) : x.endTime,
                duration:         getDuration(x.startTime, x.endTime),
                realStartTime:    new Date(new Date(new Date(new Date().setHours(x.startTime.getUTCHours(), x.startTime.getUTCMinutes())).setUTCSeconds(0)).setUTCMilliseconds(0)),
                realEndTime:      new Date(new Date(new Date(new Date(new Date().setDate(date.getDate() + (x.endTime.getUTCHours() < x.startTime.getUTCHours() ? 1 : 0))).setHours(x.endTime.getUTCHours(), x.endTime.getUTCMinutes())).setUTCSeconds(0)).setUTCMilliseconds(0)),
                realUTCStartTime: new Date(new Date(new Date(new Date(new Date().setUTCDate(date.getDate())).setUTCHours(x.startTime.getUTCHours(), x.startTime.getUTCMinutes(), 0, 0)).setUTCSeconds(0)).setUTCMilliseconds(0)),
                realUTCEndTime:   new Date(new Date(new Date(new Date(new Date().setUTCDate(date.getDate() + (x.endTime.getUTCHours() < x.startTime.getUTCHours() ? 1 : 0))).setUTCHours(x.endTime.getUTCHours(), x.endTime.getUTCMinutes(), 0, 0)).setUTCSeconds(0)).setUTCMilliseconds(0))
            }));

        const shiftsStartedYesterday = shifts // eslint-disable-line id-length
            .filter(shift => shift.days.map(x => x.day).includes(day === 1 ? 7 : day - 1) && shift.startTime.getUTCHours() > shift.endTime.getUTCHours())
            .map(x => Object.assign({}, x, {
                todayStartTime:   new Date(new Date().setUTCHours(0, 0, 0, 0)),
                todayEndTime:     x.endTime,
                duration:         getDuration(x.startTime, x.endTime),
                realStartTime:    new Date(new Date(new Date(new Date(new Date().setDate(date.getDate() - 1)).setHours(x.startTime.getUTCHours(), x.startTime.getUTCMinutes())).setUTCSeconds(0)).setUTCMilliseconds(0)),
                realEndTime:      new Date(new Date(new Date(new Date().setHours(x.endTime.getUTCHours(), x.endTime.getUTCMinutes())).setUTCSeconds(0)).setUTCMilliseconds(0)),
                realUTCStartTime: new Date(new Date(new Date(new Date(new Date().setUTCDate(date.getDate() - 1)).setUTCHours(x.startTime.getUTCHours(), x.startTime.getUTCMinutes(), 0, 0)).setUTCSeconds(0)).setUTCMilliseconds(0)),
                realUTCEndTime:   new Date(new Date(new Date(new Date(new Date().setUTCDate(date.getDate())).setUTCHours(x.endTime.getUTCHours(), x.endTime.getUTCMinutes(), 0, 0)).setUTCSeconds(0)).setUTCMilliseconds(0))
            }));

        const allShiftsOfToday = shiftsStartingToday.concat(shiftsStartedYesterday);

        const result = new Array(24).fill(null).reduce((dest, x, hour) => Object.assign({}, dest, {
            [hour]: {
                0:  allShiftsOfToday.find(shift => isBetween(shift.todayStartTime, shift.todayEndTime, hour, 0)),
                15: allShiftsOfToday.find(shift => isBetween(shift.todayStartTime, shift.todayEndTime, hour, 15)),
                30: allShiftsOfToday.find(shift => isBetween(shift.todayStartTime, shift.todayEndTime, hour, 30)),
                45: allShiftsOfToday.find(shift => isBetween(shift.todayStartTime, shift.todayEndTime, hour, 45))
            }
        }), {});

        Object.assign(this, result);
    }

    static of(opts: WorkingShift) {
        return new WorkingShiftsADay(opts);
    }
}
