import PropTypes from "prop-types";

const CustomTooltip = ({ active, label, payload }) => (
    <>
        {
            active &&
            <div className="custom-tooltip">
                <p className="tooltip-label">{`${label}`}</p>
                {
                    payload.map((ele, key) => (
                        <p key={key} className="tooltip-block">
                            <span className="tooltip-icon"> {ele.name}: </span>
                            {ele ? ele.value : ""}
                        </p>
                    ))
                }
            </div>
        }
    </>
);

CustomTooltip.propTypes = {
    active:  PropTypes.bool,
    payload: PropTypes.arrayOf(PropTypes.object),
    label:   PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export { CustomTooltip };
