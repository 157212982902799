import { NavLink } from "react-router-dom";
import { hasPermission, useBranch, useTranslation } from "@circle/gestalt-app";
import settings from "../../enums/settings";

const SettingsNavbar = () => {
    const { translate } = useTranslation();
    const { selectedPlant, user } = useBranch({
        selectedPlant: ["selectedPlant"],
        user:          ["user"]
    });
    const links = !selectedPlant ? SettingsNavbar.links : SettingsNavbar.links.map(x => ({
        ...x,
        path: x.path.concat(`/${ selectedPlant.id}`)
    }));

    return (
        <div className="flex-grow">
            <div className="nav flex">
                {
                    links.map((link, i) => (link.permission && hasPermission(user, link.permission) || !link.permission) && (
                        <NavLink to={link.path} className={({ isActive }) => `nav-ele center clickable relative ${i !== 0 ? "ml-4" : ""}${isActive ? " nav-active" : ""}`} key={i} style={{ textDecoration: "none" }} rel="noopener noreferrer">
                            <div className="flex relative">
                                <div className="flex">
                                    {translate(settings[i])}
                                </div>
                            </div>
                        </NavLink>
                    ))
                }
            </div>
        </div>
    );
};

SettingsNavbar.links = [{
    path:       "./elements",
    permission: null
}, {
    path:       "./cluster",
    permission: "PRODUCTIVITY_MONITOR_ADMIN"
}, {
    path:       "./settings",
    permission: null
}, {
    path:       "./dashboard",
    permission: "PRODUCTIVITY_MONITOR_ADMIN"
}];

export { SettingsNavbar };
