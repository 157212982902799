interface IProductItem {
    product: string
    amount: string
    time: string
}

interface WidgetData {
    products: IProductItem[]
}

export class ProductItem implements IProductItem {
    constructor(product: string, amount: string, time: string) {
        this.product = product;
        this.amount = amount;
        this.time = time;
    }
    product: string;
    amount: string;
    time: string;
}

export class ProductsWidgetData implements WidgetData {
    constructor(products: ProductItem[]) {
        if(!Array.isArray(products) || products.length > 0 && !(products[0]as any instanceof ProductItem)) // eslint-disable-line @typescript-eslint/no-explicit-any
            throw new Error("Wrong format creating ProductsWidgetData");

        this.products = products;
    }
    products: ProductItem[];
}
