import { useBranch } from "@circle/gestalt-app";
import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";
import { History } from "./History";
import { DatePicker } from "../../types/DatePicker";

const HistoryWrapper = () => {
    const { plants, calendar } = useBranch({
        plants:   ["plants"],
        calendar: ["calendar"]
    });

    const fallback = calendar ?? DatePicker.of("today");

    const [filter] = useQueryString({
        plants,
        optional: {
            shift:     x => x?.replaceAll("\"", "") ?? "overall",
            startTime: x => JSON.parse(x ?? JSON.stringify([fallback.from.getTime() / 1000, fallback.until.getTime() / 1000]))
        }
    });

    const [init] = useQuery({
        keys:    ["clusters", "shift", "startTime"],
        options: filter
    });

    return (
        <>
            {
                init &&
                <History
                    filter={filter}
                />
            }
        </>
    );
};

export { HistoryWrapper };
