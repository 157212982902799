import types from "../enums/fileTypes";
import md5 from "md5";

interface Data {
    [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface Tag {
    tag: string,
    value: string
}

interface Language {
    value: string
}

interface Translation {
    language: string,
    content: string
}

interface Stuff {
    url: string,
    id: number | string,
    plants: Array<Data>,
    parentId: number | string | null,
    name: Array<Translation>,
    createdAt: Date,
    updatedAt: Date | null,
    version: string | null,
    languages: Array<Language>,
    permissions: Array<Data>,
    tags: Array<Tag>,
    _files: Array<Stuff>,
    path: Array<Data>,
    vpath: Array<Data>
}

interface IFile extends Stuff {
    subitems: Array<IFile>,
    getByPath: (x: string[]) => Array<IFile> | IFile | null
}

interface FileData {
    url: string,
    id: number | string,
    plants: Array<Data>,
    parentId: number | string,
    name: Array<Translation>,
    createdAt: string,
    updatedAt: string,
    version: string,
    languages: Array<Language>,
    permissions: Array<Data>,
    tags: Array<Tag>,
    files: Array<IFile>,
    path: Array<Data>,
    vpath: Array<Data>
}

export class File implements IFile {
    constructor(data: FileData) { // eslint-disable-line complexity
        this.url         = data.url || "";
        this.id          = data.id;
        this.plants      = data.plants || [];
        this.parentId    = data.parentId || null;
        this.name        = data.name || [];
        this.createdAt   = data.createdAt ? new Date(data.createdAt) : new Date();
        this.updatedAt   = data.updatedAt ? new Date(data.updatedAt) : null;
        this.version     = data.version || null;
        this.languages   = data.languages || [];
        this.permissions = data.permissions || [];
        this.tags        = data.tags || [];
        this._files      = data.files || [];
        this.path        = data.path || [];
        this.vpath       = data.vpath || [];
    }
    url: string;
    id: string | number;
    plants: Data[];
    parentId: string | number | null;
    name: Translation[];
    createdAt: Date;
    updatedAt: Date | null;
    version: string | null;
    languages: Language[];
    permissions: Data[];
    tags: Tag[];
    _files: IFile[];
    path: Data[];
    vpath: Data[];

    get files(): Array<IFile> {
        return this._files;
    }

    get isFile() {
        return this.type !== "folder";
    }

    get type() {
        if(!this.appendix) return "folder";

        const value = types as Data;

        return (value[this.appendix] || "folder");
    }

    get appendix() {
        const fileParts = this.url.split(".");

        return fileParts.length <= 1 ? null : fileParts.slice(-1)[0].toLowerCase();
    }

    get isViewable() {
        return Boolean(this.isFile && this.id);
    }

    get initialName() {
        const fullName = this.url.split("/").slice(-1)[0];

        return fullName.split(".").slice(0, -1).join(".");
    }

    clone() {
        return structuredClone(this);
    }

    cloneWith(data: FileData) {
        return new File(Object.assign({}, this, data));
    }

    set(type: keyof Stuff, value: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        this[type]     = value;
        this.updatedAt = this.id ? new Date() : null;

        return this;
    }

    get(key: keyof IFile, language: string) {
        if(this[key] instanceof Date)     return this[key]?.toLocaleString();
        if(!(this[key] instanceof Array)) return this[key];

        const value = (this[key] ?? []) as Array<Translation>;

        const element = value.find(elem => elem.language === language) ||
            value.find(elem => elem.language === "en") ||
            value[0];

        return (element || {}).content;
    }

    get isValid() {
        return this.tags?.reduce((dest, val) => dest && Boolean(val?.tag) && Boolean(val?.value), true) &&
            this.languages.length > 0 &&
            this.languages?.reduce((dest, val) => {
                const elem = this.name.find(x => x.language === val.value);

                return dest && (elem?.content ?? "").split(".")[0].length > 0;
            }, true);
    }

    get hasRequiredValues() {
        if(!this.isFile) return this.name.length > 0;

        return this.name.length > 0 && this.url.length > 0;
    }

    get hash() {
        return md5(JSON.stringify({
            url:         this.url,
            id:          this.id,
            plants:      this.plants,
            parentId:    this.parentId,
            name:        this.name,
            version:     this.version,
            languages:   this.languages,
            permissions: this.permissions,
            tags:        this.tags
        }));
    }

    strip() {
        return Object.keys(this)
            .filter(key => this[key as keyof IFile])
            .reduce((dest, key) => ({
                ...dest,
                [key]: this[key as keyof IFile]
            }), {});
    }

    get encoded() {
        return this.id;
    }

    get subitems(): Array<IFile> {
        return this.files.reduce((dest, elem) => dest.concat(elem.subitems), this.files);
    }

    getByPath(path: string[] = []): Array<IFile> | IFile | null {
        const [fileName, ...subpath] = path;

        if(subpath.length === 0 && this.id === fileName) return (this as IFile);
        if(subpath.length === 0 || this.id !== fileName) return null;

        return this.files.reduce((dest, elem) => dest ?? elem.getByPath(subpath));
    }
}
