export const getColor = (type: string) => {
    if(type === "used")             return "ok";
    if(type === "standstill")       return "info";
    if(type === "blocked")          return "warn";
    if(type === "error")            return "error";
    if(type === "maintenance")      return "maintenance";
    if(type === "setup")            return "setup";
    if(type === "notReady")         return "notready";
    if(type === "turnedOff")        return "turnedOff";

    return "neutral";
};

export const getRandomColor = () => {
    return `#${(Math.random() * 0xFFFFFF << 0).toString(16)}`;
};

export const chartColors = {
    ok:          "#7cd992",
    warn:        "#F7E463",
    error:       "#EB6060",
    info:        "#a8a8a8",
    maintenance: "#7da7d9",
    setup:       "#ddebc6",
    notready:    "#e97600",
    neutral:     "#999999",
    orange:      "#db9d57",
    white:       "#fff",
    stroke:      "#4f5358",
    turnedOff:   "#d2d2d2"
};

const hex = [
    "#ffaf96",
    "#afff96",
    "#96afff",
    "a64d79",
    "#f1c232",
    "#52ff52",
    "#526cff",
    "#5252ff",
    "#ff526c",
    "#ff0e0e",
    "#ff260e",
    "#9696ff",
    "#26ff0e",
    "#0eff0e",
    "#0e26ff",
    "#0e0eff",
    "#260eff",
    "#ff0e26",
    "#ff1f1f",
    "#ff6af5",
    "#af96ff",
    "#6eff1f",
    "#1fff1f",
    "#1f6eff",
    "#1f1fff",
    "#6af5ff",
    "#ff1f6e",
    "#6c52ff",
    "#ff2222",
    "#6cff52",
    "#ff37d2",
    "#3bff22",
    "#22ff22",
    "#223bff",
    "#2222ff",
    "#ff96af",
    "#ff5252",
    "#ff6c52",
    "#37d2ff",
    "#ff223b",
    "#ff6363",
    "#ff7d63",
    "#7dff63",
    "#63ff63",
    "#637dff",
    "#6363ff",
    "#7d63ff",
    "#ff637d",
    "#71d1d1",
    "#d171d1",
    "#7171d1",
    "#d17171",
    "#804040",
    "#ffcdbe",
    "#96ff96"
];

export const getChartColor = (idx: number) => {
    return hex[idx];
};
