import { useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import { detectOutsideClick } from "../helper/detector";

const Toggle = (props, ref) => {
    useImperativeHandle(ref, () => ({
        handleClickOutside(event) {
            event.stopPropagation();

            return props.onClose();
        }
    }));

    return (
        <>
            <div className={`${props.className} dropdown w-dropdown`}>
                <div className="dropdown-toggle w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0"/>
                <nav className="dropdown-list w-dropdown-list w--open" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
                    { props.children }
                </nav>
            </div>
        </>
    );
};

Toggle.displayName = "DropdownToggle";
Toggle.propTypes   = {
    onClose:   PropTypes.func,
    className: PropTypes.string,
    children:  PropTypes.node
};

Toggle.defaultProps = {
    onClose:   x => x,
    className: ""
};

const DropdownToggle = detectOutsideClick(forwardRef(Toggle));

export { DropdownToggle };
