import PropTypes from "prop-types";

const RadioButton = props => {
    return (
        <label className={`radio-label ${props.className}`}>
            <input type="radio" className="radio-input" value={props.value} checked={props.checked} onChange={e => props.onChange(e)} name={props.name} />
            <span className="radio-indicator" />
            {props.children}
        </label>
    );
};

RadioButton.propTypes = {
    className: PropTypes.string,
    onChange:  PropTypes.func,
    checked:   PropTypes.bool,
    name:      PropTypes.string,
    value:     PropTypes.string,
    children:  PropTypes.node
};

RadioButton.defaultProps = {
    className: "",
    value:     "",
    checked:   false,
    onChange:  () => {}
};


export { RadioButton };
