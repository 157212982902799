import { useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { RadioButton } from "../generic/RadioButton";
import { useParams } from "react-router-dom";
import { DropDown, Icon, Button } from "@circle/kip-components";

const HistoryFilter = () => {
    const params          = useParams();
    const { trigger }     = useTrigger();
    const { translate }   = useTranslation();
    const [open, setOpen] = useState(false);
    const { selectedPlant, selectedShift } = useBranch({
        selectedPlant: ["selectedPlant"],
        selectedShift: ["selectedShift"]
    });

    const onClick = x => {
        if(x === "overall") return trigger("onWorkingShiftSelect", null, params.plantId, params.clusterId);

        const shift = selectedPlant?.workingShifts.find(y => y.id === x);

        return trigger("onWorkingShiftSelect", shift, params.plantId, params.clusterId);
    };

    const shifts = selectedPlant?.workingShifts.map(x => ({
        value: x.id,
        label: <>
            <RadioButton checked={selectedShift?.id === x.id} className="history-radiobutton">
                {translate(x.title)}
            </RadioButton>
        </>
    }));
    const options = [{
        value: "overall",
        label: <>
            <RadioButton checked={!selectedShift} className="history-radiobutton">
                {translate("workingshift.title.all")}
            </RadioButton>
        </>
    }, ...(shifts ?? [])];

    return (
        <DropDown role="radiogroup" isOpen={open} options={options} onOptionSelect={onClick} onIsOpenChange={() => setOpen(!open)}>
            <Button _variant="icon">
                <Icon _icon={!open ? "Filter" : "FilterSolid"}/>
            </Button>
        </DropDown>
    );
};

export { HistoryFilter };
