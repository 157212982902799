import { useState, useEffect } from "react";
import { OverviewItem } from "./OverviewItem";
import { EventForm } from "../EventForm";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { useNavigate } from "react-router-dom";
import { Modal } from "@circle/kip-components";

const Overview = () => {
    const { trigger }       = useTrigger();
    const { translate }     = useTranslation();
    const [modal, setModal] = useState(false);
    const { items, selectedPlant } = useBranch({
        items:         ["overview"],
        selectedPlant: ["selectedPlant"]
    });

    const navigate = useNavigate();

    const onClick = selectedId => {
        setModal(true);

        trigger("setPlant", selectedId);
    };

    const onClose = () => {
        setModal(false);

        return navigate(".");
    };

    const onSubmit = event => {
        trigger("createEvent", event);

        onClose();
    };

    useEffect(() => {
        trigger("resetPlant");
    }, []);

    return (
        <div className="overview flex-grow">
            <div className="mainview-container">
                {
                    items.map(item => (
                        <OverviewItem
                            key={item.id}
                            item={item}
                            onPlusClick={() => onClick(item.id)}
                            onClick={() => trigger("onDashboardPlantSelect", item.id)}
                        />
                    ))
                }
            </div>
            {
                selectedPlant &&
                <Modal
                    title={translate("title.record.event")}
                    isOpen={modal}
                    isCloseable={false}
                    onClose={() => setModal(false)}
                    className="event-modal"
                >
                    <div id="event-form" className="flex-column">
                        <EventForm
                            isEditable={true}
                            onClose={onClose}
                            selectedPlant={selectedPlant}
                            onSubmit={onSubmit}
                            plantId={selectedPlant.id}
                        />
                    </div>
                </Modal>
            }
        </div>
    );
};

export { Overview };
