import { useEffect } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { States, StatesOverview, HistoryProductsOverview } from "../widgets";
import { EventsOverview } from "../widgets/EventsOverview";
import { Slice } from "../../types/Slice";
import { SliderInput } from "../SliderInput";
import { ProductsGraph } from "./ProductsGraph";
import { Button, Icon } from "@circle/kip-components";

const HistoryCluster = props => { // eslint-disable-line complexity,max-statements
    const params        = useParams();
    const navigate      = useNavigate();
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { history, sliderSettings, selected } = useBranch({
        history:        ["dataHistory"],
        sliderSettings: ["sliderSettings"],
        selected:       ["selectedPlant"]
    });

    useEffect(() => {
        if(!selected?.id) return;

        trigger("fetch", params.clusterId, params.plantId);
    }, []);

    const onEyeClick = () => {
        trigger("resetSelectedClusters");
        trigger("addSelectedCluster", props.cluster);

        navigate(`./cluster/${props.cluster.id ? props.cluster.id : "all"}`);

        trigger("fetchMonitor", params.clusterId, params.plantId, true);
    };

    const showStatesTile = !props.cluster.name.includes("overall") &&
        ((props.cluster.isStatesSumup === true && props.cluster?.plantElements?.length > 1) || props.cluster?.plantElements?.length === 1);

    const dataSet     = history[props.cluster.id];
    const products    = (dataSet?.products ?? []).filter(x => x.processed > 0);
    const chartStates = {
        data: dataSet?.states?.filter(x => x.state !== "total").map(x => new Slice(x)) ?? []
    };
    const statesOverview = dataSet?.states?.reduce((dest, x) => Object.assign({}, dest, {
        [x.state]: x.duration
    }), {});
    const value = products
            .reduce((dest, elem) => ({
                processed:      dest.processed + elem.processed,
                sorted:         dest.sorted + elem.sorted,
                parts:          dest.parts + elem.parts,
                processingTime: (dest.processingTime * dest.processed + elem.processingTime * elem.processed) / (dest.processed + elem.processed)
            }), {
                processed:      0,
                sorted:         0,
                parts:          0,
                processingTime: 0
            });
    const events = dataSet && dataSet.events && dataSet.events.map ? dataSet.events.map(x => Object.assign({}, x, {
        startDateTime: new Date(x.startDateTime),
        endDateTime:   new Date(x.endDateTime)
    })) : [];

    const productsGraph =  [{
        name:    translate("general.overall"),
        product: value.processed
    }].concat(props.cluster.config?.producedProductTypes ? products.slice(sliderSettings.range.from, sliderSettings.range.to).map(x => ({
        name:    translate(x.name),
        product: x.processed
    })) : []);

    return (
        <div className="history-cluster">
            {
                !params.clusterId &&
                <div className="flex">
                    <h4 className="colored cluster-header flex">
                        {translate(props.cluster?.name || "Cluster")}
                    </h4>
                    <Button _variant="icon" className="ml-3" onClick={onEyeClick}>
                        <Icon _icon="Overview"/>
                    </Button>
                </div>
            }
            <div className="cluster-widget-wrapper flex-row">
                <div className="cluster-widget-container">
                    {
                        showStatesTile && props.cluster.config?.statesOverall &&
                        <States
                            data={chartStates}
                        />
                    }
                    {
                        showStatesTile && props.cluster.config?.statesOverall &&
                        <StatesOverview
                            data={statesOverview}
                        />
                    }
                    {
                        props.cluster.config?.parts && <HistoryProductsOverview
                            data={products}
                            overall={value}
                            selectedCluster={props.cluster}
                        />
                    }
                    {
                        <EventsOverview
                            data={events}
                            cluster={props.cluster}
                            selected={selected}
                            isHistoryTile={true}
                        />}
                </div>
            </div>
            {
                params.clusterId && props.cluster.config?.parts &&
                <>
                    <SliderInput/>
                    <ProductsGraph data={productsGraph} />
                </>
            }
        </div>
    );
};

HistoryCluster.propTypes = {
    cluster: PropTypes.object
};

export { HistoryCluster };
