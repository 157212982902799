import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { Checkbox } from "./generic/Checkbox";
import md5 from "md5";
import { Button, TextArea, ComboBox, Icon } from "@circle/kip-components";
import { Calendar } from "./Calendar";
import { fromFakeUTC, toFakeUTC } from "../helper/helper";
import { TimeHeader } from "./generic/TimeHeader";
import { EditFileModal } from "./generic/editFileModal/EditFileModal";
import styles from "./eventForm.module.scss";

const validate = () => ({
    isValid: true
});

const validateRequired = content => {
    return (content.isMaintenance || content.isSetup || (content.isOtherEvent && content.name.length > 0)) && (content.plantElements.length > 0 || content.effectsAllElements);
};

const EventForm = props => { // eslint-disable-line complexity, max-statements
    const { translate, currentLanguage } = useTranslation();
    const { events, selectedPlant } = useBranch({
        events:        ["events"],
        selectedPlant: ["selectedPlant"]
    });
    const init = {
        id:                 props.id,
        name:               props.name || "",
        createdAt:          props.createdAt || new Date(),
        updatedAt:          props.updatedAt || new Date(),
        plantId:            selectedPlant?.id,
        plantElements:      props.plantElements || [],
        startDateTime:      props.startDateTime || new Date(new Date().setUTCHours(0, 0, 0, 0)),
        endDateTime:        props.endDateTime || new Date(new Date(new Date().setUTCHours(23, 59, 59, 999)).setDate(new Date().getDate() + 1)),
        isMaintenance:      props.isMaintenance || false,
        isSetup:            props.isSetup || false,
        isOtherEvent:       props.isOtherEvent || false,
        isCaptureState:     props.isCaptureState || false,
        effectsAllElements: true,
        comments:           props.comments,
        img:                props.img || []
    };
    const [valid, setValid]                     = useState({});
    const [content, setContent]                 = useState(init);
    const [hash]                                = useState(md5(JSON.stringify(init)));

    const onSubmit = () => {
        if(!valid.overall)  return null;

        const generatedName = content.isMaintenance ? "maintenance.headline" : "setup.headline";
        const name          = content.isOtherEvent ? content.name : generatedName;

        if(content.effectsAllElements) {
            const finalContent = {
                ...content,
                plantElements: []
            };

            return props.onSubmit({
                ...finalContent,
                name: name
            });
        }

        return props.onSubmit({
            ...content,
            plantElements: content.plantElements.map(x => ({ plant_element_id: x.plant_element_id })), // eslint-disable-line camelcase
            name:          name
        });
    };

    const onDateSelect = ({ from, until }) => {
        setContent({
            ...content,
            startDateTime: fromFakeUTC(from),
            endDateTime:   fromFakeUTC(until)
        });
    };

    const onChange = (key, value) => {
        const values = {
            ...content,
            [key]: value
        };
        const currentHash = md5(JSON.stringify(values));

        setContent(values);

        props.onChange(key, values[key], currentHash !== hash);
    };

    const onCheck = (name, value) => {
        setContent({
            ...content,
            isMaintenance:  false,
            isSetup:        false,
            isOtherEvent:   false,
            isCaptureState: ["isMaintenance", "isSetup"].indexOf(name) > -1,
            [name]:         value
        });
    };


    const otherEventsOptions = events?.filter(x => x.name !== "maintenance.headline" && x.name !== "setup.headline")
    .concat(content.name.length > 0 ? [{ name: content.name }] : [])
    .reduce((dest, val) => dest.filter(x => x !== val.name).concat([val.name]), [])
    .map(x => ({
        value: x,
        label: translate(x)
    }));

    const onAddImage = value => {
        const allMedia = content?.img?.map(x => x);

        onChange("img", allMedia.concat({ url: value }));
    };

    const onImgDelete = item => {
        const allMedia = [...content?.img ?? []];

        allMedia.splice(item, 1);

        onChange("img", allMedia);
    };

    useEffect(() => {
        const required  = validateRequired(content);
        const validated = validate();
        const overall   = required && validated.isValid;

        setValid({
            required,
            overall
        });
    }, [content]);

    return (
        <div className="event-block flex-column">
            <>
                <div className="event-heading flex">
                    <div className="overview-item-heading-block event-plant mb-2 pd-20 flex">
                        <img src={selectedPlant.image ?? "/images/Bitmap.png"} loading="lazy" alt="" className="bitmap"/>
                        <div className="overview-item-heading">
                            <div className="text-block-primary">
                                <span>
                                    {
                                        translate(selectedPlant.name)?.length > 24 ?
                                            `${translate(selectedPlant.name).substring(0, 24)}...` :
                                            translate(selectedPlant.name)
                                    }
                                </span>
                            </div>
                            <div className="text-block-secondary">
                                <span>{translate(selectedPlant.location)}</span>
                            </div>
                        </div>
                    </div>
                    <TimeHeader/>
                </div>
                <div className="event-form-content">
                    <section className="flex form-row">
                        <div className="event-form form-column flex-column flex-grow">
                            <h5 className="form-header font-bold">
                                { translate("event.headline") }*
                            </h5>
                            { props.isEditable &&
                                <div className="flex event z-999">
                                    <Checkbox disabled={!props.isEditable} checked={content.isMaintenance} onChange={() => onCheck("isMaintenance", true)}>
                                        { translate("maintenance.headline") }
                                    </Checkbox>
                                    <Checkbox disabled={!props.isEditable} checked={content.isSetup} onChange={() => onCheck("isSetup", true)}>
                                        { translate("setup.headline") }
                                    </Checkbox>
                                    <Checkbox disabled={!props.isEditable} className="flex flex-grow" checked={content.isOtherEvent} onChange={() => onCheck("isOtherEvent", true)}>
                                        <ComboBox
                                            isEnabled={props.isEditable}
                                            isEmptyValueAllowed
                                            onChange={([selection]) => {
                                                if(selection === undefined) return; // eslint-disable-line no-undefined

                                                onChange("name", selection);
                                            }}
                                            placeholder={translate("other.event.headline")}
                                            value={{ value: translate(content.name), label: translate(content.name), checked: true }}
                                            options={otherEventsOptions}
                                        />
                                    </Checkbox>
                                </div>
                            }
                            { !props.isEditable &&
                                <div className="vertical-align">
                                    { content.isMaintenance &&
                                        <Icon className={styles.eventIcon} _icon="Maintenance"/>
                                    }
                                    { content.isSetup &&
                                        <Icon className={styles.eventIcon} _icon="Setup"/>
                                    }
                                    <span className={styles.eventTypeHeader}>{`${!content.isMaintenance && !content.isSetup ? translate("other.event.type") : ""} ${translate(content.name)}`}</span>
                                </div>
                            }
                        </div>
                    </section>
                    <section className="flex form-row">
                        <div className="event-form form-column flex-column flex-grow">
                            <h5 className="form-header font-bold">
                                { translate("time.headline") }*
                            </h5>
                            <div>
                                <Calendar
                                    isModalView={false}
                                    onChange={onDateSelect}
                                    isReadonly={!props.isEditable}
                                    locale={currentLanguage}
                                    value={{
                                        from:  toFakeUTC(content.startDateTime),
                                        until: toFakeUTC(content.endDateTime)
                                    }}/>
                            </div>
                        </div>
                    </section>
                    <div className="event-form form-row flex-column flex-grow">
                        <h5 className="form-header font-bold">
                            { translate("comment.headline") }
                        </h5>
                        <TextArea
                            className="full-width"
                            readOnly={!props.isEditable}
                            value={content.comments ?? ""}
                            onChange={e => onChange("comments", e.target.value)}
                        />
                    </div>
                    {
                        (content.img.length > 0 || props.isEditable) &&
                        <section className="flex form-row">
                            <div className="event-form form-column flex-column flex-grow">
                                <h5 className="form-header font-bold">
                                    {
                                        props.isEditable &&
                                        translate("upload.picture")
                                    }
                                    {
                                        !props.isEditable && content.img.length > 0 &&
                                        translate("display.picture")
                                    }
                                </h5>
                                <div className="flex form-row imgContainer">
                                    {content?.img?.map((x, key) =>
                                        <EditFileModal
                                            isModalEnable={true}
                                            key={key}
                                            file={x.url}
                                            readOnly={!props.isEditable}
                                            onImageChange={() => onImgDelete(key)}
                                        />
                                    )}
                                    {props.isEditable &&
                                    <EditFileModal
                                        isModalEnable={true}
                                        file={null}
                                        onImageChange={(key, value) => onAddImage(value, key)}
                                    />
                                    }
                                </div>
                            </div>
                        </section>
                    }
                    {!content.isOtherEvent &&
                        <div className="select-state flex">
                            <Checkbox disabled={true} checked={content.isCaptureState} onChange={() => onChange("isCaptureState", !content.isCaptureState)}>
                                { translate("capture.state") }
                            </Checkbox>
                        </div>
                    }
                </div>
                <section className="form-button-group event-form-actions">
                    <Button onClick={props.onClose}>
                        <span>{translate(props.isEditable ? "actions.cancel" : "actions.close")}</span>
                    </Button>
                    {
                        props.isEditable &&
                            <Button _appearance="primary" className="ml-2" disabled={!(valid.overall && content.endDateTime && content.startDateTime && content.endDateTime > content.startDateTime)} onClick={onSubmit}>
                                <span>{translate("actions.save")}</span>
                            </Button>
                    }
                </section>
            </>
        </div>
    );
};

EventForm.propTypes = {
    id:             PropTypes.string,
    name:           PropTypes.string,
    plantId:        PropTypes.string,
    plantElements:  PropTypes.arrayOf(PropTypes.object),
    events:         PropTypes.arrayOf(PropTypes.object),
    createdAt:      PropTypes.string,
    updatedAt:      PropTypes.string,
    locale:         PropTypes.string,
    startDateTime:  PropTypes.instanceOf(Date),
    endDateTime:    PropTypes.instanceOf(Date),
    isMaintenance:  PropTypes.bool,
    isSetup:        PropTypes.bool,
    isOtherEvent:   PropTypes.bool,
    isCaptureState: PropTypes.bool,
    comments:       PropTypes.string,
    selectedPlant:  PropTypes.object,
    isEditable:     PropTypes.bool,
    onChange:       PropTypes.func,
    onSubmit:       PropTypes.func,
    onClose:        PropTypes.func,
    img:            PropTypes.arrayOf(PropTypes.object)
};

EventForm.defaultProps = {
    onChange:   x => x,
    onSubmit:   x => x,
    isEditable: true
};

export { EventForm };
