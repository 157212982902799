export default {
    "dashboard.widgets.states.used":        "Used",
    "dashboard.widgets.states.standstill":  "Standstill",
    "dashboard.widgets.states.error":       "Error",
    "dashboard.widgets.states.maintenance": "Maintenance",
    "dashboard.widgets.states.setup":       "Setup",
    "dashboard.widgets.states.blocked":     "Blocked",
    "dashboard.widgets.states.notReady":    "NotReady",
    "dashboard.widgets.states.turnedOff":   "TurnedOff"
};
