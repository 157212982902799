interface Data {
    getter: (val: string) => string,
    items: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
    property: string,
    ordering: string
}

export const sort = ({ items, property, ordering = "asc", getter = x => x }: Data) => {
    const order    = ordering.toLowerCase();
    const newItems = items?.concat([]);

    newItems.sort((a, b) => {
        const valueA = (item => {
            if(item[property] instanceof Date)
                return new Date(item[property]).toLocaleString();

            if(Array.isArray(item[property]) && item[property]?.every((obj : object) => !Object.prototype.hasOwnProperty.call(obj, "language")))
                return item[property].length.toString();

            if(typeof item[property] === "boolean")
                return item[property].toString();

            if(typeof item[property] === "number")
                return item[property];

            return (getter(item[property])?.toLowerCase() || "");
        })(a);

        const valueB = (item => {
            if(item[property] instanceof Date)
                return new Date(item[property]).toLocaleString();

            if(Array.isArray(item[property]) && item[property]?.every((obj : object) => !Object.prototype.hasOwnProperty.call(obj, "language")))
                return item[property].length.toString();

            if(typeof item[property] === "boolean")
                return item[property].toString();

            if(typeof item[property] === "number")
                return item[property];

            return (getter(item[property])?.toLowerCase() || "");
        })(b);

        return order === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    });

    return newItems;
};
