import PropTypes from "prop-types";
import { DashboardClusterWidget } from "./DashboardClusterWidget";
import { useTranslation } from "@circle/gestalt-app";

const StatesOverview = props => {
    const { translate } = useTranslation();

    return (
        <DashboardClusterWidget header={ translate("dashboard.widgets.overview") }>
            <table className="cluster-table states-table">
                <tbody>
                    <tr>
                        <td>{ translate("dashboard.widgets.total.run.time") }</td>
                        {
                            (props.data.total ?? "0h 0m 0s").split(" ").map(x =>
                                <td key={x}>{x}</td>
                            )
                        }
                    </tr>
                    {
                        Object.keys(props.data).filter(x => x !== "total" && props.data[x] !== "0h 0m 0s").map(x => (
                            <tr key={x}>
                                <td>{ translate(`dashboard.widgets.states.${x}`) }</td>
                                {
                                    (props.data[x]).split(" ").map(y =>
                                        <td key={y}>{y}</td>
                                    )
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </DashboardClusterWidget>
    );
};

StatesOverview.propTypes = {
    data: PropTypes.object
};

StatesOverview.defaultProps = {
    data: {}
};

export { StatesOverview };
