import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import Slider, { SliderTooltip } from "rc-slider";

// eslint-disable-next-line id-length
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

const handle = props => {
    const { value, dragging, index, ...restProps } = props;

    return (
        <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value} %`}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </SliderTooltip>
    );
};

const marks = {
    0:   <strong>0</strong>,
    100: <strong>100</strong>
};

const SliderInput = () => {
    const { translate } = useTranslation();
    const { trigger }   = useTrigger();
    const { value }     = useBranch({
        value: ["sliderSettings"]
    });

    const onShortcutClick = (name, from, to) => {
        const settings = {
            shortcut: name,
            range:    {
                from,
                to
            }
        };

        trigger("setSliderSettings", settings);
    };

    const onSliderChange = x => {
        const settings = {
            shortcut: value.shortcut,
            range:    {
                from: x[0],
                to:   x[1]
            }
        };

        trigger("setSliderSettings", settings);
    };

    return (
        <div className="graph-input flex-row-reverse mt-6">
            <div className="graph-filter space-around center rounded graph-filter grey-background">
                <div onClick={() => onShortcutClick("top5", 0, 5)} className={`clickable calendar-text ${value.shortcut === "top5" ? "enable" : "disable"}`}>
                    <span className="calendar_longtext">{ translate("graph.filter.top5") }</span>
                    <span className="calendar_shorttext">{ translate("graph.filter.top5.short") }</span>
                </div>
                <div onClick={() => onShortcutClick("top10", 0, 10)} className={`clickable calendar-text ${value.shortcut === "top10" ? "enable" : "disable"}`}>
                    <span className="calendar_longtext">{ translate("graph.filter.top10") } </span>
                    <span className="calendar_shorttext">{ translate("graph.filter.top10.short") }</span>
                </div>
                <div onClick={() => onShortcutClick("top25", 0, 25)} className={`clickable calendar-text ${value.shortcut === "top25" ? "enable" : "disable"}`}>
                    <span className="calendar_longtext">{ translate("graph.filter.top25") }</span>
                    <span className="calendar_shorttext">{ translate("graph.filter.top25.short") }</span>
                </div>
                <div onClick={() => onShortcutClick("top50", 0, 50)} className={`clickable calendar-text ${value.shortcut === "top50" ? "enable" : "disable"}`}>
                    <span className="calendar_longtext">{ translate("graph.filter.top50") }</span>
                    <span className="calendar_shorttext">{ translate("graph.filter.top50.short") }</span>
                </div>
                <div onClick={() => onShortcutClick("userDefined", value.range.from, value.range.to)} className={`clickable calendar-text ${value.shortcut === "userDefined" ? "enable" : "disable"}`}>
                    <span className="calendar_longtext">{ translate("graph.filter.user.defined") }</span>
                    <span className="calendar_shorttext">{ translate("graph.filter.user.defined.short") }</span>
                </div>
            </div>
            <div className={`slider-content mr-4 space-around center rounded grey-background ${value.shortcut !== "userDefined" ? "disableSlider" : "enableSlider"}`}>
                <Range
                    min={0}
                    max={100}
                    value={[value.range.from, value.range.to]}
                    marks={marks}
                    handle={handle}
                    disabled={value.shortcut !== "userDefined"}
                    onChange={onSliderChange}
                />
            </div>
        </div>
    );
};

export { SliderInput };
