import PropTypes from "prop-types";
import { useOutsideClickHandler } from "palstek";
import { useTranslation } from "@circle/gestalt-app";
import { Checkbox } from "../Checkbox";
import { OverflowContainer } from "@circle/kip-components";
import styles from "./dropDownSection.module.scss";

const DropDownSection = props => {
    const { translate } = useTranslation();
    const outsideClickHandler = (e, targetRef) => {
        if(!targetRef) return "";

        return props.onOutside(e);
    };

    const [ref] = useOutsideClickHandler(outsideClickHandler, props.isOpen);

    return (
        <div name="cluster-select" id="cluster-select" className={props.isOpen ? styles.isOpen : styles.isHidden} ref={ref}>
            <OverflowContainer className={styles.overview } name="cluster-select" id="cluster-select">
                <b className={styles.header}>{translate("cluster.select.header")}</b>
                <ul className={styles.list}>
                    {props.elements.map((ele, key) =>
                        (
                            <li key={key} >
                                <Checkbox onChange={props.onSelect(ele)} value={ele.id ?? ""} checked={props.active.includes(ele.id)}>
                                    <b>{ translate(ele.name) }</b>
                                </Checkbox>
                            </li>
                        ))}
                </ul>
            </OverflowContainer>
        </div>
    );
};

DropDownSection.propTypes = {
    elements:  PropTypes.arrayOf(PropTypes.object),
    onSelect:  PropTypes.func,
    active:    PropTypes.array,
    isOpen:    PropTypes.bool,
    onOutside: PropTypes.func
};

export { DropDownSection };
