import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Datatable } from "../generic/Datatable";
import { Column } from "../generic/Column";
import { Icon } from "@circle/kip-components";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const ElementsNav = () => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const params = useParams();
    const { plantElements, selected, options } = useBranch({
        plantElements: ["sortedPlantElements"],
        selected:      ["selectedPlant"],
        options:       ["queryOptions"]
    });
    const items = selected ?
        plantElements.filter(x => x.plantId === selected.id) :
        plantElements.filter(x => x.plantId === "");
    const content = items.map(x => ({
        ...x,
        hasActionsColumn: x.status !== "active"
    }));

    const onSort = elem => {
        trigger("setSortTable", elem);
    };

    useEffect(() => {
        trigger("setSortTable", {
            property:   "name",
            isOrderAsc: true
        });
        trigger("onSettingsPlantSelect", params.plantId, options);
    }, []);

    return (
        <>
            <Datatable
                content={content}
                onDelete={x => trigger("deletePlantEle", x)}
                isEditable={false}
                hasActionsColumn={false}
                className="datatable"
                onHeadlineClick={onSort}
            >
                <Column
                    title=""
                    width="5%"
                    item=""
                    render={() =>
                        <div className="vertical-align">
                            <Icon className="home-icon" _icon="PlantElement"/>
                        </div>
                    }
                />
                <Column
                    title="element.name.header"
                    width="30%"
                    item="name"
                    sortable
                    render={x =>
                        <span className="clamped">{ translate(x) }</span>
                    }
                />
                <Column
                    title="status.header"
                    width="10%"
                    item="status"
                    sortable
                    render={x =>
                        <span className="clamped">{ translate(x) }</span>
                    }
                />
                <Column
                    title="reference.header"
                    width="25%"
                    item="isPlantReference"
                    sortable
                    render={x => (
                        <>
                            {
                                Boolean(x) &&
                                <div className="flex horizontal-center">
                                    <Icon className="home-icon" _icon="Check" />
                                </div>
                            }
                        </>
                    )}
                />
                <Column
                    title="last.modified.header"
                    width="15%"
                    sortable
                    item="updatedAt"
                    render={x => {
                        if(!x) return "-";

                        const finalDate = x?.toLocaleDateString ? x : new Date(x);

                        return finalDate.toLocaleString().replace(",", "");
                    }}
                />
            </Datatable>
        </>
    );
};

export { ElementsNav };
