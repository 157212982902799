interface PlantElement {
    type: string
}

interface Cluster {
    plantElements: PlantElement[]
}

const getClusterType = (cluster: Cluster): string => {
    const types = cluster.plantElements.map(plantElement => plantElement.type);

    if(types.find(x => x === "Counter"))                  return "Counter";
    if(types.find(x => x === "States"))                   return "States";
    if(types.find(x => x === "CounterAndStates"))         return "CounterAndStates";
    if(types.find(x => x === "CounterWithType"))          return "CounterWithType";
    if(types.find(x => x === "CounterWithTypeAndStates")) return "CounterWithTypeAndStates";
    if(types.find(x => x === "FullCounter"))              return "FullCounter";

    return "FullCounterAndStates";
};

// eslint-disable-next-line complexity
export const getConfigOfCluster = (cluster: Cluster) => {
    const clusterType = getClusterType(cluster);

    const config = { //eslint-disable-line
        statesOverall:           !(clusterType === "Counter" || clusterType === "CounterWithType" || clusterType === "FullCounter"),
        targetAmount:            !(clusterType === "States"),
        currentAmount:           !(clusterType === "States"),
        waste:                   !(clusterType === "Counter" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithType" || clusterType === "States" || clusterType === "CounterWithTypeAndStates"),
        throughputTime:          !(clusterType === "Counter" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithType" || clusterType === "States" || clusterType === "CounterWithTypeAndStates"),
        constructionTime:        !(clusterType === "Counter" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithType" || clusterType === "States" || clusterType === "CounterWithTypeAndStates"),
        parts:                   !(clusterType === "States"),
        productivity:            !(clusterType === "States"),
        producedProductTypes:    !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates"),
        historyProduced:         !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates"),
        historyWaste:            !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithTypeAndStates"),
        historyThroughputTime:   !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithTypeAndStates"), // eslint-disable-line id-length
        historyConstructionTime: !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates" || clusterType === "CounterWithType" || clusterType === "CounterWithTypeAndStates"), // eslint-disable-line id-length
        historyParts:            !(clusterType === "Counter" || clusterType === "States" || clusterType === "CounterAndStates")
    };

    return config;
};
