import { Routes, Route } from "react-router-dom";
import { ClustersNav } from "./ClustersNav";
import { Fragment } from "react";

const ClustersWrapper = () => {
    return (
        <Routes>
            <Route path=":plantId/*" element={<ClustersNav/>}/>
            <Route path="*" element={<Fragment/>}/>
        </Routes>
    );
};

export { ClustersWrapper };
