import PropTypes from "prop-types";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { CombinedTooltip } from "./history/CombinedTooltip";

const MixedChart = props => {
    const { translate }        = useTranslation();
    const { fullScreenWidget } = useBranch({
        fullScreenWidget: ["fullScreenWidget"]
    });
    const sumTargets   = props.data.chartData.reduce((dest, val) => dest + val.target, 0);
    const highestValue = props.data.chartData.reduce((dest, val) => dest < val.actual || dest < val.target ? (val.actual < val.target ? val.target : val.actual) : dest, 0); // eslint-disable-line no-nested-ternary
    const yAxisWidth   = Math.sqrt(highestValue.toString().length) * 50;

    return (
        <div style={{ height: fullScreenWidget ? "45rem" : "232px", width: "100%" }}>
            <ResponsiveContainer>
                <ComposedChart
                    margin={{ top: 12, right: 10, left: 10, bottom: 44 }}
                    data={props.data.chartData}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey="hour" label={{ value: translate("productivity.hour"), position: "bottom", offset: fullScreenWidget ? 15 : 0 }} />
                    <YAxis label={{ value: translate("productivity.amount"), angle: -90, position: "insideLeft" }} width={yAxisWidth} />
                    <Tooltip content={<CombinedTooltip labels={props.labels} units={props.units} />}/>
                    <Bar dataKey="actualPerHour" fill="#2e5978" maxBarSize={25} />
                    {
                        sumTargets > 0 &&
                        <Line type="monotone" dataKey="target" stroke="#db9d57" />
                    }
                    <Line type="monotone" dataKey="actual" stroke="#7cd992" />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

MixedChart.propTypes = {
    data:   PropTypes.object,
    labels: PropTypes.object,
    units:  PropTypes.object
};

MixedChart.defaultProps = {
    labels: {},
    units:  {}
};

export { MixedChart };
