import PropTypes from "prop-types";

const CustomLegend = props => {
    const names = [...new Set(props.payload.map(x => x.value))];
    const items = names.map(elem => props.payload.find(x => elem === x.value));

    return (
        <ul className={`recharts-default-legend ${props.className}`} style={{ padding: 0, margin: 0, textAlign: "center" }}>
            {
                items.map(x => (
                    <li key={x.value} className="recharts-legend-item" style={{ display: "inline-block", marginRight: 10 }} onClick={() => props.onClick(x)}>
                        <svg x={0} y={0} width={props.iconSize} height={props.iconSize} fill="#000000" viewBox="0 0 32 32" className="recharts-surface" style={{ display: "inline-block", verticalAlign: "middle", marginRight: 4 }}>
                            {
                                x.type === "line" &&
                                <line x1={0} x2={30} y1={13} y2={13} strokeWidth="6" stroke="red" className="recharts-legend-icon" strokeDasharray="6,4"/>
                            }
                            {
                                x.type === "triangle" &&
                                <line x1={0} x2={25} y1={13} y2={13} strokeWidth="6" stroke="#000000" className="recharts-legend-icon"/>
                            }
                            {
                                x.type === "circle" &&
                                <line x1={0} x2={30} y1={13} y2={13} strokeWidth="6" stroke="#000000" className="recharts-legend-icon" strokeDasharray="6,4"/>
                            }
                            {
                                x.type === "rect" &&
                                <circle cx="16" cy="16" r={16} fill={x.color}/>
                            }
                        </svg>
                        <span className="recharts-legend-item-text" style={{ color: "#000000" }}>{ x.value }</span>
                    </li>
                ))
            }
        </ul>
    );
};

CustomLegend.propTypes = {
    className: PropTypes.string,
    iconSize:  PropTypes.number,
    payload:   PropTypes.arrayOf(PropTypes.object),
    onClick:   PropTypes.func
};

export { CustomLegend };
