import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { DropDownSelect } from "@circle/kip-components";
import styles from "./plantsDropdown.module.scss";
import { useLocation } from "react-router-dom";

const PlantsDropdown = () => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const location = useLocation();
    const { plants, selected } = useBranch({
        plants:   ["fullPlants"],
        selected: ["selectedPlant"]
    });

    const plantOptions = plants?.map(x => ({
        value: x.id,
        label: <span key={x.id} className={styles.itemLabel}>
            <img src={x.manufacturer?.logo ?? "/images/Bitmap.png"} loading="lazy" alt="Manufacturer Logo" />
            <span>
                <b>{translate(x.name) || ""}</b>
                <br />
                {translate(x.location)}
            </span>
        </span>,
        checked: selected?.id === x.id
    }));

    const onSelect = options => {
        const selectedId = options.find(x => x.checked)?.value;
        const plant      = plants.find(x => x.id === selectedId);

        if(location.pathname.includes("history"))   return trigger("onHistoryPlantSelect", plant.id);
        if(location.pathname.includes("dashboard")) return trigger("onDashboardPlantSelect", plant.id);

        return trigger("onSettingsPlantSelect", plant.id, "auto");
    };

    return (
        <DropDownSelect
            className={styles.dropdown}
            closeOnOptionClick
            options={plantOptions}
            // eslint-disable-next-line no-undefined
            customLabel={plantOptions.find(x => x.checked) ? undefined : (
                <span key={"fallback"} className={styles.itemLabel}>
                    <img src={"/images/Bitmap.png"} loading="lazy" alt="Fallback Logo" />
                    <span>
                        <b>{translate([{
                            language: "de",
                            content:  "Anlage auswählen"
                        }, {
                            language: "en",
                            content:  "Choose a plant"
                        }]) || ""}</b>
                        <br />
                        {translate([{
                            language: "de",
                            content:  ""
                        }, {
                            language: "en",
                            content:  ""
                        }])}
                    </span>
                </span>
            )}
            onChange={onSelect}
        >
        </DropDownSelect>
    );
};

export { PlantsDropdown };
