interface WidgetData {
    target: string,
    actual: string,
    waste: string,
    averagePassTime: string,
    averageEditTime: string,
    averageParts: string
}

export class OverviewWidgetData implements WidgetData {
    constructor({ target, actual, waste, averagePassTime, averageEditTime, averageParts }: WidgetData) {
        this.target = target;
        this.actual = actual;
        this.waste = waste;
        this.averagePassTime = averagePassTime;
        this.averageEditTime = averageEditTime;
        this.averageParts = averageParts;
    }
    target: string;
    actual: string;
    waste: string;
    averagePassTime: string;
    averageEditTime: string;
    averageParts: string;
}
