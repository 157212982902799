import { Routes, Route, Navigate } from "react-router-dom";
import { SettingsNav } from "./SettingsNav";
import { ElementsNav } from "./ElementsNav";
import { DashboardNav } from "./DashboardNav";
import { SettingsNavbar } from "./SettingsNavbar";
import { Fragment } from "react";
import { ClustersWrapper } from "./ClustersWrapper";
import { useBranch } from "@circle/gestalt-app";

const Settings = () => {
    const { selected } = useBranch({
        selected: ["selectedPlant"]
    });

    return (
        <div className="settings flex-grow">
            <section>
                <div className="flex-grow">
                    <div className="flex-column">
                        <SettingsNavbar/>
                        <Routes>
                            <Route path="dashboard/*" element={<Fragment/>}/>
                            <Route path="dashboard/:dashboardId" element={<DashboardNav/>}/>
                            <Route path="settings/:plantId/*" element={<SettingsNav/>}/>
                            <Route path="settings/:plantId/:settingsId" element={<SettingsNav/>}/>
                            <Route path="settings/*" element={<Fragment/>}/>
                            <Route path="elements/:plantId" element={<ElementsNav/>}/>
                            <Route path="elements/*" element={<Fragment/>}/>
                            <Route path="cluster/*" element={<ClustersWrapper/>}/>
                            <Route path="*" element={<Navigate to={`cluster${selected ? `/${selected.id}` : ""}`}/>}/>
                        </Routes>
                    </div>
                </div>
            </section>
        </div>
    );
};

export { Settings };
