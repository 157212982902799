import { v4 as uuid } from "uuid";
import { getColor, chartColors } from "../helper/color";

interface ISlice {
    id: string,
    label: string,
    value: string,
    color: string
}

interface Data {
    portion: number,
    state: string
}

export class Slice implements ISlice {
    constructor(x: Data) {
        this.id    = uuid();
        this.label = `dashboard.widgets.states.${x.state}`;
        this.value = (x.portion * 100).toFixed(2);
        this.color = chartColors[getColor(x.state)];
    }
    id: string;
    label: string;
    value: string;
    color: string;
}
