import { useTranslation } from "@circle/gestalt-app";
import { Timer } from "./Timer";
import { Routes, Route } from "react-router-dom";
import { Header as HistoryHeader } from "./history/Header";
import { Header as DashboardHeader } from "./dashboard/Header";
import { AdminHeader } from "./settings/AdminHeader";

const ContentHeader = () => {
    const { translate } = useTranslation();

    return (
        <div className="vertical-align" style={{ height: "4rem" }}>
            <Routes>
                <Route path="/overview" element={<>
                    <h5 className="heading mt-4 mb-2">{translate("overview.content.header")}</h5>
                    <Timer/>
                </>}/>
                <Route path="/history/*" element={<HistoryHeader/>}/>
                <Route path="/dashboard/*" element={<DashboardHeader/>}/>
                <Route path="/admin/*" element={<AdminHeader/>}/>
            </Routes>
        </div>
    );
};

export { ContentHeader };
