import { getEventDistribution, toFakeUTC, IEvent } from "../helper/helper";
import { getDurationString  } from "../helper/date";

interface State {
    startTime: Date
    secsBlocked: number
    secsUsed: number
    secsError: number
    secsTurnedOff: number
    secsNotReady: number
    secsStandstill: number
    secsMaintenance: number
    secsSetup: number
}
interface Distribution {
    events: IEvent[]
    states: State[]
    from: Date
    until: Date
}

interface Data {
    [key: string]: number
}

const getDistribution = (states: State[], from: Date, until: Date): Data => {
    return states
        .filter(x => toFakeUTC(x.startTime) >= new Date(from) && toFakeUTC(x.startTime).getTime() <= until.getTime())
        .reduce((dest, elem) => ({
            blocked:     dest.blocked + elem.secsBlocked,
            used:        dest.used + elem.secsUsed,
            error:       dest.error + elem.secsError,
            turnedOff:   dest.turnedOff + elem.secsTurnedOff,
            notReady:    dest.notReady + elem.secsNotReady,
            standstill:  dest.standstill + elem.secsStandstill,
            maintenance: dest.maintenance + elem.secsMaintenance,
            setup:       dest.setup + elem.secsSetup
        }), {
            blocked:     0,
            used:        0,
            error:       0,
            turnedOff:   0,
            standstill:  0,
            notReady:    0,
            maintenance: 0,
            setup:       0
        });
};

export class StatesDistribution {
    constructor({ events, states, from, until }: Distribution) {
        const uniqueEvents = events.filter(x => x.isCaptureState).reduce((dest: IEvent[], elem: IEvent) => dest.find(x => x.startDateTime === elem.startDateTime && x.endDateTime === elem.endDateTime) ? dest : dest.concat(elem), []);
        const distribution = getDistribution(states, from, until);

        const eventDistribution = getEventDistribution(uniqueEvents, [from, until]);
        const totalState        = Object.keys(distribution).reduce((dest, x) => dest + parseInt((distribution[x] * 1000).toString(), 10), 0);
        const total             = Object.keys(eventDistribution).reduce((dest, x) => dest + eventDistribution[x] * 1000, totalState);

        return Object.keys(distribution).map((x: string) => ({
            duration: getDurationString(distribution[x] * 1000),
            state:    x,
            portion:  total === 0 ? 0 : Math.round(distribution[x] * 100000 / total) / 100
        })).concat(Object.keys(eventDistribution).map(x => ({
            duration: getDurationString(eventDistribution[x] * 1000),
            state:    x,
            portion:  total === 0 ? 0 : Math.round(eventDistribution[x] * 100000 / total) / 100
        })));
    }

    static of(args: Distribution) {
        return new StatesDistribution(args);
    }
}
