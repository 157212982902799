import { useBranch } from "@circle/gestalt-app";
import { useParams } from "react-router-dom";
import { Button, Icon } from "@circle/kip-components";

const MessageMonitorLink = () => {
    const params = useParams();
    const { calendar } = useBranch({
        calendar: ["calendar"]
    });

    const startTimeQuery = `startTime=[${parseInt(calendar.from.getTime() / 1000, 10)},${parseInt(calendar.until.getTime() / 1000, 10)}]`;
    const appUrl         = `${window.location.protocol}//${window.location.host.replace("productivity.", "")}/message-monitor`;
    const link           = `${appUrl}/monitor/${params.plantId}?${startTimeQuery}&messageType=["err"]`;

    return (
        <a href={link} rel="noreferrer" target="_blank">
            <Button _variant="icon" className="mr-4">
                <Icon _icon="ExternalInfo"/>
            </Button>
        </a>
    );
};

export { MessageMonitorLink };
