import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation, useTrigger, useBranch } from "@circle/gestalt-app";
import { Button, Icon } from "@circle/kip-components";
import { DropDownSection } from "../generic/dropDownSection/DropDownSection";

const ClusterSelector = () => {
    const ref           = useRef();
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { selected, selectedClusters, clusters, targetSettings } = useBranch({
        selected:         ["selectedPlant"],
        selectedClusters: ["selectedClusters"],
        clusters:         ["joinedClusters"],
        targetSettings:   ["targetSettings"]
    });
    const clustersOfPlant = clusters.filter(x => x.plantId === selected?.id);
    const active          = selectedClusters.map(elem => elem.id);
    const elements        = targetSettings.filter(x => x.type === "element" && x.elementsCount > 0);
    const [isOpen, setIsOpen] = useState(false);

    const onSelect = (cluster, e) => {
        trigger(e.target.checked ? "addSelectedCluster" : "removeSelectedCluster", cluster);
    };

    const filteredClusters = clustersOfPlant.sort((a, b) => {
        return translate(a.name).localeCompare(translate(b.name));
    });

    const onOutside = e => {
        if(ref.current.contains(e.target)) return;

        setIsOpen(!open);
    };

    return (
        <div className="dashboard-header">
            <div ref={ref}>
                <Button className={![elements, filteredClusters].flat().length && "isEmpty"} _variant="icon" onClick={() => setIsOpen(!isOpen)}>
                    <Icon _icon="ThreeDots" className="rotate" />
                </Button>
            </div>
            <span>
                <DropDownSection isOpen={isOpen} onOutside={onOutside} elements={[elements, filteredClusters].flat()} active={active} onSelect={ele => onSelect.bind(this, ele)}/>
                <div className="flex-wrap">
                    {[elements, filteredClusters].flat().map(ele => {
                        return <span key={ele.id} className={active.includes(ele.id) ? "badge" : "hidden"}><b>{ translate(ele.name) }</b></span>;
                    })}
                </div>
            </span>
        </div>
    );
};

ClusterSelector.propTypes = {
    selected:         PropTypes.object,
    clusters:         PropTypes.arrayOf(PropTypes.object),
    selectedClusters: PropTypes.arrayOf(PropTypes.object),
    targetSettings:   PropTypes.arrayOf(PropTypes.object)
};

export { ClusterSelector };
