import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { DatePicker } from "../../types/DatePicker";
import { formatTimeSpan } from "../../helper/formatTimeSpan";
import { Button, Icon, Modal, SwitchButton } from "@circle/kip-components";
import { Calendar } from "../Calendar";
import { fromFakeUTC, toFakeUTC } from "../../helper/helper";

const HistoryCalendar = () => {
    const { translate, currentLanguage } = useTranslation();
    const { trigger } = useTrigger();
    const { calendarData } = useBranch({
        calendarData: ["calendar"]
    });
    const selectedDate  = { from: calendarData.from, until: calendarData.until };

    const [calendar, setCalendar] = useState(false);
    const [date, setDate]         = useState(selectedDate);

    useEffect(() => {
        setDate(selectedDate);
    }, [selectedDate.from, selectedDate.until]);

    const onSubmit = val => {
        const { from, until } = val;

        const newDate = DatePicker.of([new Date(fromFakeUTC(from.getTime())), new Date(fromFakeUTC(until.setUTCDate(until.getUTCDate())))]);

        setCalendar(false);
        setDate(newDate);

        trigger("onCalendarSelect", newDate);
    };

    const onClose = () => {
        setDate(selectedDate);
        setCalendar(false);
    };

    return (
        <>
            <div className="calendar-select flex-column">
                <span className="datetime-label">
                    { formatTimeSpan(calendarData.from, calendarData.until, currentLanguage) }
                </span>
                <div className="calendar-group">
                    <SwitchButton
                        onChange={e => trigger("onCalendarSelect", DatePicker.of(e.target.value))}
                        options={!calendarData ? [] : [
                            {
                                value: "overall", label: <>
                                    <span className="calendar_longtext">{translate("calendar.overall")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.overall.short")}</span>
                                </>,
                                checked: calendarData.name === "overall"
                            },
                            {
                                value: "thisYear", label: <>
                                    <span className="calendar_longtext">{translate("calendar.thisYear")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.thisYear.short")}</span>
                                </>,
                                checked: calendarData.name === "thisYear"
                            },
                            {
                                value: "thisMonth", label: <>
                                    <span className="calendar_longtext">{translate("calendar.thisMonth")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.thisMonth.short")}</span>
                                </>,
                                checked: calendarData.name === "thisMonth"
                            },
                            {
                                value: "last7Days", label: <>
                                    <span className="calendar_longtext">{translate("calendar.last7Days")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.last7Days.short")}</span>
                                </>,
                                checked: calendarData.name === "last7Days"
                            },
                            {
                                value: "today", label: <>
                                    <span className="calendar_longtext">{translate("calendar.today")}</span>
                                    <span className="calendar_shorttext">{translate("calendar.today.short")}</span>
                                </>,
                                checked: calendarData.name === "today"
                            }
                        ]}
                    />
                </div>
            </div>

            <div>
                <Button _variant="icon" _inGroup onClick={() => setCalendar(!calendar)}>
                    <Icon _icon="DatePicker" />
                </Button>
            </div>
            <Modal
                title={translate("modal.date")}
                isOpen={calendar}
                isCloseable={false}
                stopBackgroundPropagation={false}
                onClose={() => setCalendar(false)}
            >
                <Calendar
                    isModalView={true}
                    onChange={onSubmit}
                    onCancel={onClose}
                    locale={currentLanguage}
                    value={{
                        from:  toFakeUTC(date.from),
                        until: toFakeUTC(date.until)
                    }}
                />
            </Modal>
        </>
    );
};

export { HistoryCalendar };
