import PropTypes from "prop-types";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { Selector } from "../Selector";
import { Checkbox } from "../generic/Checkbox";
import ClusterFilter from "../../enums/clusterFilter";

const DashboardSelector = props => {
    const { translate } = useTranslation();
    const { selectedShift, selectedPlant } = useBranch({
        selectedShift: ["selectedShift"],
        selectedPlant: ["selectedPlant"]
    });

    return (
        <Selector>
            {
                Object.keys(ClusterFilter).map(key =>
                    <div className="flex" key={key}>
                        <Checkbox value={key} className="cluster-checkbox" checked={props.active.includes(key)} onChange={e => props.onSelect(e.target.value)} >
                            <span className="mr-2">{ translate(ClusterFilter[key]) }</span>
                        </Checkbox>
                    </div>
                )
            }
            {
                selectedPlant?.workingShifts.map((x, idx) =>
                    <div className="flex ml-2" key={idx}>
                        <Checkbox disabled={Boolean(selectedShift)} value={translate(x.title)} className="cluster-checkbox" checked={props.active.includes(translate(x.title)) || selectedShift?.id === x.id} onChange={e => props.onSelect(e.target.value)} >
                            <span className="mr-2">{ translate(x.title) }</span>
                        </Checkbox>
                    </div>
                )
            }
        </Selector>
    );
};

DashboardSelector.propTypes = {
    onSelect: PropTypes.func,
    active:   PropTypes.arrayOf(PropTypes.string)
};

export { DashboardSelector };
