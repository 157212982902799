import PropTypes from "prop-types";
import { Icon, Button } from "@circle/kip-components";

const Row = props => {
    const onDelete = e => {
        if(e.stopPropagation) e.stopPropagation(e);

        props.onDelete(props.content.id);
    };

    return (
        // eslint-disable-next-line no-nested-ternary
        <tr className={`row ${!props.active ? props.isEditable ? "clickable" : "" : "active"}`} onClick={props.onClick}>
            <td className="placeholder" />
            {
                props.children.map((x, index) => (
                    <td key={props.index * props.children.length + index} style={{ width: props.columnWidths[index] }}>
                        <div className="flex">
                            {
                                x.props.render &&
                                x.props.render(!x.props.item ? props.content : props.content[x.props.item])
                            }
                            {
                                !x.props.render &&
                                <span>{props.content[x.props.item]}</span>
                            }
                        </div>
                    </td>
                ))
            }
            {
                (props.hasActionsColumn || props.isEditable) &&
                <td style={{ width: "10%" }}>
                    {
                        props.hasActionsColumn &&
                        <Button _variant="icon" onClick={onDelete}>
                            <Icon _icon="Trashcan" />
                        </Button>
                    }
                </td>
            }

        </tr>
    );
};

Row.propTypes = {
    children:         PropTypes.node,
    index:            PropTypes.number,
    content:          PropTypes.object,
    onClick:          PropTypes.func,
    active:           PropTypes.bool,
    isEditable:       PropTypes.bool,
    onDelete:         PropTypes.func,
    hasActionsColumn: PropTypes.bool,
    columnWidths:     PropTypes.arrayOf(PropTypes.string)
};

export { Row };
