import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Datatable } from "../generic/Datatable";
import { Column } from "../generic/Column";
import { SettingsForm } from "./SettingsForm";
import { MultiSettingsForm } from "./MultiSettingsForm";
import { Icon, Drawer } from "@circle/kip-components";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const SettingsNav = () => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const params = useParams();
    const { targetSettings, selectedClusters, selected, options } = useBranch({
        targetSettings:   ["targetSettings"],
        selectedClusters: ["selectedClusters"],
        selected:         ["selectedPlant"],
        options:          ["queryOptions"]
    });
    const content = selected ?
        targetSettings.filter(x => x.plantId === selected.id) :
        targetSettings.filter(x => x.plantId === "");
    const active = selectedClusters.map(x => x.id);

    const onRowClick = elem => {
        if(!active.includes(elem.id)) return trigger("addSelectedCluster", elem);

        trigger("removeSelectedCluster", elem);

        if(active.length !== 0) return null;

        return trigger("resetSelectedClusters");
    };

    const onSettingSubmit = setting => {
        trigger(setting.id ? "editSetting" : "createSetting", setting);

        return trigger("resetSelectedClusters");
    };

    const onClose = x => {
        if(x.composedPath && x.composedPath().find(y => y.localName === "td")) return;

        trigger("resetSelectedClusters");
    };

    const onSort = elem => {
        trigger("setSortTable", elem);
    };

    useEffect(() => {
        trigger("setSortTable", {
            property:   "name",
            isOrderAsc: true
        });
        trigger("onSettingsPlantSelect", params.plantId, options);
    }, []);

    return (
        <>
            <Datatable
                content={content}
                isEditable={true}
                hasActionsColumn={false}
                onRowClick={onRowClick}
                selected={active.length <= 1 ? active[0] : active}
                className="datatable"
                isMultiSelect={active.length > 1}
                onHeadlineClick={onSort}
            >
                <Column
                    title=""
                    width="10%"
                    item="type"
                    render={x =>
                        <div className="vertical-align">
                            <Icon className="home-icon" _icon={x === "cluster" ? "Cluster" : "PlantElement"}/>
                        </div>
                    }
                />
                <Column
                    title="element.name.header"
                    width="45%"
                    item="name"
                    sortable
                    render={x =>
                        <span className="clamped">{ translate(x) }</span>
                    }
                />
                <Column
                    title="plant.ele.count.header"
                    width="60%"
                    item="elementsCount"
                />
                <Column
                    title="last.modified.header"
                    width="50%"
                    sortable
                    item={"createdAt" || "updatedAt"}
                    render={x => {
                        const date = x;

                        if(!date) return "-";

                        const finalDate = date.toLocaleDateString ? date : new Date(date);

                        return finalDate.toLocaleString().replace(",", "");
                    }}
                />
            </Datatable>
            {
                active.length > 0 &&
                <Drawer className="sidebar drawer-container" _show={active.length !== 0} _onClose={onClose}>
                    {
                        (active.length !== 0 && active.length === 1) &&
                            <SettingsForm
                                {...selectedClusters[0]}
                                onCancel={() => trigger("resetSelectedClusters")}
                                onSubmit={onSettingSubmit}
                                isEditable={false}
                            />}

                    {
                        (active.length !== 0 && active.length > 1) &&
                            <MultiSettingsForm
                                selectedClusters={selectedClusters}
                                onCancel={onRowClick}
                                onSubmit={onSettingSubmit}
                                isEditable={false}
                            />
                    }
                </Drawer>
            }
        </>
    );
};

export { SettingsNav };
