import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { DatePicker } from "../../types/DatePicker";
import { CombinedTooltip } from "./CombinedTooltip";
import ClusterFilter from "../../enums/clusterFilter";
import { getChartColor } from "../../helper/color";
import { CustomLegend } from "./CustomLegend";
import { BusyIndicator } from "../BusyIndicator.jsx";

const MainGraph = props => {
    const { translate } = useTranslation();
    const { trigger }   = useTrigger();
    const { selectedClusters, isBusy } = useBranch({
        selectedClusters: ["selectedClusters"],
        isBusy:           ["counter", "monitor"]
    });
    const items = selectedClusters.map((elem, index) => ({
        ...elem,
        name:  translate(elem.name),
        color: getChartColor(index)
    }));
    const data = Object.keys(props.data).map(elem => ({
        name: elem,
        ...props.data[elem].reduce((dest, x) => ({
            ...dest,
            from:                              x.from,
            until:                             x.until,
            [`processed-${x.clusterId}`]:      x.processed,
            [`duration-${x.clusterId}`]:       x.duration,
            [`processingTime-${x.clusterId}`]: x.processingTime
        }), {})
    }));

    const onClick = e => {
        if(!e || !e.activePayload || !e.activePayload[0] || !e.activePayload[0].payload) return;

        const from  = new Date(e.activePayload[0].payload.from);
        const until = new Date(e.activePayload[0].payload.until);

        if(!from || !until) return;

        const selector = DatePicker.of([from, until]);

        trigger("onCalendarSelect", selector);
    };

    return (
        <div className="main-graph">
            {
                isBusy > 0 &&
                <div className="local-indicator">
                    <BusyIndicator/>
                </div>
            }
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    onClick={onClick}
                    width={900}
                    height={550}
                    data={data}
                    margin={{
                        top: 40, right: 0, bottom: 20, left: -10
                    }}
                >
                    <CartesianGrid stroke="#d7d7d7" strokeDasharray="5 5" horizontalFill={["#d7d7d7"]} vertical={false} fillOpacity={0.2} />
                    <XAxis dataKey="name" axisLine={false}/>
                    <YAxis yAxisId="left" axisLine={false}/>
                    <YAxis yAxisId="right" orientation='right' width={70} axisLine={false}/>
                    {
                        items.length > 0 &&
                        <Tooltip content={<CombinedTooltip locale={props.locale}/>}/>
                    }
                    <Legend className="legend" content={<CustomLegend/>} onClick={x => x}/>
                    {
                        items.map(elem => (
                            <Bar key={`processed-${elem.id}`} yAxisId="left" name={elem.name} dataKey={`processed-${elem.id}`} barSize={20} fill={elem.color} fillOpacity={0.7}/>
                        ))
                    }
                    {
                        items.map(elem => (elem.isSequentiallyOrdered || elem.plantElements?.length === 1) && (
                            <Line key={`duration-${elem.id}`} legendType="triangle" yAxisId="right" type="monotone" dataKey={`duration-${elem.id}`} stroke={elem.color} name={translate(ClusterFilter.durchlaufzeit)}/>
                        ))
                    }
                    {
                        items.map(elem => (elem.isSequentiallyOrdered || elem.plantElements?.length === 1) && (
                            <Line key={`processingTime-${elem.id}`} legendType="circle" yAxisId="right" type="monotone" strokeDasharray="3 3" dataKey={`processingTime-${elem.id}`} name={translate(ClusterFilter.processTime)} stroke={elem.color}/>
                        ))
                    }
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

MainGraph.propTypes = {
    data:   PropTypes.object,
    locale: PropTypes.string
};

MainGraph.defaultProps = {
    data: {}
};

export { MainGraph };
