import { useBranch, useTranslation } from "@circle/gestalt-app";
import { PlantsDropdown } from "../PlantsDropdown";
import { Filter } from "../Filter";
import { Timer } from "../Timer";

const Header = () => {
    const { translate }        = useTranslation();
    const { selected, shifts } = useBranch({
        selected: ["selectedPlant"],
        shifts:   ["currentWorkingShifts"]
    });
    const shift     = shifts.find(x => x.plants_id === selected?.id);
    const shiftName = shift ? translate(shift.title) : null;

    return (
        <>
            <PlantsDropdown />
            <Filter selectedPlant={selected}/>
            <Timer shift={shiftName}/>
        </>
    );
};

export { Header };
