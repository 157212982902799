import PropTypes from "prop-types";
import { DashboardClusterWidget } from "./DashboardClusterWidget";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Icon } from "@circle/kip-components";

const ProductsOverview = props => {
    const { trigger } = useTrigger();
    const { translate, currentLanguage } = useTranslation();
    const { ordering } = useBranch({
        ordering: ["ordering"]
    });
    const hasValue = props.data?.products.length > 0;

    return (
        <DashboardClusterWidget header={translate("dashboard.widgets.produced")}>
            {
                !hasValue &&
                <span>{translate("data.none")}</span>
            }
            {
                hasValue &&
                <table className="cluster-table products-table">
                    <thead>
                        <tr>
                            <th onClick={() => trigger("setOrdering", "product") }>
                                <b>
                                    { translate("dashboard.widgets.produced.type") }
                                    { ordering.column === "product" && <Icon _icon={`Arrow${ordering.order === "asc" ? "Up" : "Down"}`}/>}
                                </b>
                            </th>
                            <th>
                                <b onClick={() => trigger("setOrdering", "amount") }>
                                    { translate("dashboard.widgets.produced.amount") }
                                    { ordering.column === "amount" && <Icon _icon={`Arrow${ordering.order === "asc" ? "Up" : "Down"}`}/>}
                                </b>
                            </th>
                            {
                                props.showTimes &&
                                <th>
                                    <b onClick={() => trigger("setOrdering", "time") }>
                                        { translate("dashboard.widgets.produced.time") }
                                        { ordering.column === "time" && <Icon _icon={`Arrow${ordering.order === "asc" ? "Up" : "Down"}`}/>}
                                    </b>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data?.products.map((item, i) => (
                                <tr key={i}>
                                    <td>{ translate(item.product) }</td>
                                    <td><b>{ Number(item.amount).toLocaleString(currentLanguage) }</b></td>
                                    { props.showTimes && <td><b>{ Number(item.time).toLocaleString(currentLanguage) }s</b></td> }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
        </DashboardClusterWidget>
    );
};

ProductsOverview.propTypes = {
    data:      PropTypes.object,
    showTimes: PropTypes.bool
};

export { ProductsOverview };
