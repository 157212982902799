import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation, useTrigger } from "@circle/gestalt-app";
import { Button, Card, CardHeader, Icon, OverflowContainer } from "@circle/kip-components";
import styles from "./overview.module.scss";

const OverviewItem = props => { // eslint-disable-line complexity
    const { trigger }      = useTrigger();
    const { translate }    = useTranslation();
    const currentParts     = isNaN(props.item.parts.current) ? "-" : Number(props.item.parts.current).toLocaleString(props.locale);
    const targetParts      = isNaN(props.item.parts.target) ? "-" : Number(props.item.parts.target).toLocaleString(props.locale);
    const currentTimeParts = isNaN(props.item.partsPerTime.current) ? "-" : Number(props.item.partsPerTime.current).toLocaleString(props.locale);
    const targetTimeParts  = isNaN(props.item.partsPerTime.target) ? "-" : Number(props.item.partsPerTime.target).toLocaleString(props.locale);

    const onClick = e => {
        e.stopPropagation();

        props.onPlusClick();
    };

    useEffect(() => {
        trigger("fetchOverview", { plantId: props.item.id });

        const interval = setInterval(() => trigger("fetchOverview", { plantId: props.item.id }), 30000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Card className={styles.item} onClick={props.onClick}>
            <CardHeader className={styles.header}>
                <img src={props.item.image ?? props.item.alternativePicture} loading="lazy" alt="Logo" className="bitmap" />
                <div className={styles.titles}>
                    <h2>
                        {
                            (translate(props.item.name) || "").length > 24 ?
                                `${translate(props.item.name).substring(0, 24)}...` :
                                translate(props.item.name)
                        }
                    </h2>
                    <h3>
                        <span>{translate(props.item.location)}</span>
                    </h3>
                </div>
            </CardHeader>

            <OverflowContainer className={styles.content}>
                { !props.item.showParts && <>
                    <span className={styles.label}>
                        {translate("shifts.name")}
                    </span>
                    <span className={styles.value} style={{ gridColumn: "2/-1" }}>
                        {translate(props.item.shift?.title ?? "last8Hours")}
                    </span>
                    <span className={styles.label}>{translate("data.none.missing_plantreference")}</span>
                    <span className={styles.label} style={{ gridColumn: "2/-1" }}></span>
                    <span className={styles.label}></span>
                </> }
                { props.item.showParts && <>
                    <span className={styles.label}>
                        {translate("shifts.name")}
                    </span>
                    <span className={styles.value} style={{ gridColumn: "2/-1" }}>
                        {translate(props.item.shift?.title ?? "last8Hours")}
                    </span>
                    <span className={styles.label}>
                        {translate("overview.tile.should")}
                    </span>
                    <span className={styles.value}>
                        {`${currentParts}/${targetParts}`}
                    </span>
                    <span className={styles.label}>{translate("tile.unit")}</span>
                    <span className={styles.label}>
                        {translate("overview.tile.performance")}
                    </span>
                    <span className={styles.value}>
                        {`${currentTimeParts}/${targetTimeParts}`}
                    </span>
                    <span className={styles.label}>{translate("overview.tile.unit.hour")}</span>
                </>}
                <Button onClick={onClick}>
                    <Icon className="mr-2" _icon="Add" />
                    {translate("overview.tile.event")}
                </Button>
            </OverflowContainer>
        </Card>
    );
};

OverviewItem.propTypes = {
    item:        PropTypes.object.isRequired,
    onClick:     PropTypes.func.isRequired,
    onPlusClick: PropTypes.func.isRequired,
    locale:      PropTypes.string
};

export { OverviewItem };
