import PropTypes from "prop-types";
import { useBranch, useTrigger } from "@circle/gestalt-app";
import { MainGraph } from "./MainGraph";
import { DashboardGraph } from "../dashboard/DashboardGraph";
import { HistoryCluster } from "./HistoryCluster";
import { useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";

const History = props => {
    const location = useLocation();
    const params   = useParams();
    const { trigger } = useTrigger();
    const { history, selectedClusters, clusterData } = useBranch({
        history:          ["dataHistory"],
        selectedClusters: ["selectedClusters"],
        clusterData:      ["joinedClusters"]
    });

    const item     = clusterData.find(x => x.id === params.clusterId);
    const clusters = item ? [item] : selectedClusters;

    const toggleActive = (...x) => {
        if(!params.clusterId) return;

        props.toggleActive(...x);
    };

    useEffect(() => {
        trigger("onHistoryPlantSelect", params.plantId, props.filter);
    }, []);

    return (
        <>
            {
                !location.pathname.includes("/dashboard") &&
                <>
                    {
                        !params.clusterId &&
                        <MainGraph data={history.monitor} />
                    }
                    {
                        params.clusterId &&
                        <DashboardGraph
                            data={history.monitor}
                            active={props.active}
                            toggleActive={toggleActive}
                            showFault={Boolean(params.clusterId)}
                            showTimes={clusters[0]?.plantElements.length === 1 || clusters[0]?.isSequentiallyOrdered}
                        />
                    }
                </>
            }
            <div className="history-clusters m-40">
                {
                    clusters.map(cluster => (
                        <HistoryCluster
                            cluster={cluster}
                            key={cluster.id || cluster.name}
                        />
                    ))
                }
            </div>
        </>
    );
};

History.propTypes = {
    active:       PropTypes.arrayOf(PropTypes.string),
    filter:       PropTypes.object,
    toggleActive: PropTypes.func
};

export { History };
