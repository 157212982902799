import { useBranch, useTrigger } from "@circle/gestalt-app";
import { DashboardCluster } from "./DashboardCluster";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

const Dashboard = props => {
    const params = useParams();
    const { trigger } = useTrigger();
    const { selectedClusters } = useBranch({
        selectedClusters: ["selectedClusters"]
    });

    useEffect(() => {
        trigger("onDashboardPlantSelect", params.plantId, props.filter);
    }, []);

    return (
        <div className="dashboard-clusters-container">
            <div className="dashboard-clusters">
                {
                    selectedClusters.map(cluster => (
                        <DashboardCluster
                            cluster={cluster}
                            key={cluster.id || cluster.name}
                        />
                    ))
                }

            </div>
        </div>
    );
};

Dashboard.propTypes = {
    filter: PropTypes.object
};

export { Dashboard };

