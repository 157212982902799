import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { Column } from "./Column";
import { Row } from "./Row";
import { Modal, Button } from "@circle/kip-components";

const Datatable = props => {
    const [clickedId, setClickedId] = useState(null);
    const [modal, setModal]         = useState(false);
    const { translate } = useTranslation();

    const columns      = props.children.filter(elem => elem.type.prototype === Column.prototype);
    const filtered     = columns.filter(x => !x.props.hidden);
    const columnWidths = filtered.map(elem => props.hasActionsColumn || props.isEditable ? `${parseInt(elem.props.width, 10) - 10 / props.children.length}%` : elem.props.width);

    const onHeadlineClick = elem => {
        if(!elem.props.sortable) return;

        props.onHeadlineClick(elem.props.filter || elem.props.item);
    };

    const closeModal = () => {
        setModal(false);
        setClickedId(null);
    };

    const onDeleteClick = id => {
        setClickedId(id);
        setModal(true);
    };

    const onDelete = () => {
        props.onDelete(clickedId);
        closeModal();
    };

    const onEditClick = (elem, e) => {
        e.stopPropagation();

        props.onEditClick(elem);
    };

    return (
        <div className={`${props.className} briddle font-bold`}>
            <table>
                <thead>
                    <tr>
                        <th className="placeholder"/>
                        {
                            filtered.map((elem, idx) => (
                                <th
                                    className={`grey-colored font-medium ${elem.props.sortable ? "sortable clickable" : ""}`}
                                    key={idx}
                                    style={{ width: columnWidths[idx] }}
                                    onClick={() => onHeadlineClick(elem)}
                                >
                                    { (translate(elem.props.title)).toUpperCase() }
                                </th>
                            ))
                        }
                        {
                            (props.hasActionsColumn || props.isEditable) &&
                            <th className="placeholder" style={{ width: "10%" }}/>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.content.length === 0 &&
                        <tr className="empty">
                            <td colSpan={props.children.length + 1}>{ translate("datatable.empty") }</td>
                        </tr>
                    }
                    {
                        props.content.map((elem, idx) => (
                            <Row
                                isEditable={props.isEditable}
                                hasActionsColumn={elem.hasActionsColumn !== undefined ? elem.hasActionsColumn : props.hasActionsColumn} // eslint-disable-line no-undefined
                                onDelete={() => onDeleteClick(elem.id)}
                                index={idx}
                                key={idx}
                                content={elem}
                                translate={translate}
                                active={!props.isMultiSelect ? props.selected === elem.id : props.selected?.includes(elem.id)}
                                onClick={e => props.onRowClick(elem, e)}
                                columnWidths={columnWidths}
                                onEdit={e => onEditClick(elem, e)}>
                                { filtered }
                            </Row>
                        ))
                    }
                </tbody>
            </table>
            <Modal
                isOpen={modal}
                isCloseable={false}
                onClose={() => setModal(false)}
                title={translate("modal.delete.danger")}
            >
                <div className="flex-column">
                    { translate("modal.delete.really").split("\n").map((elem, key) => (
                        <span key={key} className="font-bold mt-3 center">{ elem }</span>
                    )) }
                    <div className="flex mt-5 center">
                        <Button _appearance="primary" onClick={() => onDelete(clickedId)}>{translate("actions.delete")}</Button>
                        <Button onClick={() => setModal(false)}>{translate("actions.cancel")}</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

Datatable.defaultProps = {
    onRowClick:      x => x,
    onDelete:        x => x,
    onHeadlineClick: x => x,
    onEditClick:     x => x
};

Datatable.propTypes = {
    children:         PropTypes.node,
    content:          PropTypes.arrayOf(PropTypes.object),
    isEditable:       PropTypes.bool,
    onDelete:         PropTypes.func,
    hasActionsColumn: PropTypes.bool,
    onRowClick:       PropTypes.func,
    onHeadlineClick:  PropTypes.func,
    className:        PropTypes.string,
    onEditClick:      PropTypes.func,
    selected:         PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    isMultiSelect:    PropTypes.bool
};

export { Datatable };
