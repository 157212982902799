interface IDatePicker {
    from: Date,
    until: Date,
    name: string
}

const last7Days = () => {
    const currentDate = new Date();

    currentDate.setUTCDate(currentDate.getUTCDate());

    return {
        from:  new Date(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setUTCHours(0, 0, 0, 0)),
        until: new Date(currentDate.setUTCHours(23, 59, 59, 999)),
        name:  "last7Days"
    };
};

const today = () => {
    const currentDate = new Date();

    return {
        from:  new Date(new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate() + 1).setUTCHours(0, 0, 0, 0)),
        until: new Date(new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate() + 1).setUTCHours(23, 59, 59, 999)),
        name:  "today"
    };
};

const thisMonth = () => {
    const currentDate = new Date();

    return {
        from:  new Date(new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 2).setUTCHours(0, 0, 0, 0)),
        until: new Date(new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 1).setUTCHours(23, 59, 59, 999)),
        name:  "thisMonth"
    };
};

const thisYear = () => {
    const currentDate = new Date();

    return {
        from:  new Date(new Date(currentDate.getUTCFullYear(), 0, 2).setUTCHours(0, 0, 0, 0)),
        until: new Date(new Date(currentDate.getUTCFullYear() + 1, 0, 1).setUTCHours(23, 59, 59, 999)),
        name:  "thisYear"
    };
};

const overall = () => {
    const year = new Date().getUTCFullYear();

    return {
        from:  new Date(new Date("2020-01-01").setUTCHours(0, 0, 0, 0)),
        until: new Date(new Date(`${year}-12-31`).setUTCHours(23, 59, 59, 999)),
        name:  "overall"
    };
};

const isEqual = (timestamp1: number[], timestamp2: number[]) => {
    return timestamp1.reduce((dest, elem, idx) => dest && elem === timestamp2[idx], true);
};


const getName = (timestamp: number[]) => {
    const _today     = today();
    const _last7Days = last7Days();
    const _thisMonth = thisMonth();
    const _thisYear  = thisYear();
    const _overall   = overall();

    if(isEqual(timestamp, [_today.from.getTime(), _today.until.getTime()])) return "today";
    if(isEqual(timestamp, [_last7Days.from.getTime(), _last7Days.until.getTime()])) return "last7Days";
    if(isEqual(timestamp, [_thisMonth.from.getTime(), _thisMonth.until.getTime()])) return "thisMonth";
    if(isEqual(timestamp, [_thisYear.from.getTime(), _thisYear.until.getTime()])) return "thisYear";
    if(isEqual(timestamp, [_overall.from.getTime(), _overall.until.getTime()])) return "overall";

    return "custom";
};

const getCustomTime = (name: Date[]) => {
    const from      = new Date(name[0].getTime());
    const until     = new Date(name[1].getTime());

    return {
        from:  from,
        until: until,
        name:  getName([from.getTime(), until.getTime()])
    };
};

const callFunction: { [K: string]: () => IDatePicker } = {
    last7Days,
    today,
    thisMonth,
    thisYear,
    overall
};

export class DatePicker {
    static of(name: string | Array<Date>) {
        if(name instanceof Array) return getCustomTime(name);

        if(callFunction[name]) return callFunction[name]();

        throw new Error(`${name} is not a valid DatePicker value. Choose 'last7Days', 'today', 'thisMonth', 'thisYear' or 'overall'`);
    }
}
