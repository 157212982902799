import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useBranch, useTrigger, useTranslation } from "@circle/gestalt-app";
import { BusyIndicator } from "./BusyIndicator.jsx";
import { Icon, Sidebar, SidebarButton } from "@circle/kip-components";
import { sidebarButtonHighlightClassName } from "@circle/kip-components";
import { resolveClassNames } from "palstek";
import styles from "./menu.module.scss";

const Menu = () => {
    const { trigger } = useTrigger();
    const { translate } = useTranslation();
    const { globalBusy, selected } = useBranch({
        globalBusy: ["counter", "global"],
        selected:   ["selectedPlant"]
    });
    const onClick = (e, path) => {
        const isActive = window.location.pathname.includes(path);

        if(isActive) return e.preventDefault();

        return trigger("reset");
    };

    return (
        <Sidebar className={styles.sidebar}>
            <NavLink id="overview" to="/overview" className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, styles.menuItem)} onClick={e => onClick(e, window.location.pathname.includes("/overview"))} rel="noopener noreferrer">
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Overview"/>
                    <Tooltip anchorSelect="#overview" effect="solid" place="right" content={translate("menu.overview.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }}/>
                </SidebarButton>
            </NavLink>
            <NavLink id="dashboard-view" to={`/dashboard${selected ? `/${selected.id}` : ""}`} className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, styles.menuItem)} onClick={e => onClick(e, window.location.pathname.includes("/dashboard"))} rel="noopener noreferrer">
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Dashboard"/>
                    <Tooltip anchorSelect="#dashboard-view" effect="solid" place="right" content={translate("menu.monitor.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }}/>
                </SidebarButton>
            </NavLink>
            <NavLink id="history-view" to={`/history${selected ? `/${selected.id}` : ""}`} className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, styles.menuItem)} onClick={e => onClick(e, window.location.pathname.includes("/history"))} rel="noopener noreferrer">
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Analysis"/>
                    <Tooltip anchorSelect="#history-view" effect="solid" place="right" content={translate("menu.history.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }}/>
                </SidebarButton>
            </NavLink>
            <NavLink id="settings-view" to="/admin" className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, styles.menuItem)} onClick={e => onClick(e, window.location.pathname.includes("/admin"))} rel="noopener noreferrer" >
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Settings"/>
                    <Tooltip anchorSelect="#settings-view" effect="solid" place="right" content={translate("menu.settings.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }}/>
                </SidebarButton>
            </NavLink>
            {
                globalBusy ? <BusyIndicator withContainer /> : <></>
            }
        </Sidebar>
    );
};

export { Menu };
