import { useState, Fragment } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ClusterSelector } from "./history/ClusterSelector";
import { DashboardSelector } from "./dashboard/DashboardSelector";
import { HistoryWrapper } from "./history/HistoryWrapper";
import { DashboardWrapper } from "./dashboard/DashboardWrapper";

const GraphicalContent = () => {
    const location            = useLocation();
    const [active, setActive] = useState(["fault", "durchlaufzeit", "processTime", "teile"]);

    const toggleActive = value => {
        if(!value) return setActive([]);

        return setActive(active.includes(value) ? active.filter(x => x !== value) : active.concat(value));
    };

    return (
        <div className="dashboard-content flex-grow">
            <Routes>
                <Route path="*" element={<ClusterSelector/>}/>
                <Route path=":plantId/cluster/*" element={<DashboardSelector active={active} onSelect={toggleActive}/>}/>
            </Routes>
            {
                !location.pathname.includes("/dashboard") &&
                <Routes>
                    <Route path=":plantId/cluster/:clusterId" element={<HistoryWrapper active={active} toggleActive={toggleActive}/>}/>
                    <Route path=":plantId/*" element={<HistoryWrapper active={active} toggleActive={toggleActive}/>}/>
                    <Route path="*" element={<Fragment/>}/>
                </Routes>
            }
            {
                location.pathname.includes("/dashboard") &&
                <Routes>
                    <Route path=":plantId/*" element={<DashboardWrapper/>}/>
                    <Route path="*" element={<Fragment/>}/>
                </Routes>
            }
        </div>
    );
};

export { GraphicalContent };
