import { useState, useEffect } from "react";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { Checkbox } from "../generic/Checkbox";
import system from "../../enums/system";
import cluster from "../../enums/cluster";
import { FormActions } from "../generic/FormActions";
import md5 from "md5";
import { hasValue, validateTranslation } from "../../helper/form";
import { TextInput, Modal } from "@circle/kip-components";

const validate = (languages, content) => ({
    isValid: validateTranslation(languages, content.name)
});

const validateRequired = (language, content) => {
    return hasValue(language, content.name);
};

const DashboardForm = props => { // eslint-disable-line complexity
    const { translate } = useTranslation();
    const { language, languages } = useBranch({
        languages: ["allLanguages"],
        language:  ["language"]
    });
    const init = {
        id:               props.id || null,
        name:             props.name || [],
        createdtAt:       props.createdAt || null,
        updatedAt:        props.updatedAt || null,
        isOverallSystem:  props.isOverallSystem || false,
        isDisplayTarget:  props.isDisplayTarget || false,
        isDisplaySystem:  props.isDisplaySystem || false,
        isShowProduct:    props.isShowProduct || false,
        isShowEvents:     props.isShowEvents || false,
        isShowdiagram:    props.isShowdiagram || false,
        isClusterTarget:  props.isClusterTarget || false,
        isClusterSystem:  props.isClusterSystem || false,
        isClusterProduct: props.isClusterProduct || false,
        isClusterEvents:  props.isClusterEvents || false
    };

    const [modal, setModal]     = useState(false);
    const [valid, setValid]     = useState({});
    const [content, setContent] = useState(init);
    const [hash]                = useState(md5(JSON.stringify(init)));

    useEffect(() => {
        const required  = validateRequired(language, content);
        const validated = validate(languages, content);
        const overall   = required && validated.isValid;

        setValid({
            required,
            overall
        });
    }, [language, content, languages]);

    const onSubmit = () => {
        if(!valid.required) return setModal(true);
        if(!valid.overall)  return null;

        return props.onSubmit(content);
    };

    const onChange = (key, value) => {
        const values = {
            ...content,
            [key]: value
        };
        const currentHash = md5(JSON.stringify(values));

        setContent(values);

        props.onChange(key, values[key], currentHash !== hash);
    };

    const onTranslationChange = (key, value) => {
        const current = content[key] ? [...content[key]] : [];
        const index   = current.findIndex(elem => elem.language === language);
        const item    = current[index];

        if(!item) return onChange(key, current.concat({
            language: language,
            content:  value
        }));

        current.splice(index, 1, {
            ...item,
            content: value
        });

        return onChange(key, current);
    };

    const onCheckmark = key => {
        if(key === "isOverallSystem" && !content.isOverallSystem) Object.keys(system).map(x => onChange(x, !content[x]));

        onChange(key, !content[key]);
    };

    return (
        <>

            <div>
                <div className="form">
                    <span className="header font-bold">{ translate("sidebar.headline").toUpperCase() }</span>
                    <div className="flex form-row">
                        <div className="form-column flex-column flex-grow">
                            <span className="form-header font-bold">
                                { translate("name.headline") }*
                            </span>
                            <TextInput
                                value={translate(content.name, language)}
                                onChange={e => onTranslationChange("name", e.target.value)}
                                placeholder={translate("name.headline")}
                            />
                        </div>
                    </div>
                    <div className="flex-column flex-grow mt-4">
                        {
                            Object.keys(cluster).map(key =>
                                <div className="flex ml-2" key={key}>
                                    <Checkbox checked={content[key]} onChange={() => onCheckmark(key, !content[key])}>
                                        { translate(cluster[key]) }
                                    </Checkbox>
                                </div>
                            )
                        }
                    </div>
                </div>
                <Modal
                    title={translate("modal.form.title")}
                    isOpen={modal}
                    isCloseable={false}
                    onClose={() => setModal(false)}
                >
                    <div className="flex-column">
                        <span className="font-bold center font-3 mt-3">{ translate("modal.info") }</span>
                        {
                            translate("modal.required_missing").split("\n").map((elem, key) => (
                                <span key={key} className="font-bold mt-3 center">{ elem }</span>
                            ))
                        }
                        <div className="flex mt-5 center">
                            <div className="button clickable center" onClick={() => setModal(false)}>
                                <span>{ translate("actions.ok") }</span>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <FormActions
                onCancel={props.onCancel}
                onSubmit={onSubmit}
                isValid={valid}
                validationText={ "actions.notValid" }
            />
        </>
    );
};

DashboardForm.propTypes = {
    id:               PropTypes.string,
    name:             PropTypes.arrayOf(PropTypes.object),
    createdAt:        PropTypes.string,
    updatedAt:        PropTypes.string,
    isOverallSystem:  PropTypes.bool,
    isDisplayTarget:  PropTypes.bool,
    isDisplaySystem:  PropTypes.bool,
    isShowProduct:    PropTypes.bool,
    isShowEvents:     PropTypes.bool,
    isShowdiagram:    PropTypes.bool,
    isClusterTarget:  PropTypes.bool,
    isClusterSystem:  PropTypes.bool,
    isClusterProduct: PropTypes.bool,
    isClusterEvents:  PropTypes.bool,
    language:         PropTypes.string,
    languages:        PropTypes.arrayOf(PropTypes.object),
    onChange:         PropTypes.func,
    onCancel:         PropTypes.func,
    onSubmit:         PropTypes.func,
    trigger:          PropTypes.func
};

DashboardForm.defaultProps = {
    onChange: x => x,
    onCancel: x => x,
    onSubmit: x => x
};

export { DashboardForm };
