import { Fragment } from "react";
import { useBranch, useTrigger } from "@circle/gestalt-app";
import { DropDownSelect, Icon } from "@circle/kip-components";
import styles from "./languageToggle.module.scss";

const LanguageToggle = () => {
    const { trigger } = useTrigger();
    const { language, languages } = useBranch({
        language:  ["language"],
        languages: ["languages"]
    });

    return (
        <DropDownSelect
            closeOnOptionClick={true}
            className={styles.langbox}
            options={languages?.map(lang => ({
                value:   lang.value,
                label:   <Fragment key={lang.value}><Icon style={{ fontSize: "1.5em", marginRight: "0.5em" }} _icon={lang?.value === "de" ? "FlagGermany" : "FlagBritain"} />{lang.label}</Fragment>,
                checked: language === lang.value
            }))}
            isMulti={false}
            isEmptyValueAllowed={false}
            onChange={opts => trigger("changeLang", opts.find(x => x.checked).value)}
        />
    );
};

export { LanguageToggle };
