import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { CustomTooltip } from "./Tooltip";

const ProductsGraph = props => {
    const { translate } = useTranslation();

    return (
        <div className="main-graph">
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={900}
                    height={250}
                    data={props.data}
                    margin={{
                        top: 40, right: 0, bottom: 20, left: -10
                    }}
                >
                    <CartesianGrid stroke="#d7d7d7" strokeDasharray="5 5" horizontalFill={["#d7d7d7"]} vertical={false} fillOpacity={0.2} />
                    <XAxis dataKey="name" axisLine={false}/>
                    <YAxis axisLine={false}/>
                    <Tooltip content={<CustomTooltip />}/>
                    <Legend/>
                    <Bar dataKey="product" name={translate("dashboard.widgets.produced.amount")} barSize={20} fill="#5694F2" hide={false} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

ProductsGraph.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
};

export { ProductsGraph };
