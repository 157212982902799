interface IEntry {
    hour: number,
    target: number,
    actual: number,
    actualPerHour: number
}

// eslint-disable-next-line id-length
export class ProductivityDataEntry implements IEntry {
    constructor(hour: number, target: string, actual: string, actualPerHour: string) {
        this.hour          = hour;
        this.target        = parseFloat(target);
        this.actual        = parseInt(actual, 10);
        this.actualPerHour = parseFloat(actualPerHour);
    }
    hour: number;
    target: number;
    actual: number;
    actualPerHour: number;
}

export class CombinedWidgetData {
    constructor(chartData: object) {
        if(!Array.isArray(chartData))
            throw new Error("Wrong format creating CombinedWidgetData");

        this.chartData = chartData;
    }
    chartData: object;

    accessors = {
        getHour:          (x: IEntry) => x.hour,
        getTarget:        (x: IEntry) => x.target,
        getActualPerHour: (x: IEntry) => x.actualPerHour
    };
}
