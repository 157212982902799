import { useState } from "react";
import PropTypes from "prop-types";
import { DashboardClusterWidget } from "./DashboardClusterWidget";
import { Pie } from "@nivo/pie";
import { useTranslation } from "@circle/gestalt-app";
import StatesIcons from "../../enums/statesIcons";
import { Icon } from "@circle/kip-components";

const States = props => {
    const { translate, currentLanguage }  = useTranslation();
    const [height, setHeight]             = useState(200);
    const [width, setWidth]               = useState(200);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const data = !props.data ? [] : [...props.data.data].filter(x => x.value > 0).map(({ id, ...rest }) => ({ ...rest })); // eslint-disable-line @typescript-eslint/no-unused-vars
    const hasValue = props.data?.data.length > 0;

    const onFullScreen = mode => {
        const size = mode === "open" ? 500 : 200;

        setHeight(size);
        setWidth(size);
        setIsFullScreen(mode === "open");
    };

    return (
        <DashboardClusterWidget header={translate("dashboard.widgets.states")} centered={hasValue} onFullScreen={onFullScreen}>
            {
                !hasValue &&
                <span>{translate("data.none")}</span>
            }
            {
                hasValue &&
                <div className={isFullScreen ? "flex center" : "flex flex-grow"}>
                    <Pie data={data} height={height} width={width}
                        animate={false}
                        motionConfig="stiff"
                        innerRadius={0.5}
                        padAngle={3}
                        startAngle={-0}
                        cornerRadius={5}
                        borderWidth={3}
                        borderColor={{ from: "color", modifiers: [["darker", "0.2"]] }}
                        translate={(85, 80)}
                        enableArcLinkLabels={false}
                        enableArcLabels={false}
                        margin={{ top: 0, bottom: 0, left: 10, right: 10 }}
                        colors={{ datum: "data.color" }}
                        tooltip={x => <span className="charts-tooltip">{x.datum.value}%</span>}
                        theme={{ fontSize: "13px", textColor: "#000" }}
                    />
                    <div className={`pie-legend ${isFullScreen ? "" : "flex-grow"}`}>
                        {
                            props.data.data.filter(x => Number(x.value) !== 0).map((x, i) => (
                                <span className="pie-legend-entry" key={i}>
                                    {translate(x.label)}{": "}
                                    <b>{Number(x.value).toLocaleString(currentLanguage)}%</b>
                                    {
                                        x.label === "dashboard.widgets.states.undefined" &&
                                        <span style={{ background: x.color }} ></span>
                                    }
                                    {
                                        x.label !== "dashboard.widgets.states.undefined" &&
                                        <Icon _icon={StatesIcons[x.label]} style={{
                                            color:      x.color,
                                            marginLeft: "0.5rem",
                                            transform:  "scale(1.1)",
                                            filter:     "drop-shadow(0 0 1px rgba(0,0,0,1))" }} />
                                    }
                                </span>)
                            )
                        }
                    </div>
                </div>
            }
        </DashboardClusterWidget>
    );
};

States.propTypes = {
    data: PropTypes.object
};

export { States };
