import PropTypes from "prop-types";

const getUnit = (units, key) => {
    if(units[key]) return units[key];
    if(key.startsWith("duration") || key.startsWith("processing")) return "s";
    if(key.startsWith("fault"))                                    return "min";

    return "";
};

const CombinedTooltip = ({ active, payload, label, labels = {}, units = {}, locale }) => {
    const names = [...new Set((payload ?? []).map(x => x.name))];

    return (
        <>
            {
                active &&
                <div className="custom-tooltip">
                    <p className="tooltip-label">{`${label}`}</p>
                    {
                        names.map(x => (
                            <p key={x} className="tooltip-block">
                                <span className="tooltip-icon"> {labels[x] ?? x}: </span>
                                {
                                    payload.filter(elem => elem.name === x).map(elem => (
                                        <span key={elem.dataKey} style={{ color: elem.color, marginRight: 5 }}>{ Number(elem.payload[elem.dataKey]).toLocaleString(locale)}{ getUnit(units, elem.dataKey) }</span>
                                    ))
                                }
                            </p>
                        ))
                    }
                </div>
            }
        </>
    );
};

CombinedTooltip.propTypes = {
    active:  PropTypes.bool,
    payload: PropTypes.arrayOf(PropTypes.object),
    label:   PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    labels: PropTypes.object,
    units:  PropTypes.object,
    locale: PropTypes.string
};

export { CombinedTooltip };
