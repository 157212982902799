import { useState, useEffect } from "react";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { Checkbox } from "../generic/Checkbox";
import { FormActions } from "../generic/FormActions";
import { hasValue, validateTranslation, elemToValue } from "../../helper/form";
import md5 from "md5";
import { TextInput, DropDownSelect, Modal, ComboBox } from "@circle/kip-components";

const validate = (languages, content) => ({
    isValid: validateTranslation(languages, content.name)
});

const validateRequired = (language, content) => {
    if(!content.name) return null;
    return hasValue(language, content.name) &&
        content.plantElements.length > 0 &&
        (content.plantElements.length <= 1 || (content.measuringElement || !content.isSequentiallyOrdered));
};

const ClusterForm = props => { // eslint-disable-line complexity,max-statements
    const { translate } = useTranslation();
    const { allElements, languages, language } = useBranch({
        allElements: ["sortedPlantElements"],
        languages:   ["allLanguages"],
        language:    ["language"]
    });
    const init = {
        id:                    props.id || null,
        allElements:           allElements || [],
        name:                  props.name || [],
        createdAt:             props.createdAt,
        updatedAt:             props.updatedAt || null,
        plantId:               props.plantId || null,
        plantElements:         props.plantElements?.map(x => elemToValue({ translate, allElements }, x)) || [],
        isStatesSumup:         props.isStatesSumup || false,
        isSortedOutSumup:      props.isSortedOutSumup || false,
        measuringElement:      props.measuringElement,
        isSequentiallyOrdered: props.isSequentiallyOrdered || false // eslint-disable-line id-length
    };
    const [modal, setModal]     = useState(false);
    const [valid, setValid]     = useState({});
    const [content, setContent] = useState(init);
    const [hash]                = useState(md5(JSON.stringify(init)));

    const options = allElements
        ?.filter(x => x.plantId === props.plantId)
        ?.map(x => ({
            value:            translate(x.name),
            label:            translate(x.name),
            plant_element_id: x.id // eslint-disable-line camelcase
        }));
    const plantSelectValues = content.plantElements?.map(x => x.label);

    const measuringOptions = content.plantElements.map(x => ({
        label:   translate(allElements.find(elem => elem.id === x?.plant_element_id)?.name),
        value:   x?.plant_element_id,
        checked: content.measuringElement === x.plant_element_id
    })).reverse();

    useEffect(() => {
        const required  = validateRequired(language, content);
        const validated = validate(languages, content);
        const overall   = required && validated.isValid;

        setValid({
            required,
            overall
        });
    }, [language, content, languages]);

    const onSubmit = () => {
        if(!valid.required) return setModal(true);
        if(!valid.overall)  return null;

        const getMeasuringElement = () => {
            if(content.plantElements.length === 1) return content.plantElements[0].plant_element_id;
            if(content.isSequentiallyOrdered)      return content.measuringElement;

            return null;
        };

        if(content.plantElements.length === 1) return props.onSubmit({
            ...content,
            isSequentiallyOrdered: true, // eslint-disable-line id-length
            id:                    props.id,
            measuringElement:      getMeasuringElement()
        });

        return props.onSubmit({
            ...content,
            id:               props.id,
            measuringElement: getMeasuringElement()
        });
    };

    const onChange = (key, value) => {
        const values = {
            ...content,
            [key]: value
        };
        const currentHash = md5(JSON.stringify(values));

        setContent(values);

        props.onChange(key, values[key], currentHash !== hash);
    };

    const onTranslationChange = (key, value) => {
        const current = content[key] ? [...content[key]] : [];
        const index   = current.findIndex(elem => elem.language === language);
        const item    = current[index];

        if(!item) return onChange(key, current.concat({
            language: language,
            content:  value
        }));

        current.splice(index, 1, {
            ...item,
            content: value
        });

        return onChange(key, current);
    };

    const onElementsSelect = elems => {
        const items = elems
            .map(x => options.find(y => y.label === x))
            .map(x => ({
                ...x,
                plant_element_id: x.plant_element_id // eslint-disable-line camelcase
            }));

        return onChange("plantElements", items);
    };

    const onSelect = value => {
        const [selected] = value.filter(x => x.checked);

        onChange("measuringElement", selected.value);
    };

    return (
        <>
            <div>
                <div className="form">
                    <span className="header font-bold">{ translate("sidebar.headline").toUpperCase() }</span>
                    <div className="flex form-row">
                        <div className="form-column flex-column flex-grow">
                            <span className="form-header font-bold">
                                { translate("name.headline") }*
                            </span>
                            <TextInput
                                className="full-width"
                                value={translate(content.name, language)}
                                onChange={e => onTranslationChange("name", e.target.value)}
                                placeholder={translate("name.headline")}
                            />
                        </div>
                    </div>
                    <div className="flex form-row">
                        <div className="flex-column flex-grow">
                            <span className="form-header font-bold">
                                { translate("plant.ele.header") }*
                            </span>
                            <ComboBox
                                closeOnOptionClick={options.length <= 1}
                                className="full-width ele-select"
                                isMulti
                                options={options}
                                onChange={onElementsSelect}
                                value={plantSelectValues}
                                placeholder={translate("choose.elements")}
                            />
                        </div>
                    </div>
                    <div className="flex-column flex-grow">
                        <div className="flex mt-2 ml-2">
                            { content.plantElements.length > 1 && <Checkbox disabled={content.plantElements.length <= 1} checked={content.isStatesSumup} onChange={() => onChange("isStatesSumup", !content.isStatesSumup)}>
                                { translate("summary.state") }
                            </Checkbox>}
                        </div>
                        <div className="flex ml-2">
                            { content.plantElements.length > 1 && <Checkbox disabled={content.plantElements.length <= 1} checked={content.isSortedOutSumup} onChange={() => onChange("isSortedOutSumup", !content.isSortedOutSumup)}>
                                { translate("combine.state") }
                            </Checkbox>}
                        </div>
                        <div className="flex ml-2">
                            { content.plantElements.length > 1 && <Checkbox disabled={!content.plantElements.length} checked={content.isSequentiallyOrdered} onChange={() => onChange("isSequentiallyOrdered", !content.isSequentiallyOrdered)}>
                                { translate("cluster.sequentially_ordered") }
                            </Checkbox>}
                        </div>
                    </div>
                    {
                        (content.plantElements.length > 1 && content.isSequentiallyOrdered) &&
                    <div className="flex form-row">
                        <div className="form-column flex-column flex-grow">
                            <span className="form-header font-bold">
                                { translate("count.headline") }*
                            </span>
                            <DropDownSelect
                                className="full-width"
                                options={measuringOptions}
                                value={measuringOptions.find(x => x.checked)}
                                placeholder={translate("choose.element")}
                                closeOnOptionClick
                                isEmptyValueAllowed={false}
                                onChange={onSelect}
                            />
                        </div>
                    </div>
                    }
                </div>
                <Modal
                    title={translate("title.record.event")}
                    isOpen={modal}
                    isCloseable={false}
                    onClose={() => setModal(false)}
                >
                    <div className="flex-column">
                        <span className="font-bold center font-3 mt-3">{ translate("modal.info") }</span>
                        {
                            translate("modal.required_missing").split("\n").map((elem, key) => (
                                <span key={key} className="font-bold mt-3 center">{ elem }</span>
                            ))
                        }
                        <div className="flex mt-5 center">
                            <div className="button clickable center" onClick={() => setModal(false)}>{translate("actions.ok")}</div>
                        </div>
                    </div>
                </Modal>
            </div>
            <FormActions
                onCancel={props.onCancel}
                onSubmit={onSubmit}
                isValid={valid}
                validationText={ "actions.notValid" }
            />
        </>
    );
};

ClusterForm.propTypes = {
    id:                    PropTypes.string,
    name:                  PropTypes.arrayOf(PropTypes.object),
    plantElements:         PropTypes.arrayOf(PropTypes.object),
    plantId:               PropTypes.string,
    isStatesSumup:         PropTypes.bool,
    isSortedOutSumup:      PropTypes.bool,
    measuringElement:      PropTypes.string,
    onChange:              PropTypes.func,
    onCancel:              PropTypes.func,
    onSubmit:              PropTypes.func,
    createdAt:             PropTypes.string,
    updatedAt:             PropTypes.string,
    isSequentiallyOrdered: PropTypes.bool // eslint-disable-line id-length
};

ClusterForm.defaultProps = {
    onChange: x => x,
    onCancel: x => x,
    onSubmit: x => x
};

export { ClusterForm };
