import { useState } from "react";
import PropTypes from "prop-types";
import { FormActions } from "../generic/FormActions";
import md5 from "md5";
import { TextInput } from "@circle/kip-components";
import { useTranslation } from "@circle/gestalt-app";

const SettingsForm = props => {
    const { translate } = useTranslation();
    const init = {
        id:        props.id || null,
        name:      props.name || [],
        createdAt: props.createdAt,
        updatedAt: props.updatedAt || null,
        plantId:   props.plantId || null,
        clusterId: props.clusterId || null,
        targets:   props.targets || []
    };

    const [content, setContent] = useState(init);
    const [hash]                = useState(md5(JSON.stringify(init)));

    const onChange = (key, value) => {
        const values = {
            ...content,
            [key]: value
        };
        const currentHash = md5(JSON.stringify(values));

        setContent(values);

        props.onChange(key, values[key], currentHash !== hash);
    };

    const onTargetChange = (index, e) => {
        const newTargets = content.targets.concat([]);

        newTargets[index] = Object.assign({}, newTargets[index], {
            target: parseInt(e.target.value, 10)
        });

        onChange("targets", newTargets);
    };

    return (
        <>
            <div>
                <div className="form">
                    <span className="header font-bold">{ translate("sidebar.headline").toUpperCase() }</span>
                    <div className="flex form-row">
                        <div className="form-column flex-column flex-grow">
                            <span className="form-header font-bold">
                                { translate("name.headline") }
                            </span>
                            <TextInput
                                readOnly
                                value={translate(content.name)}
                                placeholder={translate("name.headline")}
                            />
                        </div>
                    </div>
                    <div className="flex form-row">
                        <div className="form-column flex-column flex-grow">
                            <span className="form-header font-bold">
                                { translate("target.headline") }
                            </span>
                        </div>
                    </div>
                    {
                        content.targets.map((x, key) =>
                            <div key={key} className="flex form-row">
                                <div className="flex flex-grow">
                                    <span className="form-header shift-label font-bold mt-2 mr-2">
                                        { translate(x.name) }
                                    </span>
                                    <TextInput
                                        className="target-input"
                                        value={x.target}
                                        onChange={e => onTargetChange(key, e)}
                                    />
                                    <span className="unit-label mt-2 ml-5">
                                        { translate("tile.unit") }
                                    </span>
                                </div>
                            </div>
                        )}
                </div>
            </div>
            <FormActions
                onCancel={props.onCancel}
                onSubmit={() => props.onSubmit({
                    ...content,
                    id: props.id
                })}
                isValid={({ overall: true, required: true })}
                validationText={ "actions.notValid" }
                hasLanguageToggle={false}
            />
        </>
    );
};

SettingsForm.propTypes = {
    id:        PropTypes.string,
    name:      PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.string]),
    plantId:   PropTypes.string,
    clusterId: PropTypes.string,
    targets:   PropTypes.arrayOf(PropTypes.object),
    onChange:  PropTypes.func,
    onCancel:  PropTypes.func,
    onSubmit:  PropTypes.func,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string
};

SettingsForm.defaultProps = {
    onChange: x => x,
    onCancel: x => x,
    onSubmit: x => x
};

export { SettingsForm };
