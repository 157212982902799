import { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "@circle/kip-components";

const Selector = props => {
    const ref      = useRef();
    const innerRef = useRef();

    const [overflow, setOverflow] = useState(true);

    const isEllipsisActive = useCallback(
        () => {
            return innerRef.current.scrollWidth > ref.current.scrollWidth;
        },
        []);

    const handleScroll = offset => {
        innerRef.current.scrollBy({ left: offset, behavior: "smooth" });
    };

    useEffect(() => {
        if(props.children) return setOverflow(isEllipsisActive());
        return setOverflow(false);
    }, [props.children, isEllipsisActive]);

    return (
        <div className="dashboard-header" ref={ref}>
            {
                overflow &&
                <Button _variant="icon" onClick={e => handleScroll(-100, e)} className="overflow-arrow arrow-left">
                    <Icon _icon="ChevronLeft" onClick={e => handleScroll(-100, e)} />
                </Button>
            }
            <div className="dashboard-cluster-checkboxes" ref={innerRef}>
                {
                    props.children
                }
            </div>
            {
                overflow &&
                <Button _variant="icon" onClick={e => handleScroll(100, e)} className="overflow-arrow arrow-right">
                    <Icon _icon="ChevronRight" onClick={e => handleScroll(100, e)} />
                </Button>
            }
        </div>
    );
};

Selector.propTypes = {
    children: PropTypes.node
};

export { Selector };
