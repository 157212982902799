import { useEffect, useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Datatable } from "../generic/Datatable";
import { Column } from "../generic/Column";
import { DashboardForm } from "./DashboardForm";
import { Drawer } from "@circle/kip-components";
import { useParams } from "react-router-dom";

const DashboardNav = () => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const params        = useParams();
    const [dashboard, setDashboard] = useState(null);
    const { dashboards, isSidebarOpen, options } = useBranch({
        dashboards:    ["sortedDashboards"],
        isSidebarOpen: ["isSidebarOpen"],
        options:       ["queryOptions"]
    });

    const onClose = () => {
        trigger("onSidebarToggle", false);

        setDashboard(null);

        trigger("resetLang");
    };

    const onSubmit = content => {
        const func = content.id ? "editDashboard" : "createDashboard";

        trigger(func, content);

        onClose();
    };

    const onSort = elem => {
        trigger("setSortTable", elem);
    };

    useEffect(() => {
        trigger("setSortTable", {
            property:   "name",
            isOrderAsc: true
        });

        trigger("onSettingsPlantSelect", params.plantId, options);
    }, []);

    return (
        <>
            <Datatable
                content={dashboards}
                isEditable={true}
                hasActionsColumn={true}
                onRowClick={elem => setDashboard(elem)}
                onDelete={x => trigger("deleteDashboard", x)}
                selected={dashboard?.id}
                className="datatable"
                onHeadlineClick={onSort}
            >
                <Column
                    title="element.name.header"
                    width="45%"
                    item="name"
                    sortable
                    render={x => (
                        <span className="clamped">{ translate(x) }</span>
                    )}
                />
                <Column
                    title="last.modified.header"
                    width="65%"
                    sortable
                    item={"createdAt" || "updatedAt"}
                    render={x => {
                        const date = x;

                        if(!date) return "-";

                        const finalDate = date.toLocaleDateString ? date : new Date(date);

                        return finalDate.toLocaleString().replace(",", "");
                    }}
                />
            </Datatable>
            <Drawer className="sidebar drawer-container" _show={Boolean(dashboard?.id || isSidebarOpen)} _onClose={onClose}>
                {
                    dashboard &&
                        <DashboardForm
                            {...dashboard}
                            onCancel={onClose}
                            onSubmit={onSubmit}
                        />
                }
                {
                    !dashboard &&
                        <DashboardForm
                            onSubmit={onSubmit}
                            onCancel={onClose}
                        />
                }
            </Drawer>
        </>
    );
};

export { DashboardNav };
